import { SupportedFileType } from "src/services/upload.service";

export const checkSizeAndExtensions = (ext: string, size: number) => {
  //TODO: toast if invalid
  const validExtension: boolean =
    Object.values(SupportedFileType).findIndex(
      (type) => type.toLowerCase() === ext.toLowerCase(),
    ) > -1;
  const maxImageSize = 10485760 * 2; // 2 MB
  const validSize = size < maxImageSize;

  return validExtension && validSize;
};
