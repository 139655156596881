import {
  getListPlusFourMock,
  getListPlusOneMock,
  getListPlusThreeMock,
  getListPlusTwoMock,
} from "src/services/mocks/list-plus-servuce.mocks/list-plus-service.mock";

export const getListPlusService = (plus: string) => {
  switch (plus) {
    case "container.community.1.cohort":
      return getListPlusOneMock;
    case "container.community.2.cohort":
      return getListPlusTwoMock;
    case "container.community.3.cohort":
      return getListPlusThreeMock;
    case "container.community.4.cohort":
      return getListPlusFourMock;
    default:
      return [];
  }
};
