import { IoMdClose } from "react-icons/io";
import { appRoutesObj } from "src/app.paths";
import { Layout } from "src/components/common/layout-component/layout-component";
import PopupCard from "src/components/common/PopupCard";
import ScanPermitContainer from "src/containers/user/Transportation-container/scan-permit-container";

export default function ScanPermitPage() {

  return (
    <Layout>
      <PopupCard
        link={`${appRoutesObj.getBasePath()}`}
        icon={<IoMdClose size={32} className="text-white" />}
      >
       <ScanPermitContainer/>
      </PopupCard>
    </Layout>
  );
}
