import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import TitleComponent from "src/components/common/title-component";
import { Button } from "src/components/common/ui/Button";
import CommonQuestionsComponents from "src/components/user/common-questions-components";

function CommonQuestionsContainer() {
  const { t, i18n } = useTranslation();
  const [questionVisibility, setQuestionVisibility] = useState<{
    [key: string]: boolean;
  }>({});

  const ListQuestions = Array.from({ length: 12 }, (_, index) => ({
    id: `q${index + 1}`,
    question: t(`container.CommonQuestionsContainer.Q${index + 1}`),
    answer: t(`container.CommonQuestionsContainer.A${index + 1}`),
    click: questionVisibility[`q${index + 1}`] || false,
    handleClick: () =>
      setQuestionVisibility((prevVisibility) => ({
        ...prevVisibility,
        [`q${index + 1}`]: !prevVisibility[`q${index + 1}`],
      })),
  }));

  return (
    <div className="px-4">
      <TitleComponent
        title="container.CommonQuestionsContainer.title.f&q"
        size={`${i18n.language === "en" ? "text-base" : "text-2xl"} `}
      />
      <div className="grid  mt-10">
        {ListQuestions.map(({ id, question, answer, click, handleClick }) => (
          <CommonQuestionsComponents
            key={id}
            question={question}
            answer={answer}
            click={click}
            handleClick={handleClick}
          />
        ))}
      </div>
      <Link
        to={appRoutesObj.getQuestionsFormPagePath()}
        className="grid justify-center items-center mt-6"
      >
        <Button type="button">{t("app.shared.ask")}</Button>
      </Link>
    </div>
  );
}

export default CommonQuestionsContainer;
