export interface HostedPrograms {
  name: string;
  url: string;
  description: string;
}

export const hostedPrograms: HostedPrograms[] = [
  {
    name: "container.hostedContainer.name.MVPLab",
    url: "https://ntdp.gov.sa/en/initiative/mvplab",
    description: "container.hostedContainer.description.MVPLab",
  },
  {
    name: "container.hostedContainer.name.GAIA",
    url: "https://gaia.newnative.ai/",
    description: "container.hostedContainer.description.GAIA",
  },
  {
    name: "container.hostedContainer.name.ANTLER",
    url: "https://www.antler.co/location/middle-east",
    description: "container.hostedContainer.description.ANTLER",
  },
  {
    name: "container.hostedContainer.name.Boost",
    url: "https://ntdp.gov.sa/en/initiative/boost",
    description: "container.hostedContainer.description.Boost",
  },
];
