import React, { useCallback, useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { appRoutesObj } from "src/app.paths";
import { Layout } from "src/components/common/layout-component/layout-component";
import PopupCard from "src/components/common/PopupCard";
import QuestionFormContainer from "src/containers/user/CommonQuestionsContainer/question-form-container";
import { handleRequestError } from "src/core/helpers/error.helper";
import { opengraphMetaTags } from "src/core/helpers/example.helper";
import { getontactUsStatusService } from "src/services/contact-us.service";

function QuestionFormPage() {
  const [isopen, setIsOpen] = useState(null);
  const { i18n ,t } = useTranslation();
  const ContactUsStatus = useCallback(async () => {
    try {
      const result = await getontactUsStatusService();
      setIsOpen(result.is_open);
    } catch (error) {
      handleRequestError(error);
    }
  }, []);
  useEffect(() => {
    ContactUsStatus();
  }, [ContactUsStatus]);

  return (
    <Layout>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA as string}
      >
         <> <Helmet
          title={`${t("app.shared.theGarage")} | ${t(
            "app.shared.ask"
          )}`}
          meta={[
            ...opengraphMetaTags({
              title: `${t("app.shared.theGarage")} | ${t(
                "app.shared.ask"
              )}`,
              description: `${t(
                "component.user.SidebarComponent.theGarageDescreption"
              )}`,
              logo: "/assets/images/logo.png",
            }),
            {
              name: "description",
              content: `${t(
                "component.user.SidebarComponent.theGarageDescreption"
              )}`,
            },
          ]}
        />
        <PopupCard
          link={appRoutesObj.getCommonQuestionsPagePath()}
          icon={
            i18n.language === "en" ? (
              <AiOutlineArrowRight size={32} className="text-white" />
            ) : (
              <AiOutlineArrowLeft size={32} className="text-white" />
            )
          }
        >
          <>{isopen === true ? <QuestionFormContainer /> : ""}</>
        </PopupCard></>
      </GoogleReCaptchaProvider>
    </Layout>
  );
}

export default QuestionFormPage;
