/* eslint-disable import/named */
import { format } from "date-fns";
import {
  Formik,
  useFormik,
  FormikTouched,
  FormikValues,
  FormikErrors,
} from "formik";
import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import Popup from "src/components/common/pop-up";
import ProgramSteps from "src/components/common/program-steps";
import { RegistrationCardComponent } from "src/components/common/registration-card-component";
import { ReportProblemButtonComponent } from "src/components/common/report-problem-button-component";
import BackButton from "src/components/common/ui/BackButton";
import DateField from "src/components/common/ui/DateField";
import GridView from "src/components/common/ui/grid-view";
import InputField from "src/components/common/ui/InputField";
import SelectComponent from "src/components/common/ui/select-component";
import { handleRequestError } from "src/core/helpers/error.helper";
import * as list from "src/core/statics/form-data/form-data";
import { BookTourList } from "src/core/types/tour.type";
import { postEventsBookService } from "src/services/events-book.service";

import { initialValues } from "./book-venue-form.data";
import { validationSchema } from "./book-venue-form.validation";

function BookVenueFormContainer() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t, i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [succeSsfully, setSucceSsfully] = useState<boolean>(false);
  const [isvalid, setIsvalid] = useState<boolean>(false);
  const fieldsToCheck: string[] = ["firstName", "lastName", "mobile", "email"];
  const content = [
    { label: "app.shared.personalInformation", color: "#737373" },
    { label: "app.shared.additionalInformation", color: "#737373" },
  ];
  const isLastSection = activeIndex === content.length - 1;

  const handleStartValueChange = (startDate: Date) => {
    setStartDate(startDate);
  };
  const handleEndValueChange = (endDate: Date) => {
    setEndDate(endDate);
  };
  const handleSubmit = async () => {
    try {
      if (executeRecaptcha) {
        const token = await executeRecaptcha();
        await postEventsBookService(
          {
            event_name: formik.values.event_name,
            company: formik.values.company,
            email: formik.values.email,
            first_name: formik.values.firstName,
            last_name: formik.values.lastName,
            mobile: formik.values.mobile,
            position: formik.values.position,
            event_type:
              formik.values.other !== ""
                ? formik.values.other
                : formik.values.event_type,
            guest_count: formik.values.guest_count,
            start_at: format(startDate, "yyyy-MM-dd"),
            end_at: format(endDate, "yyyy-MM-dd"),
          },
          token
        );
        setSucceSsfully(!succeSsfully);
      }
    } catch (error) {
      handleRequestError(error);
    }
  };
  const handleBackClick = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + content.length) % content.length
    );
  };
  const handleNext = (
    fieldsToCheck: string[],
    formik: {
      setTouched: (touched: FormikTouched<BookTourList>) => void;
      values: FormikValues;
      errors: FormikErrors<BookTourList>;
    },
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>,
    content: unknown[]
  ) => {
    const newTouched: FormikTouched<BookTourList> = {};

    fieldsToCheck.forEach((field) => {
      if (!formik.values[field]) {
        newTouched[field] = true;
      }
    });
    formik.setTouched(newTouched);
    const hasErrors = fieldsToCheck.some(
      (field) => !formik.values[field] || !!formik.errors[field]
    );
    if (!hasErrors) {
      setIsvalid(!isvalid);
      setActiveIndex((prevIndex) =>
        Math.min(prevIndex + 1, content.length - 1)
      );
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async () => {
      await handleSubmit();
    },
    validationSchema: validationSchema(),
  });
  const handlClick = () => {
    if (activeIndex !== 0) {
      handleBackClick();
    } else if (activeIndex === 0) {
      handleNext(fieldsToCheck, formik, setActiveIndex, content);
    } else {
      handleSubmit();
    }
  };

  return (
    <RegistrationCardComponent
      title="containers.BookVenueFormContainer.title"
      description="containers.BookVenueFormContainer.description"
    >
      <div className="h-auto largeLaptop:h-auto overflow-y-auto">
        <div className="flex gap-12 px-4 pt-5 mobile:gap-0 tablet:gap-0 text-base text-neutral-500">
          {content.map((item, index) => (
            <ProgramSteps
              activeIndex={activeIndex}
              index={index}
              completed={activeIndex > index}
              color={item.color}
              label={item.label}
              key={index}
              onClick={handlClick}
            />
          ))}
        </div>
        <div className="w-full">
          <Formik
            initialValues={initialValues}
            onSubmit={() => formik.submitForm()}
            validationSchema={formik.initialValues}
            validateOnChange
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
            >
              {activeIndex === 0 && (
                <GridView column={"2"}>
                  <InputField
                    label="app.shared.firstName"
                    name="firstName"
                    isValid={
                      formik.touched.firstName && !formik.errors.firstName
                    }
                    value={
                      formik.values.firstName != null
                        ? formik.values.firstName
                        : ""
                    }
                    errorMsg={
                      formik.touched.firstName && formik.errors.firstName
                        ? formik.errors.firstName
                        : ""
                    }
                    onChange={(e) => {
                      formik.setFieldValue("firstName", e.target.value);
                      formik.handleChange(e);
                    }}
                  />
                  <InputField
                    label="app.shared.lastName"
                    name="lastName"
                    isValid={formik.touched.lastName && !formik.errors.lastName}
                    value={
                      formik.values.lastName != null
                        ? formik.values.lastName
                        : ""
                    }
                    errorMsg={
                      formik.touched.lastName && formik.errors.lastName
                        ? formik.errors.lastName
                        : ""
                    }
                    onChange={(e) => {
                      formik.setFieldValue("lastName", e.target.value);
                      formik.handleChange(e);
                    }}
                  />
                  <InputField
                    label="app.shared.mobile.number"
                    name="mobile"
                    helperText={""}
                    errorMsg={
                      formik.touched.mobile && formik.errors.mobile
                        ? formik.errors.mobile
                        : ""
                    }
                    minLength={9}
                    isValid={formik.touched.mobile && !formik.errors.mobile}
                    value={
                      formik.values.mobile != null ? formik.values.mobile : NaN
                    }
                    onChange={(e) => {
                      formik.setFieldValue("mobile", e.target.value);
                      formik.handleChange(e);
                    }}
                  />
                  <InputField
                    label="app.shared.email"
                    name="email"
                    isValid={formik.touched.email && !formik.errors.email}
                    value={
                      formik.values.email != null ? formik.values.email : ""
                    }
                    errorMsg={
                      formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : ""
                    }
                    onChange={(e) => {
                      formik.setFieldValue("email", e.target.value);
                      formik.handleChange(e);
                    }}
                  />
                </GridView>
              )}
              {activeIndex === 1 && (
                <GridView column={"1"}>
                  <div className="flex gap-6 mobile:flex-col mobile:gap-2 tablet:gap-2 tablet:flex-col">
                    <InputField
                      label="app.shared.company"
                      name="company"
                      isValid={formik.touched.company && !formik.errors.company}
                      value={
                        formik.values.company != null
                          ? formik.values.company
                          : ""
                      }
                      errorMsg={
                        formik.touched.company && formik.errors.company
                          ? formik.errors.company
                          : ""
                      }
                      onChange={(e) => {
                        formik.setFieldValue("company", e.target.value);
                        formik.handleChange(e);
                      }}
                    />
                    <InputField
                      label="app.shared.position"
                      name="position"
                      isValid={
                        formik.touched.position && !formik.errors.position
                      }
                      value={
                        formik.values.position != null
                          ? formik.values.position
                          : ""
                      }
                      errorMsg={
                        formik.touched.position && formik.errors.position
                          ? formik.errors.position
                          : ""
                      }
                      onChange={(e) => {
                        formik.setFieldValue("position", e.target.value);
                        formik.handleChange(e);
                      }}
                    />
                  </div>
                  <InputField
                    label="container.BookVenueFormContainer.eventName"
                    name="event_name"
                    isValid={
                      formik.touched.event_name && !formik.errors.event_name
                    }
                    value={
                      formik.values.event_name != null
                        ? formik.values.event_name
                        : ""
                    }
                    errorMsg={
                      formik.touched.event_name && formik.errors.event_name
                        ? formik.errors.event_name
                        : ""
                    }
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  <div className="flex gap-6 mobile:flex-col mobile:gap-2 tablet:gap-2 tablet:flex-col">
                    <SelectComponent
                      selectLabel="container.BookVenueFormContainer.typeOfEvent"
                      name="event_type"
                      options={
                        i18n.language === "en"
                          ? list.eventType_en
                          : list.eventType
                      }
                      value={
                        formik.values.event_type != null
                          ? formik.values.event_type
                          : ""
                      }
                      errorMsg={
                        formik.touched.event_type && formik.errors.event_type
                          ? formik.errors.event_type
                          : ""
                      }
                      onChange={(e) => {
                        formik.setFieldValue("event_type", e.target.value);
                        formik.handleChange(e);
                      }}
                    />
                    <InputField
                      label="container.BookVenueFormContainer.personNumber"
                      name="guest_count"
                      type="number"
                      isValid={
                        formik.touched.guest_count && !formik.errors.guest_count
                      }
                      value={
                        formik.values.guest_count != null
                          ? formik.values.guest_count
                          : ""
                      }
                      errorMsg={
                        formik.touched.guest_count && formik.errors.guest_count
                          ? formik.errors.guest_count
                          : ""
                      }
                      onChange={(e) => {
                        formik.setFieldValue("guest_count", e.target.value);
                        formik.handleChange(e);
                      }}
                    />
                  </div>
                  <div className="flex gap-6 mobile:gap-2 tablet:gap-2 mobile:flex-col tablet:flex-col">
                    <DateField
                      label="container.BookVenueFormContainer.from"
                      name="start_at"
                      type="date"
                      isValid={
                        formik.touched.start_at && !formik.errors.start_at
                      }
                      value={startDate != null ? startDate.toDateString() : ""}
                      errorMsg={
                        formik.touched.start_at && formik.errors.start_at
                          ? formik.errors.start_at
                          : ""
                      }
                      onChange={(e) => {
                        handleStartValueChange;
                        formik.handleChange(e);
                      }}
                    />

                    <DateField
                      label="container.BookVenueFormContainer.to"
                      name="end_at"
                      type="date"
                      isValid={formik.touched.end_at && !formik.errors.end_at}
                      value={endDate != null ? endDate.toDateString() : ""}
                      errorMsg={
                        formik.touched.end_at && formik.errors.end_at
                          ? formik.errors.end_at
                          : ""
                      }
                      onChange={(e) => {
                        handleEndValueChange;
                        formik.handleChange(e);
                      }}
                    />
                  </div>
                </GridView>
              )}
            </form>
          </Formik>
        </div>
        <div
          className={`flex flex-row justify-center gap-4 largeLaptop:pt-16 ${
            isLastSection ? "pt-8" : "pt-44"
          }`}
        >
          <ReportProblemButtonComponent
            title={`${
              isLastSection ? t("app.shared.send") : t("app.shared.next")
            }`}
            onClick={() => {
              if (activeIndex === 0) {
                handleNext(fieldsToCheck, formik, setActiveIndex, content);
              } else {
                handleSubmit();
              }
            }}
          />
          {activeIndex !== 0 && <BackButton onClick={handleBackClick} />}
        </div>
        {succeSsfully && (
          <div className="max-w-[100%] bg-cover w-screen z-50 absolute top-0 bottom-0 bg-black/50 backdrop-blur-2xl rounded-xl py-[186px] px-[238px]">
            <Popup
              body="Your request has been sent successfully"
              onClose={() => setSucceSsfully(false)}
            />
          </div>
        )}
      </div>
    </RegistrationCardComponent>
  );
}

export default BookVenueFormContainer;
