
import { useTranslation } from "react-i18next";
import { BiCalendar, BiTimeFive } from "react-icons/bi";
import { GrLocation } from "react-icons/gr";

import CardBorderGreenComponent from "./card-border-green-component";

export default function HiddenDisruptTimeComponent() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center">
      <img
        className="w-[170px] tablet:w-[120px] mobile:w-[120px] cursor-pointer justify-center flex items-center pb-6"
        src="/assets/images/disrupt-join.png"
        alt="logo"
      />
      <div className="flex items-center justify-center pb-6 gap-x-6 mobile:justify-between mobile:gap-x-4">
        <CardBorderGreenComponent title={t('component.HiddenDisruptTimeComponent.date')} icon={BiCalendar} />
        <CardBorderGreenComponent title={t('component.HiddenDisruptTimeComponent.time')} icon={BiTimeFive} />
        <CardBorderGreenComponent title={t('app.shared.theGarage')} icon={GrLocation} />

      </div>
    </div>
  );
}
