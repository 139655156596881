import {
  BiBookReader,
  BiBriefcase,
  BiChalkboard,
  BiMapPin,
  BiNetworkChart,
  BiTerminal,
} from "react-icons/bi";
import {
  BsBuildings,
  BsGraphUpArrow,
  BsCashStack,
  BsGraphUp,
  BsBarChartLine,
} from "react-icons/bs";
import { FiAward } from "react-icons/fi";
import { GoLightBulb } from "react-icons/go";
import { GrInherit, GrMoney, GrSteps, GrUserManager } from "react-icons/gr";
import { HiOutlinePresentationChartBar, HiOutlineServer } from "react-icons/hi";
import { ImMagicWand } from "react-icons/im";
import { MdBiotech, MdOutlineChair } from "react-icons/md";
import {
  RiBookmark3Line,
  RiEarthLine,
  RiFlashlightLine,
  RiGoogleLine,
  RiPagesLine,
  RiTeamLine,
} from "react-icons/ri";

export interface InternalPrograms {
  name: string;
  programInactiveImg: string;
  programActiveImg: string;
  aboutProgram: string;
  nextPatch: NextPatch[];
  targetSectors?: TargetSectors[];
  selectionCriteria: SelectionCriteria[];
  whatMakeUsSpecial: WhatMakeUsSpecial[];
}
export interface NextPatch {
  text: string;
}
interface TargetSectors {
  text: string;
}
interface SelectionCriteria {
  id: number;
  title: string;
  description: string;
  icon: JSX.Element;
}
interface WhatMakeUsSpecial {
  icon: JSX.Element;
  label: string;
  description: string;
}

export const internalPrograms: InternalPrograms[] = [
  {
    name: "Plus",
    programActiveImg: "plus-active",
    programInactiveImg: "plus",
    aboutProgram: "containers.InternalProgramsContainer.aboutPlus",
    nextPatch: [
      { text: "containers.InternalProgramsContainer.plusProgramStages1" },
      { text: "containers.InternalProgramsContainer.plusProgramStages2" },
      { text: "containers.InternalProgramsContainer.plusProgramStages3" },
      { text: "containers.InternalProgramsContainer.plusProgramStages4" },
      { text: "containers.InternalProgramsContainer.plusProgramStages5" },
    ],

    selectionCriteria: [
      {
        id: 1,
        title:
          "containers.InternalProgramsContainer.plusSelectionCriteria1.title1",
        icon: <BsBuildings />,
        description:
          "containers.InternalProgramsContainer.plusSelectionCriteria1.subTitle1",
      },
      {
        id: 2,
        title:
          "containers.InternalProgramsContainer.plusSelectionCriteria2.title2",
        icon: <GrSteps />,
        description:
          "containers.InternalProgramsContainer.plusSelectionCriteria2.subTitle2",
      },
      {
        id: 3,
        title:
          "containers.InternalProgramsContainer.plusSelectionCriteria3.title3",
        icon: <GoLightBulb />,
        description:
          "containers.InternalProgramsContainer.plusSelectionCriteria3.subTitle3",
      },
      {
        id: 4,
        title:
          "containers.InternalProgramsContainer.plusSelectionCriteria4.title4",
        icon: <BsGraphUpArrow />,
        description:
          "containers.InternalProgramsContainer.plusSelectionCriteria4.subTitle4",
      },
      {
        id: 5,
        title:
          "containers.InternalProgramsContainer.plusSelectionCriteria5.title5",
        icon: <RiTeamLine />,
        description:
          "containers.InternalProgramsContainer.plusSelectionCriteria5.subTitle5",
      },
      {
        id: 6,
        title:
          "containers.InternalProgramsContainer.plusSelectionCriteria6.title6",
        icon: <GrMoney />,
        description:
          "containers.InternalProgramsContainer.plusSelectionCriteria6.subTitle6",
      },
      {
        id: 7,
        title:
          "containers.InternalProgramsContainer.plusSelectionCriteria7.title7",
        icon: <FiAward />,
        description:
          "containers.InternalProgramsContainer.plusSelectionCriteria7.subTitle7",
      },
      {
        id: 8,
        title:
          "containers.InternalProgramsContainer.plusSelectionCriteria8.title8",
        icon: <RiBookmark3Line />,
        description:
          "containers.InternalProgramsContainer.plusSelectionCriteria8.subTitle8",
      },
      {
        id: 9,
        title:
          "containers.InternalProgramsContainer.plusSelectionCriteria9.title9",
        icon: <GrUserManager />,
        description:
          "containers.InternalProgramsContainer.plusSelectionCriteria9.subTitle9",
      },
      {
        id: 10,
        title:
          "containers.InternalProgramsContainer.plusSelectionCriteria10.title10",
        icon: <BiMapPin />,
        description:
          "containers.InternalProgramsContainer.plusSelectionCriteria10.subTitle10",
      },
      {
        id: 11,
        title:
          "containers.InternalProgramsContainer.plusSelectionCriteria11.title11",
        icon: <BsCashStack />,
        description:
          "containers.InternalProgramsContainer.plusSelectionCriteria11.subTitle11",
      },
    ],
    whatMakeUsSpecial: [
      {
        label: "containers.InternalProgramsContainer.plusMakesUsSpecial1",
        description:
          "containers.InternalProgramsContainer.plusMakesUsSpecial1.description1",
        icon: <RiGoogleLine size={24} />,
      },
      {
        label: "containers.InternalProgramsContainer.plusMakesUsSpecial2",
        description:
          "containers.InternalProgramsContainer.plusMakesUsSpecial2.description2",
        icon: <BiTerminal size={24} />,
      },
      {
        label: "containers.InternalProgramsContainer.plusMakesUsSpecial3",
        description:
          "containers.InternalProgramsContainer.plusMakesUsSpecial3.description3",
        icon: <GrInherit size={24} />,
      },
      {
        label: "containers.InternalProgramsContainer.plusMakesUsSpecial4",
        description:
          "containers.InternalProgramsContainer.plusMakesUsSpecial4.description4",
        icon: <BsCashStack size={24} />,
      },
      {
        label: "containers.InternalProgramsContainer.plusMakesUsSpecial5",
        description:
          "containers.InternalProgramsContainer.plusMakesUsSpecial5.description5",
        icon: <HiOutlinePresentationChartBar size={24} />,
      },
      {
        label: "containers.InternalProgramsContainer.plusMakesUsSpecial6",
        description:
          "containers.InternalProgramsContainer.plusMakesUsSpecial6.description6",
        icon: <RiFlashlightLine size={24} />,
      },
    ],
  },
  {
    name: "Incubator",
    programActiveImg: "incubator-active",
    programInactiveImg: "incubator",
    aboutProgram: "containers.InternalProgramsContainer.aboutIncubator",
    nextPatch: [
      { text: "containers.InternalProgramsContainer.incubatorProgramStages1" },
      { text: "containers.InternalProgramsContainer.incubatorProgramStages2" },
      { text: "containers.InternalProgramsContainer.incubatorProgramStages3" },
      { text: "containers.InternalProgramsContainer.incubatorProgramStages4" },
    ],
    targetSectors: [
      { text: "containers.InternalProgramsContainer.incubatorTargetSector1" },
      // { text: "containers.InternalProgramsContainer.incubatorTargetSector2" },
      // { text: "containers.InternalProgramsContainer.incubatorTargetSector3" },
      // { text: "containers.InternalProgramsContainer.incubatorTargetSector4" },
      { text: "containers.InternalProgramsContainer.incubatorTargetSector5" },
    ],
    selectionCriteria: [
      {
        id: 1,
        title:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria1.title1",
        icon: <BsBuildings />,
        description:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria1.subTitle1",
      },
      {
        id: 2,
        title:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria2.title2",
        icon: <GrSteps />,
        description:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria2.subTitle2",
      },
      {
        id: 3,
        title:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria3.title3",
        icon: <GoLightBulb />,
        description:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria3.subTitle3",
      },
      {
        id: 4,
        title:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria4.title4",
        icon: <RiTeamLine />,
        description:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria4.subTitle4",
      },
      {
        id: 5,
        title:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria5.title5",
        icon: <RiPagesLine />,
        description:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria5.subTitle5",
      },
      {
        id: 6,
        title:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria6.title6",
        icon: <RiBookmark3Line />,
        description:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria6.subTitle6",
      },
      {
        id: 7,
        title:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria7.title7",
        icon: <GrUserManager />,
        description:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria7.subTitle7",
      },
      {
        id: 8,
        title:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria8.title8",
        icon: <BiMapPin />,
        description:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria8.subTitle8",
      },
      {
        id: 9,
        title:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria9.title9",
        icon: <RiEarthLine />,
        description:
          "containers.InternalProgramsContainer.incubatorSelectionCriteria9.subTitle9",
      },
    ],
    whatMakeUsSpecial: [
      {
        label: "containers.InternalProgramsContainer.incubatorMakesUsSpecial1",
        description: "",
        icon: <BiChalkboard size={24} />,
      },
      {
        label: "containers.InternalProgramsContainer.incubatorMakesUsSpecial2",
        description: "",
        icon: <HiOutlineServer size={24} />,
      },
      {
        label: "containers.InternalProgramsContainer.incubatorMakesUsSpecial3",
        description: "",
        icon: <MdOutlineChair size={24} />,
      },
      {
        label: "containers.InternalProgramsContainer.incubatorMakesUsSpecial4",
        description: "",
        icon: <BsBarChartLine size={24} />,
      },
      {
        label: "containers.InternalProgramsContainer.incubatorMakesUsSpecial5",
        description: "",
        icon: <BiTerminal size={24} />,
      },
      {
        label: "containers.InternalProgramsContainer.incubatorMakesUsSpecial6",
        description: "",
        icon: <MdBiotech size={24} />,
      },
    ],
  },
  {
    name: "Ignition",
    programActiveImg: "Ignition-Active",
    programInactiveImg: "Ignition",
    aboutProgram: "containers.InternalProgramsContainer.aboutIgnition",
    nextPatch: [
      { text: "containers.InternalProgramsContainer.incubatorProgramStages1" },
      { text: "containers.InternalProgramsContainer.incubatorProgramStages2" },
      { text: "containers.InternalProgramsContainer.incubatorProgramStages3" },
      { text: "containers.InternalProgramsContainer.incubatorProgramStages4" },
    ],
    targetSectors: [
      { text: "containers.InternalProgramsContainer.IgnitionTargetSector1" },
      { text: "containers.InternalProgramsContainer.IgnitionTargetSector2" },
      { text: "containers.InternalProgramsContainer.IgnitionTargetSector3" },
      { text: "containers.InternalProgramsContainer.IgnitionTargetSector4" },
      { text: "containers.InternalProgramsContainer.IgnitionTargetSector5" },
      { text: "containers.InternalProgramsContainer.IgnitionTargetSector6" },
      { text: "containers.InternalProgramsContainer.IgnitionTargetSector7" },
      { text: "containers.InternalProgramsContainer.IgnitionTargetSector8" },
      { text: "containers.InternalProgramsContainer.IgnitionTargetSector9" },
      { text: "containers.InternalProgramsContainer.IgnitionTargetSector10" },
    ],
    selectionCriteria: [
      {
        id: 1,
        title:
          "containers.InternalProgramsContainer.IgnitionSelectionCriteria1.title1",
        icon: <BsBuildings />,
        description:
          "containers.InternalProgramsContainer.IgnitionSelectionCriteria1.subTitle1",
      },
      {
        id: 2,
        title:
          "containers.InternalProgramsContainer.IgnitionSelectionCriteria2.title2",
        icon: <RiTeamLine />,
        description:
          "containers.InternalProgramsContainer.IgnitionSelectionCriteria2.subTitle2",
      },
      {
        id: 3,
        title:
          "containers.InternalProgramsContainer.IgnitionSelectionCriteria3.title3",
        icon: <GrSteps />,
        description:
          "containers.InternalProgramsContainer.IgnitionSelectionCriteria3.subTitle3",
      },
      {
        id: 4,
        title:
          "containers.InternalProgramsContainer.IgnitionSelectionCriteria4.title4",
        icon: <BsGraphUp />,
        description:
          "containers.InternalProgramsContainer.IgnitionSelectionCriteria4.subTitle4",
      },
      {
        id: 5,
        title:
          "containers.InternalProgramsContainer.IgnitionSelectionCriteria5.title5",
        icon: <FiAward />,
        description:
          "containers.InternalProgramsContainer.IgnitionSelectionCriteria5.subTitle5",
      },
      {
        id: 6,
        title:
          "containers.InternalProgramsContainer.IgnitionSelectionCriteria6.title6",
        icon: <GrUserManager />,
        description:
          "containers.InternalProgramsContainer.IgnitionSelectionCriteria6.subTitle6",
      },
      {
        id: 7,
        title:
          "containers.InternalProgramsContainer.IgnitionSelectionCriteria7.title7",
        icon: < RiEarthLine/>,
        description:
          "containers.InternalProgramsContainer.IgnitionSelectionCriteria7.subTitle7",
      },
      {
        id: 8,
        title:
          "containers.InternalProgramsContainer.IgnitionSelectionCriteria8.title8",
        icon: < RiBookmark3Line/>,
        description:
          "containers.InternalProgramsContainer.IgnitionSelectionCriteria8.subTitle8",
      },
    ],
    whatMakeUsSpecial: [
      {
        label: "containers.InternalProgramsContainer.IgnitionMakesUsSpecial1",
        description: "",
        icon: <ImMagicWand size={24} />,
      },
      {
        label: "containers.InternalProgramsContainer.IgnitionMakesUsSpecial2",
        description: "",
        icon: <BiBookReader size={24} />,
      },
      {
        label: "containers.InternalProgramsContainer.IgnitionMakesUsSpecial3",
        description: "",
        icon: <BiBriefcase size={24} />,
      },
      {
        label: "containers.InternalProgramsContainer.IgnitionMakesUsSpecial4",
        description: "",
        icon: <BiNetworkChart size={24} />,
      },
      {
        label: "containers.InternalProgramsContainer.IgnitionMakesUsSpecial5",
        description: "",
        icon: <BsGraphUp size={24} />,
      },
    ],
  },
];

export const selectionCriteriaIgnation = [
  {
    id: 1,
    label: "containers.InternalProgramsContainer.IgnitionTargetSector1",
  },
  {
    id: 2,
    label: "containers.InternalProgramsContainer.IgnitionTargetSector2",
  },
  {
    id: 3,
    label: "containers.InternalProgramsContainer.IgnitionTargetSector3",
  },
  {
    id: 4,
    label: "containers.InternalProgramsContainer.IgnitionTargetSector4",
  },
  {
    id: 5,
    label: "containers.InternalProgramsContainer.IgnitionTargetSector5",
  },
  {
    id: 6,
    label: "containers.InternalProgramsContainer.IgnitionTargetSector6",
  },
  {
    id: 7,
    label: "containers.InternalProgramsContainer.IgnitionTargetSector7",
  },
  {
    id: 8,
    label: "containers.InternalProgramsContainer.IgnitionTargetSector8",
  },
  {
    id: 9,
    label: "containers.InternalProgramsContainer.IgnitionTargetSector9",
  },
  {
    id: 10,
    label: "containers.InternalProgramsContainer.IgnitionTargetSector10",
  },
];
