import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "src/core/configs/i18n";
import { DirectionEnum } from "src/core/enums/ui.enums";

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  limit?: number;
  helperText?: string;
  label?: string;
  error?: boolean;
  errorMsg?: string;
  isValid?: boolean;
}

const InputField = ({
  label = "Label",
  limit,
  dir = i18n.language === "en" ? DirectionEnum.LTR : DirectionEnum.RTL,
  helperText = "",
  errorMsg = "",
  isValid = true,
  ...props
}: InputFieldProps): JSX.Element => {
  const { t } = useTranslation();
  const valueLength = props.value?.toString().length || 0;

  const getError = () => {
    if (errorMsg !== "") {
      isValid = false;
      return "border-red-500 focus:border-red-500 focus:border-2";
    }
    if (limit !== undefined && valueLength > limit) {
      isValid = false;
      return "border-red-500 focus:border-red-500 focus:border-2";
    }
    isValid = true;
    return "";
  };

  const getLabelVariant = () => {
    return `flex text-base peer-focus:text-base peer-focus:transform peer-focus:-translate-y-3 peer-focus:scale-95 text-opacity-80 bg-transparent px-1
    ${
      dir === DirectionEnum.LTR
        ? "left-0 ml-3 peer-focus:-translate-x-1"
        : "right-5 peer-focus:translate-x-3"
    }
    ${
      props.value && dir === DirectionEnum.LTR && isValid
        ? `transform -translate-y-3 -translate-x-1 scale-95`
        : props.value && dir === DirectionEnum.RTL && isValid
          ? "transform -translate-y-3 translate-x-3 scale-95"
          : (!isValid && props.value) || !isValid
            ? `transform -translate-y-5 scale-95 peer-focus:-translate-y-5`
            : props.value
              ? "transform  translate-x-3 "
              : ""
    }`;
  };

  const getInputVariant = () => {
    return `rounded-md placeholder:opacity-0 focus:border focus:border-borderColor focus:placeholder:opacity-100 duration-100 px-4 ${errorMsg !== "" ?`border ${getError()}`:""}`;
  };

  return (
    <div
      className="relative flex flex-col justify-center w-full mx-auto"
      dir={dir}
    >
      <input
        {...props}
        value={props.value}
        type="text"
        className={`peer h-14 mobile:h-12 focus:outline-none ${i18n.language === "en" ? "font-helveticaMedium text-base" : "font-arMyriad text-xl"} pt-5 bg-neutral-500/10 transition text-white disabled:text-gray-400 disabled:border disabled:border-none border-neutral-500/10  ${getInputVariant()}`}
      />

      <label
        className={`absolute ${i18n.language === "en" ? "font-helveticaMedium text-base" : "font-arMyriad text-xl"} transition peer-focus:text-gray-400 duration-200 text-gray-400  ${
          limit ? "disabled:!bg-transparent bottom-1/2" : "bottom-1/3"
        } ${getLabelVariant()} `}
      >
        {t(label)}
      </label>
      <div
        className={`flex justify-between px-2 py-1 text-xs text-gray-900 ${i18n.language === "en" ? "font-helveticaMedium" : "font-arMyriad"}`}
      >
        {(limit && limit < valueLength) || errorMsg !== "" ? (
          <p className={`${getError()} text-red-500`}>{t(errorMsg)}</p>
        ) : (
          <p className={`${getError()}`}>{t(helperText)}</p>
        )}
        {limit && (
          <span
            className={`${valueLength > limit ? "text-red-500" : ""} ${i18n.language === "en" ? "font-helveticaMedium" : "font-arMyriad"}`}
          >
            {props.value?.toString().length}/{limit}
          </span>
        )}
      </div>
    </div>
  );
};

export default InputField;
