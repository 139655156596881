import { ContactUsType } from "src/core/types/user.type";

export const initialValues: ContactUsType = {
  first_name: "",
  email: "",
  title: "",
  question: "",
  status: "new",
  reply: "",
};
