import React from 'react'
import { useTranslation } from 'react-i18next';

interface IbackButtonProps {
    onClick: () => void
}

export default function BackButton({ onClick }: IbackButtonProps) {
    const { t, i18n } = useTranslation();

    return (
        <button
            className={`h-11 px-10 py-3 text-sm leading-4 border rounded-lg ${i18n.language === 'en' ? 'font-helveticaBold' : 'font-arMyriad'} bg-naturalGray500/10 border-yellow backdrop-blur-lg text-yellow`}
            onClick={onClick}
        >
            {t("app.shared.back")}
        </button>
    )
}
