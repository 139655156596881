import "react-toastify/dist/ReactToastify.css";
import * as toast from "react-toastify";

export function ToastMessage() {
  return (
    <toast.ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      toastClassName="font-en"
    />
  );
}

export default ToastMessage;
