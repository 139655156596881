
import {
  onlyLetters_Ar_En,
  onlyNumbers_En,
} from "src/components/common/ui/regex";
import * as Yup from "yup";
const messageErrorUrl = "shared.form.errors.url";
export const validationPlusSchema = (startupProfileCopy: HTMLInputElement) =>
  Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .matches(onlyLetters_Ar_En, "form.validation.first.name.no.numbers")
      .required("form.validation.first.name.required")
      .min(2, "form.validation.first.name.min.2")
      .max(20, "form.validation.first.name.max.20"),
    lastName: Yup.string()
      .trim()
      .matches(onlyLetters_Ar_En, "form.validation.last.name.no.numbers")
      .required("form.validation.last.name.required"),
    email: Yup.string()
      .trim()
      .required("form.validation.email.required")
      .max(50, "form.validation.email.max.50")
      .email("form.validation.email.valid.format"),
    phoneNo: Yup.string()
      .trim()
      .required("form.validation.mobile.required")
      .matches(onlyNumbers_En, "form.validation.mobile.no.numbers")
      .min(9, "form.validation.mobile.min.9")
      .max(15, "form.validation.mobile.max.15"),

    gender: Yup.string().trim().required("form.validation.gender.required"),

    nationality: Yup.string()
      .trim()
      .required("form.validation.nationlity.required"),
    idType: Yup.string().trim().required("form.validation.idType.required"),
    idNumber: Yup.string()
      .trim()
      .required()
      .when("idType", {
        is: "app.shared.Passport",
        then: Yup.string()
          // TODO:required msg
          .required()
          .min(6, "form.validation.passport.min.6"),
      })
      .when("idType", {
        is: "app.shared.iqamaNo",
        // TODO:required msg
        then: Yup.string()
          .required()
          .min(10, "form.validation.match.10")
          .max(10, "form.validation.match.10"),
      })
      .when("idType", {
        is: "app.shared.idNo",
        // TODO:required msg
        then: Yup.string()
          .required()
          .min(10, "form.validation.match.10")
          .max(10, "form.validation.match.10"),
      }),

    otherNationality: Yup.string()
      .trim()
      .when("nationality", {
        is: "app.shared.Other",
        then: Yup.string().required("form.validation.required"),
      }),
    otherHearAboutGarage: Yup.string()
      .trim()
      .when("hearAboutGarage", {
        is: "app.shared.Other",
        then: Yup.string().required("form.validation.required"),
      }),
    otherCountry: Yup.string()
      .trim()
      .when("country", {
        is: "app.shared.Other",
        then: Yup.string().required("form.validation.required"),
      }),

    startupName: Yup.string()
      .trim()
      .required("form.validation.company.name.required")
      .max(50, "form.validation.company.name.max.50"),
    yearOfEstablishment: Yup.number().required(
      "form.validation.year.of.establishment.required"
    ),
    country: Yup.string().required("form.validation.Country.required"),
    startupMajor: Yup.string().required("form.validation.industry.required"),
    // this felid Validation is missing from the BA team
    website: Yup.string()
      .trim()
      .url(messageErrorUrl)
      .required("form.validation.required"),

    twitter: Yup.string().nullable().trim(),
    instagram: Yup.string().nullable().trim(),
    aboutStartup: Yup.string()
      .required("form.validation.about.company.required")
      .max(500, "form.validation.about.company.max.500"),
    startupProfile: Yup.mixed()
      .required("form.validation.company.file.required")
      .test(
        "fileSize",
        "form.validation.company.file.size",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (value) => value && startupProfileCopy.files![0].size < 2000000
      ),
    hearAboutGarage: Yup.string().required(
      "form.validation.hearAboutTheGarage.required"
    ),
    reasonToJoin: Yup.string()
      .max(350, "form.validate.JoinTheGarage.max.350")
      .required("form.validate.JoinTheGarage.required"),
  });
