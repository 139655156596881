import { BsTwitterX } from "react-icons/bs";
import { CiFacebook } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
function Footer() {
  return (
    <div className="flex flex-col md:flex-row justify-between px-3 pb-4 pt-6 largeLaptop:pt-2.5 mobile:py-0 mobile:pt-5 tablet:pt-5">
      <div className="flex flex-wrap justify-center md:justify-start gap-6 ">
        <img
          onClick={() => window.open("https://safcsp.org.sa/", "_blank")}
          className="w-[110px] largeLaptop:w-[79px] tablet:w-[15%] mobile:w-[25%] cursor-pointer"
          src="/assets/images/Mask group.png"
          alt="logo"
        />
        <img
          onClick={() => window.open("https://www.mcit.gov.sa", "_blank")}
          className="w-[110px] largeLaptop:w-[79px] tablet:w-[15%] mobile:w-[25%] cursor-pointer"
          src="/assets/images/Mask group (3).png"
          alt="logo"
        />
        <img
          onClick={() => window.open("https://kacst.gov.sa", "_blank")}
          className="max-w-[110px] largeLaptop:w-[79px] tablet:w-[15%] mobile:w-[25%] cursor-pointer"
          src="/assets/images/kacst.png"
          alt="logo"
        />

        {/* Add more images here */}
      </div>
      <div className="flex items-end justify-center md:justify-end gap-2 mt-4 md:mt-0">
        <FaInstagram
          onClick={() =>
            window.open("https://www.instagram.com/thegarageksa/", "_blank")
          }
          className="text-whiteColor cursor-pointer"
          size={16}
        />
        <BsTwitterX
          onClick={() =>
            window.open("https://twitter.com/TheGarageKSA", "_blank")
          }
          className="text-whiteColor cursor-pointer"
          size={16}
        />
        <CiFacebook
          onClick={() =>
            window.open("https://www.facebook.com/TheGarageKSA", "_blank")
          }
          className="text-whiteColor cursor-pointer"
          size={16}
        />
      </div>
    </div>
  );
}
export default Footer;
