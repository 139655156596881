/* eslint-disable import/named */

import { IconType } from "react-icons";
import { Link } from "react-router-dom";



interface CircleIconComponentProps {
    icon:IconType
    link:string
}
export default function CircleIconComponent(props: CircleIconComponentProps) {


  return (
   <Link to={props.link}>
   <div className="flex items-center justify-center p-1.5 rounded-full text-white bg-neutral-900 my-4">
   <props.icon size={13}/>
   </div>
   </Link>
  );
}
