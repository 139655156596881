import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DisruptComingSoon from "src/components/user/disrupt-coming-soon";
import DisruptTimerSkeletonComponent from "src/components/user/disrupt-timer-skeleton-component";
import { DisruputTime } from "src/core/types/disrupt.type";
import {
  getDisruptTimeServices,
  disruptStatus,
} from "src/services/disrupt-service";

export default function DisruptTimer() {
  const { i18n, t } = useTranslation();
  const [time, setTime] = useState<DisruputTime>();
  const [isopen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const data = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  const [timeRemaining, setTimeRemaining] = useState(data);
  const getData = async () => {
    try {
      const timer = await getDisruptTimeServices();
      setTime(timer);
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    if (time) {
      const firstItem = time;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const startAt: number = new Date(firstItem.start_at!).getTime();
      const currentTime: number = new Date().getTime();
      let timeDifference = startAt - currentTime;
      const interval = setInterval(() => {
        const currentTime = new Date().getTime();
        timeDifference = startAt - currentTime;
        if (timeDifference <= 0) {
          clearInterval(interval);
          setTimeRemaining(data);
        } else {
          const totalSeconds = Math.floor(timeDifference / 1000);
          const days = Math.floor(totalSeconds / (3600 * 24));
          const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
          const minutes = Math.floor((totalSeconds % 3600) / 60);
          const seconds = totalSeconds % 60;
          setTimeRemaining({
            days,
            hours,
            minutes,
            seconds,
          });
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [time]);

  const distruptStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await disruptStatus();
      setIsOpen(result.is_open);
      setIsLoading(false);
    } catch (error) {
      error;
    }
  }, []);

  useEffect(() => {
    distruptStatus();
    getData();
  }, [distruptStatus]);
  return isLoading? <DisruptTimerSkeletonComponent/> : 
  isopen ? (
    <div className="flex flex-col items-center justify-center w-full bg-black/20 h-[94px] backdrop-blur-lg rounded-2xl">
      <p className={`text-sm text-white ${i18n.language === "ar"? "font-arMyriad": "font-helveticaMedium"}`}>
        {t("container.disruptTimerContainer.launchingNextDisrupt")}
      </p>
      <div className="flex items-center justify-center gap-6 mobile:gap-3 pt-[6px]">
        <div className="flex flex-col items-center">
          <p className={`text-xs ${i18n.language === "ar"? "font-arMyriad": "font-helveticaMedium"} text-naturalGray400`}>
            {t("component.disruptTimerContainer.DAYS")}
          </p>
          <span className="text-2xl font-helveticaBold text-primary">
            {timeRemaining.days.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </span>
        </div>
        <p className="text-[32px] text-white font-helveticaBold">:</p>
        <div className="flex flex-col items-center">
          <span className={`text-xs ${i18n.language === "ar"? "font-arMyriad": "font-helveticaMedium"} text-naturalGray400`}>
            {t("component.disruptTimerContainer.HOUR")}
          </span>
          <p className="text-2xl font-helveticaBold text-primary">
            {timeRemaining.hours.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </p>
        </div>
        <p className="text-[32px] text-white font-helveticaBold">:</p>
        <div className="flex flex-col items-center">
          <p className={`text-xs ${i18n.language === "ar"? "font-arMyriad": "font-helveticaMedium"} text-naturalGray400`}>
            {t("component.disruptTimerContainer.MINUTE")}
          </p>
          <span className="text-2xl font-helveticaBold text-primary">
            {timeRemaining.minutes.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </span>
        </div>
        <p className="text-[32px] text-white font-helveticaBold">:</p>
        <div className="flex flex-col items-center">
          <p className={`text-xs ${i18n.language === "ar"? "font-arMyriad": "font-helveticaMedium"} text-naturalGray400`}>
            {t("component.disruptTimerContainer.SECOND")}
          </p>
          <span className="text-2xl font-helveticaBold text-primary">
            {timeRemaining.seconds.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </span>
        </div>
      </div>
    </div>
  ) : (
    <DisruptComingSoon />
  );
}
