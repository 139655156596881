import { useTranslation } from "react-i18next";
import { GrCheckmark } from "react-icons/gr";
import i18n from "src/core/configs/i18n";

interface ProgramStepsprops {
  activeIndex: number;
  index: number;
  color: string;
  completed?: boolean;
  label: string;
  onClick: () => void;
}
export default function ProgramSteps({
  activeIndex,
  index,
  completed = false,
  color,
  label,
  onClick,
}: ProgramStepsprops) {
  const { t } = useTranslation();

  return (
    <div onClick={onClick} className="pb-6 ">
      <div
        className={`flex h-5 mx-auto justify-center items-center text-center ${completed ? "gap-x-0" : "gap-x-1"}`}
        key={index}
      >
        {completed && (
          <div className="inline-flex text-center items-center justify-center mx-auto">

            <GrCheckmark color="white" />

          </div>
        )}
        <div
          className={`w-2 h-2 rounded-full cursor-pointer  ${activeIndex === index && completed === false ? "bg-yellow" : `bg-${color}`
            }`}
        ></div>
        <p
          className={`cursor-pointer w-full leading-tight whitespace-nowrap ${activeIndex === index && completed === false ? "text-yellow" : `text-${color}`
            }
            ${completed ? "text-white" : ""}
          ${i18n.language === "en"
              ? "font-helveticaMedium text-base mobile:text-[10px]"
              : "font-arMyriad text-xl mobile:text-[17px]"
            }
          `}
        >
          {`${t(label)}`}
        </p>
      </div>
    </div>
  );
}
