import { useState, useCallback, useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import { Layout } from "src/components/common/layout-component/layout-component";
import Loading from "src/components/common/Loading/loading";
import PopupCard from "src/components/common/PopupCard";
import RegistrationCloseComponents from "src/components/user/registration-close-component";
import BeOurPartnerFormContainer from "src/containers/user/be-our-partner-container/be-our-partner-form-container";
import { handleRequestError } from "src/core/helpers/error.helper";
import { opengraphMetaTags } from "src/core/helpers/example.helper";
import { beOurPartnerStatus } from "src/services/be-our-partner.service";

function BeOurPartnerFormPage() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const BeOurPartnerStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await beOurPartnerStatus();
      setIsOpen(result.is_open);
      setIsLoading(false);
    } catch (error) {
      handleRequestError(error);
    }
  }, []);

  useEffect(() => {
    BeOurPartnerStatus();
  }, [BeOurPartnerStatus]);

  return (
    <Layout>
      {isLoading ? (
        <Loading />
      ) : isOpen ? (
        <><Helmet
            title={`${t("app.shared.theGarage")} | ${t(
              "containers.BeOurPartnerFormContainer.title"
            )}`}
            meta={[
              ...opengraphMetaTags({
                title: `${t("app.shared.theGarage")} | ${t(
                  "containers.BeOurPartnerFormContainer.title"
                )}`,
                description: `${t(
                  "component.user.SidebarComponent.theGarageDescreption"
                )}`,
                logo: "/assets/images/logo.png",
              }),
              {
                name: "description",
                content: `${t(
                  "component.user.SidebarComponent.theGarageDescreption"
                )}`,
              },
            ]} /><PopupCard
              icon={<IoMdClose
                size={32}
                className="text-white"
                onClick={() => {
                  navigate(-1);
                } } />}
            >
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA as string}
              >
                <BeOurPartnerFormContainer />
              </GoogleReCaptchaProvider>
            </PopupCard></>
      ) : (
        <RegistrationCloseComponents link={appRoutesObj.getBasePath()} />
      )}
    </Layout>
  );
}

export default BeOurPartnerFormPage;
