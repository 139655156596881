import { makeRequest, baseURL } from "src/core/data-access/http.service";
import { HttpMethods } from "src/core/enums/httpMethods.enum";

export const postEventsBookService = async (
  data: object,
  reCaptchaToken: string,
) => {
  return await makeRequest({
    url: `${baseURL}/book-event`,
    method: HttpMethods.POST,
    data,
    headers: { "recaptcha-token": reCaptchaToken },
  });
};

export const getEventsBookStatusService = async () => {
  return await makeRequest({
    url: `${baseURL}/book-event/status`,
    method: HttpMethods.GET,
  });
};
