import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import PopupCard from "src/components/common/PopupCard";

interface RegistrationCloseProps {
  link?: string;
}

function RegistrationCloseComponents(prop: RegistrationCloseProps) {
  const { t, i18n } = useTranslation();

  return (
    <PopupCard
      link={prop.link}
      icon={
        i18n.language === "en" ? (
          <AiOutlineArrowRight size={32} className="text-white" />
        ) : (
          <AiOutlineArrowLeft size={32} className="text-white" />
        )
      }
    >
      <div className="mobile:h-full">
        <div className="grid justify-center items-center mx-auto mt-28">
          <img
            alt=""
            src="/assets/images/registrationClose.png"
            width={146}
            height={146}
          />
        </div>
        <div className="text-center grid justify-center items-center mx-auto my-2">
          <h5
            className={`text-white ${
              i18n.language === "en"
                ? "text-xl font-helveticaBold"
                : "text-3xl font-arMyriad"
            } `}
          >
            {t("component.user.RegistrationClose.title")}
          </h5>

          <h5
            className={`text-white ${
              i18n.language === "en"
                ? "text-base font-Helvetica-Light-Condensed"
                : "text-2xl font-arMyriad"
            } `}
          >
            {t("component.user.RegistrationClose.subTitle")}
          </h5>
          <h5
            className={`text-white ${
              i18n.language === "en"
                ? "text-base font-Helvetica-Light-Condensed"
                : "hidden"
            } `}
          >
            {t("component.user.RegistrationClose.subTitle2")}
          </h5>
        </div>
      </div>
    </PopupCard>
  );
}

export default RegistrationCloseComponents;
