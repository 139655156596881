import { IssueType } from "src/core/types/user.type";

export const issueInitialValues: IssueType = {
  details: "",
  email: "",
  browser: "",
  operating_system: "",
  image_url: "",

  
};
