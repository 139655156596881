import { useTranslation } from "react-i18next";
import { FiAlertOctagon } from "react-icons/fi";
import { Link } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";

import { Button } from "./ui/Button";

interface ReportProblemButtonComponentProp{
    title?:string,
    onClick?: () => void;
}

export function ReportProblemButtonComponent(prop:ReportProblemButtonComponentProp) {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex flex-col mobile:static items-center justify-center">
            <Button onClick={prop.onClick} type="submit">{prop.title ?? t("app.shared.button.send")} </Button>
            <Link to={appRoutesObj.getIssuePagePath()}>
              <div className="flex flex-row gap-2 pt-2 text-gray-400">
                <FiAlertOctagon className="w-4 h-4" />
                <p className={` text-gray-400 ${i18n.language === 'en' ? "text-[11px] font-helveticaMedium" : "text-[13px] font-arMyriad"}`}>
                  {t("app.shared.button.problem")}
                </p>
              </div>
            </Link>
          </div>
  );
}
