import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { handleRequestError } from "src/core/helpers/error.helper";
import { GarageNewsType } from "src/core/types/user.type";
import { getNewsByIdService } from "src/services/news.service";

function NewsDetailsContainer() {
  const { i18n, t } = useTranslation();
  const [news, setNews] = useState<GarageNewsType>();
  const { newsId = "" } = useParams<{ newsId: string }>();

  const getNewsById = useCallback(async () => {
    try {
      const newsLater: GarageNewsType = await getNewsByIdService(
        newsId,
        i18n.language.toUpperCase()
      );
      setNews(newsLater);
    } catch (error) {
      handleRequestError(error);
    }
  }, [newsId, i18n.language]);

  useEffect(() => {
    if (newsId) {
      getNewsById();
    }
  }, [getNewsById, newsId]);

  return (
    <div className="px-3 mobile:overflow-y-auto mobile:h-[600px]">
      <p className="text-whiteColor text-sm font-bold pb-4">
        {t(`${news?.title}`)}
      </p>
      <img
        className="h-[285px] w-full object-cover object-center rounded-2xl"
        src={news?.url}
        alt="logo"
      />
      <div className="text-neutral-400 text-xs font-bold pt-5 text-justify leading-4">
        {t(`${news?.description}`)}
      </div>
    </div>
  );
}

export default NewsDetailsContainer;
