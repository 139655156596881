// eslint-disable-next-line import/named
import { AxiosRequestHeaders } from "axios";

import axiosObject from "../configs/axios.config";
import { HttpMethods } from "../enums/httpMethods.enum";

export const baseURL = process.env.REACT_APP_BACKEND_URL;

export type MakeRequest = {
  url: string;
  method: HttpMethods;
  data?: object | undefined;
  params?: object | undefined;
  headers?: AxiosRequestHeaders | object;
};

export const makeRequest = async (req: MakeRequest) => {
  const { url, method, data, params, headers } = req;

  return axiosObject({
    url,
    method,
    data,
    params,
    headers,
  }).then((res) => {
    return res.data;
  });
};
