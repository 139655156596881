import { useTranslation } from "react-i18next";
interface titleComponent {
  title?: string;
  color?: string;
  size?: string;
}


function TitleComponent({ title, color, size }: titleComponent) {
  const { t, i18n } = useTranslation();

  return (
    <div className="pb-3.5 w-fit">
      <h1
        className={`${size || "text-sm"} ${color || "text-white"}
        ${i18n.language === "en"
            ? "font-helveticaMedium pb-1"
            : "font-arMyriad pb-0 largeLaptop:-mb-1"
          }
         `}
      >
        {t(`${title}`)}
      </h1>
      <div className="w-[41px] h-1 bg-yellow rounded">

      </div>
      {/* <div
        className={`h-1 ${i18n.language === "en" ? "mr-4" : "ml-4"
          } bg-yellow rounded`}
      ></div> */}
    </div>
  );
}

export default TitleComponent;
