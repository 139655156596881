import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useModeStore from "src/core/helpers/useModeStore";
import { reservationItems } from "src/core/statics/booking.static";

import CardComponent from "./card-component";

function ReservationComponent() {
  const { t, i18n } = useTranslation();
  const { mode } = useModeStore();
  const isDarkMode = mode === "dark";

  return (
    <div
      className={`group flex flex-row w-full desktop:w-[calc(75vw-554px)] 
      largeLaptop:w-[calc(52vw-226px)] laptop:w-[calc(75vw-364px)] flex-nowrap gap-6 overflow-hidden 
      [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-100px),transparent_100%)]
      hover:[mask-image:none]
      hover:overflow-x-auto hover:px-1 transform`}
    >
      <div
        className={`flex flex-row w-fit gap-6 items-center justify-center md:justify-start h-full py-2 group-hover:animate-none ${
          i18n.language === "ar"
            ? "animate-infinite-scroll"
            : "animate-infinite-scrollENG"
        } `}
      >
        {reservationItems.map((item, index) => (
          <Link to={item.link} key={index}>
            <CardComponent
              isHover={true}
              className={`w-[415px] h-[119px] largeLaptop:h-[100px] laptop:h-[100px] rounded-2xl from-neutral-900/50 via-neutral-900/50 to-linearGreen ${
                i18n.language === "en"
                  ? "bg-gradient-to-br"
                  : "bg-gradient-to-bl"
              } ${
                isDarkMode
                  ? "border border-gray-600/50"
                  : "border border-naturalGray500"
              }`}
            >
              <div className="flex flex-col w-[100%]">
                <div
                  className={`-mt-0.5 pb-1 text-white ${
                    i18n.language === "ar"
                      ? "font-arMyriad text-[19px] "
                      : "font-helveticaMedium text-base"
                  } `}
                >
                  {t(`${item.title}`)}
                </div>
                <div
                  className={`text-white text-xs h-8 pb-2 laptop:text-[10px] ${
                    i18n.language === "en"
                      ? "font-helveticaMedium"
                      : "font-arMyriad text-right"
                  }`}
                >
                  {t(`${item.description}`)}
                </div>
              </div>
            </CardComponent>
          </Link>
        ))}
      </div>
      <div
        aria-hidden="true"
        className={`flex flex-row w-fit gap-6 items-center justify-center md:justify-start h-full py-2 group-hover:animate-none group-hover:hidden ${
          i18n.language === "ar"
            ? "animate-infinite-scroll"
            : "animate-infinite-scrollENG"
        } `}
      >
        {reservationItems.map((item, index) => (
          <Link to={item.link} key={index}>
            <CardComponent
              isHover={true}
              className={`w-[415px] h-[119px] largeLaptop:h-[100px] laptop:h-[100px] rounded-2xl from-neutral-900/50 via-neutral-900/50 to-linearGreen ${
                i18n.language === "en"
                  ? "bg-gradient-to-br"
                  : "bg-gradient-to-bl"
              } ${
                isDarkMode
                  ? "border border-gray-600/50"
                  : "border border-naturalGray500"
              }`}
            >
              <div className="flex flex-col w-[100%]">
                <div
                  className={`-mt-0.5 pb-1 text-white ${
                    i18n.language === "ar"
                      ? "font-arMyriad text-[19px] "
                      : "font-helveticaMedium text-base"
                  } `}
                >
                  {t(`${item.title}`)}
                </div>
                <div
                  className={`text-white text-xs h-8 pb-2 laptop:text-[10px] ${
                    i18n.language === "en"
                      ? "font-helveticaMedium"
                      : "font-arMyriad text-right"
                  }`}
                >
                  {t(`${item.description}`)}
                </div>
              </div>
            </CardComponent>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default ReservationComponent;
