import * as list from "src/core/statics/form-data/form-data";
import {
  getListDisruptOneMock,
  getListDisruptTwoMock,
  getListDisruptThreeMock,
  getListDisruptFourMock,
  getListDisruptFiveMock,
  getListDisruptSixMock,
  getListDisruptSevenMock,
  getListDisruptEightMock,
} from "src/services/mocks/list-disrupt-service.mock/list-disrupt-service.mock";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getListDisruptService = (distrup: any) => {
  switch (distrup) {
    case list.disrupt_en[0]:
      return getListDisruptOneMock;
    case list.disrupt_en[1]:
      return getListDisruptTwoMock;
    case list.disrupt_en[2]:
      return getListDisruptThreeMock;
    case list.disrupt_en[3]:
      return getListDisruptFourMock;
    case list.disrupt_en[4]:
      return getListDisruptFiveMock;
    case list.disrupt_en[5]:
      return getListDisruptSixMock;
    case list.disrupt_en[6]:
      return getListDisruptSevenMock;
    case list.disrupt_en[7]:
      return getListDisruptEightMock;
    default:
      return [];
  }
};
