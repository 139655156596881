import { t } from "i18next";
import * as Yup from "yup";
export const validationSchema = () =>
  Yup.object().shape({
    firstName: Yup.string().required(t("form.validation.required").toString()),
    lastName: Yup.string().required(t("form.validation.required").toString()),
    email: Yup.string()
      .required(t("form.validation.required").toString())
      .email(t("form.validation.email").toString()),
    mobile: Yup.string()
      .required(t("form.validation.required").toString())
      .matches(/^\d+$/, t("form.validation.numbers").toString()),
    company: Yup.string().required(t("form.validation.required").toString()),
    position: Yup.string().required(t("form.validation.required").toString()),
    event_name: Yup.string().required(t("form.validation.required").toString()),
    other: Yup.string().optional(),
    event_type: Yup.string().required(t("form.validation.required").toString()),
    start_at: Yup.string().required(),
    end_at: Yup.string().required(),
    guest_count: Yup.number().required(
      t("form.validation.required").toString(),
    ),
  });
