import { useTranslation } from "react-i18next";

export interface NextPatchData {
  nextPatch: NextPatch[];
}

export interface NextPatch {
  text: string;
}

export function IncubatorIndividualContainer(nextPatch: NextPatchData) {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex relative w-full ">
      <div
        className={`w-full pt-5 ${
          i18n.language === "en" ? "pb-[1.2px]" : "pb-[1.5px]"
        }`}
      >
        <img
          src="/assets/images/start-knot.svg"
          className={`absolute ${
            i18n.language === "en" ? "bottom-0" : "rotate-180"
          } -left-5  tablet:hidden mobile:hidden`}
        />
        <div className="relative flex w-[88%] laptop:w-[89%] laptop:mx-9 flex-row h-[3px] justify-between items-center mx-auto bg-secondaryGreen mobile:mx-24 tablet:mx-24  mobile:w-0.5 mobile:h-[420px] tablet:w-1 tablet:h-[420px]  mobile:flex-col  tablet:flex-col">
          {nextPatch.nextPatch.map((items, index) => (
            <div className="flex items-center justify-center" key={index}>
              <span className="absolute w-3 mobile:w-2 tablet:w-2 mx-auto">
                <span className="animate-ping absolute rounded-full h-3 w-3 mobile:h-2 mobile:w-2 tablet:h-2.5   tablet:w-2.5  bg-secondaryGreen opacity-75"></span>
                <span className="flex rounded-full h-3 w-3 mobile:h-2 mobile:w-2 tablet:h-2 tablet:w-2 bg-secondaryGreen"></span>
              </span>
              <p
                className={`pt-8 mobile:pt-2 mobile:w-[300px] tablet:pt-2 tablet:w-[300px] w-[95px] h-9 text-center mobile:text-start tablet:text-start  text-naturalGray400 ${
                  i18n.language === "en"
                    ? "font-helveticaMedium text-[13px] mobile:text-[11px] mobile:pl-40 tablet:pl-40"
                    : "font-arMyriad text-base mobile:pr-40 tablet:pr-40"
                }`}
              >
                {t(`${items.text}`)}
              </p>
            </div>
          ))}
        </div>
        <img
          src="/assets/images/end-knot.svg"
          className={`absolute ${
            i18n.language === "en" ? "top-[19px] " : "rotate-180  bottom-px"
          } -right-3.5 tablet:hidden mobile:hidden`}
        />
      </div>
    </div>
  );
}
