/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface PopupTapsComponentProps {
  children: ReactElement;
  link?: string;
  icon: JSX.Element;
  taps: ReactElement;
  index: number;
}
function PopupTapsComponent({
  children,
  icon,
  link,
  taps,
  index,
}: PopupTapsComponentProps) {
  const { i18n } = useTranslation();

  return (
    <div className="w-[100%] tablet:py-0 mobile:py-0 mobile:pt-4 mobile:min-h-screen tablet:min-h-screen">
      <div className="w-[100%] rounded-2xl py-3.5 border-1 mx-auto border border-gray-600/50 mobile:rounded-none mobile:border-none tablet:mt-6">
        <div className="px-2 mobile:px-1">
          <div className="flex justify-between">
            <div className="flex mobile:gap-0 mobile:pt-7">{taps}</div>

            <div className="flex items-center justify-end mobile:relative mobile:bottom-6">
              <Link to={link!}>{icon}</Link>
            </div>
          </div>

          <div
            className={`${
              i18n.language === "ar"
                ? "h-[590px] largeLaptop:h-[464px]"
                : "h-[576px] largeLaptop:h-[458px]"
            } mobile:h-fit pt-5 mobile:py-5 mobile:px-3  tablet:px-3 tablet:h-auto overflow-y-auto rounded-lg backdrop-filter backdrop-blur-lg mobile:backdrop-blur-none mobile:backdrop-filter-none mobile:bg-gradient-to-b mobile:from-[rgba(120,113,108,0.06)] mobile:to-[rgba(120,113,108,0.1)]
           ${
             index === 0
               ? i18n.language === "ar"
                 ? "rounded-tr-none"
                 : "rounded-tl-none"
               : "rounded-lg"
           }`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupTapsComponent;
