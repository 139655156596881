import { BeOurPartnerList } from "src/core/types/be-our-partner-type";

export const beOurPartnerList: BeOurPartnerList = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  company: "",
  position: "",
  partnershiptype: "",
  other: "",
};
