import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { DirectionEnum } from "src/core/enums/ui.enums";

interface selectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  selectLabel: string;
  errorMsg?: string;
  value?: string | number;
  disabled?: boolean;
  isValid?: boolean;
  options: string[];
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

export default function SelectComponent({
  name,
  value,
  selectLabel,
  disabled,

  errorMsg = "",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isValid = true,
  options,
  onChange,
  ...props
}: selectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const getError = () => {
    if (errorMsg !== "") {
      isValid = false;
      return "border-red-500 focus:border-red-500 focus:border-2 ";
    }
    isValid = true;
    return "";
  };

  return (
    <div className="relative w-full">
      <select
        name={name}
        disabled={disabled}
        onChange={onChange}
        value={value}
        className={`block focus:border focus:border-borderColor h-14 mobile:h-12 rounded-lg px-2.5 pb-0 mobile:pb-0 tablet:pb-0 pt-5 w-full ${
          i18n.language === "en"
            ? "font-helveticaMedium text-base"
            : "font-arMyriad text-xl"
        } text-white bg-neutral-500/10 border-neutral-500/10 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer ${
          errorMsg !== "" ? `border ${getError()}` : ""
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <option
          defaultValue={""}
          value=""
          disabled
          hidden
          className="mobile:text-xs"
        >
          {t("app.shared.select")}
        </option>
        {options.map((value, index) => (
          <option key={index} value={value}>
            {`${t(value)}`}
          </option>
        ))}
      </select>
      <label
        className={`absolute ${
          i18n.language === "en"
            ? "font-helveticaMedium text-base "
            : "font-arMyriad text-xl "
        } text-gray-500 peer-focus:translate-x-1 duration-300 transform -translate-y-4 scale-70 top-4 z-10 origin-[0] start-2.5  ${
          props.dir === DirectionEnum.LTR ? "left-0" : "right-0 "
        }`}
      >
        {t(selectLabel)}
      </label>
      <div
        className={`flex justify-between px-2 py-1 text-xs ${
          i18n.language === "en" ? "font-helveticaMedium" : "font-arMyriad"
        } text-gray-900`}
      >
        {errorMsg !== "" ? (
          <p className={`${getError()} text-red-500`}>{t(errorMsg)}</p>
        ) : (
          <p className={`${getError()}`}>{""}</p>
        )}
      </div>
    </div>
  );
}
