import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SharedSectionComponent from "src/components/shard-Section";
import CardComponent from "src/components/user/card-component";
import { programsData } from "src/core/statics/programs-data.static";

function Programs() {
  const { t, i18n } = useTranslation();

  return (
    <div className="tablet:px-24 ">
      <SharedSectionComponent
        component={
          <div
            className={`grid grid-cols-2 gap-4 mobile:grid-cols-1 tablet:grid-cols-1`}
          >
            {programsData.map((items, index) => (
              <Link key={index} to={items.path}>
                <CardComponent
                  isHover={true}
                  className={`w-[100%]  h-[100%] largeLaptop:h-[87px] largeLaptop:whitespace-nowrap pb-[6%] mobile:pb-[2%] from-neutral-900/50 via-neutral-900/50 to-linearGreen ${
                    i18n.language === "en"
                      ? "bg-gradient-to-br"
                      : "bg-gradient-to-bl"
                  }`}
                >
                  <img
                    src={i18n.language === "en" ? items.img : items.imgAr}
                    alt={items.title}
                    className={`-rotate-[3.23deg] ${
                      i18n.language === "en"
                        ? "w-[40%] tablet:w-[25%]"
                        : "w-[30%] tablet:w-[20%]"
                    }`}
                  />
                  <p
                    className={`text-naturalGray400 line-clamp-2 pt-[4.5%] ${
                      i18n.language === "en"
                        ? "text-left font-helveticaMedium text-sm largeLaptop:text-[13px]"
                        : "text-right font-arMyriad text-lg largeLaptop:text-base"
                    } `}
                  >
                    {t(items.description)}
                  </p>
                </CardComponent>
              </Link>
            ))}
          </div>
        }
        title={"containers.user.landing.programsTitel"}
      />
    </div>
  );
}

export default Programs;
