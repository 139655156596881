export const dynamicUsedClasses = [
  "bg-primary-50",
  "bg-primary",
  "bg-primary-100",
  "bg-primary-200",
  "bg-primary-300",
  "bg-primary-400",
  "bg-primary-500",
  "bg-primary-600",
  "bg-primary-700",
  "bg-primary-800",
  "bg-primary-900",
  "bg-secondary-50",
  "bg-secondary",
  "bg-secondary-100",
  "bg-secondary-200",
  "bg-secondary-300",
  "bg-secondary-400",
  "bg-secondary-500",
  "bg-secondary-600",
  "bg-secondary-700",
  "bg-secondary-800",
  "bg-secondary-900",
  "bg-danger-50",
  "bg-danger",
  "bg-danger-100",
  "bg-danger-200",
  "bg-danger-300",
  "bg-danger-400",
  "bg-danger-500",
  "bg-danger-600",
  "bg-danger-700",
  "bg-danger-800",
  "bg-danger-900",
  "bg-success-50",
  "bg-success",
  "bg-success-100",
  "bg-success-200",
  "bg-success-300",
  "bg-success-400",
  "bg-success-500",
  "bg-success-600",
  "bg-success-700",
  "bg-success-800",
  "bg-success-900",
  "bg-warning-50",
  "bg-warning",
  "bg-warning-100",
  "bg-warning-200",
  "bg-warning-300",
  "bg-warning-400",
  "bg-warning-500",
  "bg-warning-600",
  "bg-warning-700",
  "bg-warning-800",
  "bg-warning-900",
  "bg-accent-50",
  "bg-accent",
  "bg-accent-100",
  "bg-accent-200",
  "bg-accent-300",
  "bg-accent-400",
  "bg-accent-500",
  "bg-accent-600",
  "bg-accent-700",
  "bg-accent-800",
  "bg-accent-900",
  "bg-muted-50",
  "bg-muted",
  "bg-muted-100",
  "bg-muted-200",
  "bg-muted-300",
  "bg-muted-400",
  "bg-muted-500",
  "bg-muted-600",
  "bg-muted-700",
  "bg-muted-800",
  "bg-muted-900",
  "hover:bg-primary-50",
  "hover:bg-primary",
  "focus:bg-primary-50",
  "focus:bg-primary",
  "hover:bg-primary-100",
  "focus:bg-primary-100",
  "hover:bg-primary-200",
  "focus:bg-primary-200",
  "hover:bg-primary-300",
  "focus:bg-primary-300",
  "hover:bg-primary-400",
  "focus:bg-primary-400",
  "hover:bg-primary-500",
  "focus:bg-primary-500",
  "hover:bg-primary-600",
  "focus:bg-primary-600",
  "hover:bg-primary-700",
  "focus:bg-primary-700",
  "hover:bg-primary-800",
  "focus:bg-primary-800",
  "hover:bg-primary-900",
  "focus:bg-primary-900",
  "hover:bg-secondary-50",
  "hover:bg-secondary",
  "focus:bg-secondary-50",
  "focus:bg-secondary",
  "hover:bg-secondary-100",
  "focus:bg-secondary-100",
  "hover:bg-secondary-200",
  "focus:bg-secondary-200",
  "hover:bg-secondary-300",
  "focus:bg-secondary-300",
  "hover:bg-secondary-400",
  "focus:bg-secondary-400",
  "hover:bg-secondary-500",
  "focus:bg-secondary-500",
  "hover:bg-secondary-600",
  "focus:bg-secondary-600",
  "hover:bg-secondary-700",
  "focus:bg-secondary-700",
  "hover:bg-secondary-800",
  "focus:bg-secondary-800",
  "hover:bg-secondary-900",
  "focus:bg-secondary-900",
  "hover:bg-danger-50",
  "hover:bg-danger",
  "focus:bg-danger-50",
  "focus:bg-danger",
  "hover:bg-danger-100",
  "focus:bg-danger-100",
  "hover:bg-danger-200",
  "focus:bg-danger-200",
  "hover:bg-danger-300",
  "focus:bg-danger-300",
  "hover:bg-danger-400",
  "focus:bg-danger-400",
  "hover:bg-danger-500",
  "focus:bg-danger-500",
  "hover:bg-danger-600",
  "focus:bg-danger-600",
  "hover:bg-danger-700",
  "focus:bg-danger-700",
  "hover:bg-danger-800",
  "focus:bg-danger-800",
  "hover:bg-danger-900",
  "focus:bg-danger-900",
  "hover:bg-success-50",
  "hover:bg-success",
  "focus:bg-success-50",
  "focus:bg-success",
  "hover:bg-success-100",
  "focus:bg-success-100",
  "hover:bg-success-200",
  "focus:bg-success-200",
  "hover:bg-success-300",
  "focus:bg-success-300",
  "hover:bg-success-400",
  "focus:bg-success-400",
  "hover:bg-success-500",
  "focus:bg-success-500",
  "hover:bg-success-600",
  "focus:bg-success-600",
  "hover:bg-success-700",
  "focus:bg-success-700",
  "hover:bg-success-800",
  "focus:bg-success-800",
  "hover:bg-success-900",
  "focus:bg-success-900",
  "hover:bg-warning-50",
  "hover:bg-warning",
  "focus:bg-warning-50",
  "focus:bg-warning",
  "hover:bg-warning-100",
  "focus:bg-warning-100",
  "hover:bg-warning-200",
  "focus:bg-warning-200",
  "hover:bg-warning-300",
  "focus:bg-warning-300",
  "hover:bg-warning-400",
  "focus:bg-warning-400",
  "hover:bg-warning-500",
  "focus:bg-warning-500",
  "hover:bg-warning-600",
  "focus:bg-warning-600",
  "hover:bg-warning-700",
  "focus:bg-warning-700",
  "hover:bg-warning-800",
  "focus:bg-warning-800",
  "hover:bg-warning-900",
  "focus:bg-warning-900",
  "hover:bg-accent-50",
  "hover:bg-accent",
  "focus:bg-accent-50",
  "focus:bg-accent",
  "hover:bg-accent-100",
  "focus:bg-accent-100",
  "hover:bg-accent-200",
  "focus:bg-accent-200",
  "hover:bg-accent-300",
  "focus:bg-accent-300",
  "hover:bg-accent-400",
  "focus:bg-accent-400",
  "hover:bg-accent-500",
  "focus:bg-accent-500",
  "hover:bg-accent-600",
  "focus:bg-accent-600",
  "hover:bg-accent-700",
  "focus:bg-accent-700",
  "hover:bg-accent-800",
  "focus:bg-accent-800",
  "hover:bg-accent-900",
  "focus:bg-accent-900",
  "hover:bg-muted-50",
  "hover:bg-muted",
  "focus:bg-muted-50",
  "focus:bg-muted",
  "hover:bg-muted-100",
  "focus:bg-muted-100",
  "hover:bg-muted-200",
  "focus:bg-muted-200",
  "hover:bg-muted-300",
  "focus:bg-muted-300",
  "hover:bg-muted-400",
  "focus:bg-muted-400",
  "hover:bg-muted-500",
  "focus:bg-muted-500",
  "hover:bg-muted-600",
  "focus:bg-muted-600",
  "hover:bg-muted-700",
  "focus:bg-muted-700",
  "hover:bg-muted-800",
  "focus:bg-muted-800",
  "hover:bg-muted-900",
  "focus:bg-muted-900",
  // borders
  "border-primary-50",
  "border-primary",
  "hover:border-primary-50",
  "hover:border-primary",
  "focus:border-primary-50",
  "focus:border-primary",
  "border-primary-100",
  "hover:border-primary-100",
  "focus:border-primary-100",
  "border-primary-200",
  "hover:border-primary-200",
  "focus:border-primary-200",
  "border-primary-300",
  "hover:border-primary-300",
  "focus:border-primary-300",
  "border-primary-400",
  "hover:border-primary-400",
  "focus:border-primary-400",
  "border-primary-500",
  "hover:border-primary-500",
  "focus:border-primary-500",
  "border-primary-600",
  "hover:border-primary-600",
  "focus:border-primary-600",
  "border-primary-700",
  "hover:border-primary-700",
  "focus:border-primary-700",
  "border-primary-800",
  "hover:border-primary-800",
  "focus:border-primary-800",
  "border-primary-900",
  "hover:border-primary-900",
  "focus:border-primary-900",
  "border-secondary-50",
  "border-secondary",
  "hover:border-secondary-50",
  "hover:border-secondary",
  "focus:border-secondary-50",
  "focus:border-secondary",
  "border-secondary-100",
  "hover:border-secondary-100",
  "focus:border-secondary-100",
  "border-secondary-200",
  "hover:border-secondary-200",
  "focus:border-secondary-200",
  "border-secondary-300",
  "hover:border-secondary-300",
  "focus:border-secondary-300",
  "border-secondary-400",
  "hover:border-secondary-400",
  "focus:border-secondary-400",
  "border-secondary-500",
  "hover:border-secondary-500",
  "focus:border-secondary-500",
  "border-secondary-600",
  "hover:border-secondary-600",
  "focus:border-secondary-600",
  "border-secondary-700",
  "hover:border-secondary-700",
  "focus:border-secondary-700",
  "border-secondary-800",
  "hover:border-secondary-800",
  "focus:border-secondary-800",
  "border-secondary-900",
  "hover:border-secondary-900",
  "focus:border-secondary-900",
  "border-danger-50",
  "border-danger",
  "hover:border-danger-50",
  "hover:border-danger",
  "focus:border-danger-50",
  "focus:border-danger",
  "border-danger-100",
  "hover:border-danger-100",
  "focus:border-danger-100",
  "border-danger-200",
  "hover:border-danger-200",
  "focus:border-danger-200",
  "border-danger-300",
  "hover:border-danger-300",
  "focus:border-danger-300",
  "border-danger-400",
  "hover:border-danger-400",
  "focus:border-danger-400",
  "border-danger-500",
  "hover:border-danger-500",
  "focus:border-danger-500",
  "border-danger-600",
  "hover:border-danger-600",
  "focus:border-danger-600",
  "border-danger-700",
  "hover:border-danger-700",
  "focus:border-danger-700",
  "border-danger-800",
  "hover:border-danger-800",
  "focus:border-danger-800",
  "border-danger-900",
  "hover:border-danger-900",
  "focus:border-danger-900",
  "border-success-50",
  "border-success",
  "hover:border-success-50",
  "hover:border-success",
  "focus:border-success-50",
  "focus:border-success",
  "border-success-100",
  "hover:border-success-100",
  "focus:border-success-100",
  "border-success-200",
  "hover:border-success-200",
  "focus:border-success-200",
  "border-success-300",
  "hover:border-success-300",
  "focus:border-success-300",
  "border-success-400",
  "hover:border-success-400",
  "focus:border-success-400",
  "border-success-500",
  "hover:border-success-500",
  "focus:border-success-500",
  "border-success-600",
  "hover:border-success-600",
  "focus:border-success-600",
  "border-success-700",
  "hover:border-success-700",
  "focus:border-success-700",
  "border-success-800",
  "hover:border-success-800",
  "focus:border-success-800",
  "border-success-900",
  "hover:border-success-900",
  "focus:border-success-900",
  "border-warning-50",
  "border-warning",
  "hover:border-warning-50",
  "hover:border-warning",
  "focus:border-warning-50",
  "focus:border-warning",
  "border-warning-100",
  "hover:border-warning-100",
  "focus:border-warning-100",
  "border-warning-200",
  "hover:border-warning-200",
  "focus:border-warning-200",
  "border-warning-300",
  "hover:border-warning-300",
  "focus:border-warning-300",
  "border-warning-400",
  "hover:border-warning-400",
  "focus:border-warning-400",
  "border-warning-500",
  "hover:border-warning-500",
  "focus:border-warning-500",
  "border-warning-600",
  "hover:border-warning-600",
  "focus:border-warning-600",
  "border-warning-700",
  "hover:border-warning-700",
  "focus:border-warning-700",
  "border-warning-800",
  "hover:border-warning-800",
  "focus:border-warning-800",
  "border-warning-900",
  "hover:border-warning-900",
  "focus:border-warning-900",
  "border-accent-50",
  "border-accent",
  "hover:border-accent-50",
  "hover:border-accent",
  "focus:border-accent-50",
  "focus:border-accent",
  "border-accent-100",
  "hover:border-accent-100",
  "focus:border-accent-100",
  "border-accent-200",
  "hover:border-accent-200",
  "focus:border-accent-200",
  "border-accent-300",
  "hover:border-accent-300",
  "focus:border-accent-300",
  "border-accent-400",
  "hover:border-accent-400",
  "focus:border-accent-400",
  "border-accent-500",
  "hover:border-accent-500",
  "focus:border-accent-500",
  "border-accent-600",
  "hover:border-accent-600",
  "focus:border-accent-600",
  "border-accent-700",
  "hover:border-accent-700",
  "focus:border-accent-700",
  "border-accent-800",
  "hover:border-accent-800",
  "focus:border-accent-800",
  "border-accent-900",
  "hover:border-accent-900",
  "focus:border-accent-900",
  "border-muted-50",
  "border-muted",
  "hover:border-muted-50",
  "hover:border-muted",
  "focus:border-muted-50",
  "focus:border-muted",
  "border-muted-100",
  "hover:border-muted-100",
  "focus:border-muted-100",
  "border-muted-200",
  "hover:border-muted-200",
  "focus:border-muted-200",
  "border-muted-300",
  "hover:border-muted-300",
  "focus:border-muted-300",
  "border-muted-400",
  "hover:border-muted-400",
  "focus:border-muted-400",
  "border-muted-500",
  "hover:border-muted-500",
  "focus:border-muted-500",
  "border-muted-600",
  "hover:border-muted-600",
  "focus:border-muted-600",
  "border-muted-700",
  "hover:border-muted-700",
  "focus:border-muted-700",
  "border-muted-800",
  "hover:border-muted-800",
  "focus:border-muted-800",
  "border-muted-900",
  "hover:border-muted-900",
  "focus:border-muted-900",
  // texts
  "text-primary-50",
  "text-primary",
  "hover:text-primary-50",
  "hover:text-primary",
  "focus:text-primary-50",
  "focus:text-primary",
  "text-primary-100",
  "hover:text-primary-100",
  "focus:text-primary-100",
  "text-primary-200",
  "hover:text-primary-200",
  "focus:text-primary-200",
  "text-primary-300",
  "hover:text-primary-300",
  "focus:text-primary-300",
  "text-primary-400",
  "hover:text-primary-400",
  "focus:text-primary-400",
  "text-primary-500",
  "hover:text-primary-500",
  "focus:text-primary-500",
  "text-primary-600",
  "hover:text-primary-600",
  "focus:text-primary-600",
  "text-primary-700",
  "hover:text-primary-700",
  "focus:text-primary-700",
  "text-primary-800",
  "hover:text-primary-800",
  "focus:text-primary-800",
  "text-primary-900",
  "hover:text-primary-900",
  "focus:text-primary-900",
  "text-secondary-50",
  "text-secondary",
  "hover:text-secondary-50",
  "hover:text-secondary",
  "focus:text-secondary-50",
  "focus:text-secondary",
  "text-secondary-100",
  "hover:text-secondary-100",
  "focus:text-secondary-100",
  "text-secondary-200",
  "hover:text-secondary-200",
  "focus:text-secondary-200",
  "text-secondary-300",
  "hover:text-secondary-300",
  "focus:text-secondary-300",
  "text-secondary-400",
  "hover:text-secondary-400",
  "focus:text-secondary-400",
  "text-secondary-500",
  "hover:text-secondary-500",
  "focus:text-secondary-500",
  "text-secondary-600",
  "hover:text-secondary-600",
  "focus:text-secondary-600",
  "text-secondary-700",
  "hover:text-secondary-700",
  "focus:text-secondary-700",
  "text-secondary-800",
  "hover:text-secondary-800",
  "focus:text-secondary-800",
  "text-secondary-900",
  "hover:text-secondary-900",
  "focus:text-secondary-900",
  "text-danger-50",
  "text-danger",
  "hover:text-danger-50",
  "hover:text-danger",
  "focus:text-danger-50",
  "focus:text-danger",
  "text-danger-100",
  "hover:text-danger-100",
  "focus:text-danger-100",
  "text-danger-200",
  "hover:text-danger-200",
  "focus:text-danger-200",
  "text-danger-300",
  "hover:text-danger-300",
  "focus:text-danger-300",
  "text-danger-400",
  "hover:text-danger-400",
  "focus:text-danger-400",
  "text-danger-500",
  "hover:text-danger-500",
  "focus:text-danger-500",
  "text-danger-600",
  "hover:text-danger-600",
  "focus:text-danger-600",
  "text-danger-700",
  "hover:text-danger-700",
  "focus:text-danger-700",
  "text-danger-800",
  "hover:text-danger-800",
  "focus:text-danger-800",
  "text-danger-900",
  "hover:text-danger-900",
  "focus:text-danger-900",
  "text-success-50",
  "text-success",
  "hover:text-success-50",
  "hover:text-success",
  "focus:text-success-50",
  "focus:text-success",
  "text-success-100",
  "hover:text-success-100",
  "focus:text-success-100",
  "text-success-200",
  "hover:text-success-200",
  "focus:text-success-200",
  "text-success-300",
  "hover:text-success-300",
  "focus:text-success-300",
  "text-success-400",
  "hover:text-success-400",
  "focus:text-success-400",
  "text-success-500",
  "hover:text-success-500",
  "focus:text-success-500",
  "text-success-600",
  "hover:text-success-600",
  "focus:text-success-600",
  "text-success-700",
  "hover:text-success-700",
  "focus:text-success-700",
  "text-success-800",
  "hover:text-success-800",
  "focus:text-success-800",
  "text-success-900",
  "hover:text-success-900",
  "focus:text-success-900",
  "text-warning-50",
  "text-warning",
  "hover:text-warning-50",
  "hover:text-warning",
  "focus:text-warning-50",
  "focus:text-warning",
  "text-warning-100",
  "hover:text-warning-100",
  "focus:text-warning-100",
  "text-warning-200",
  "hover:text-warning-200",
  "focus:text-warning-200",
  "text-warning-300",
  "hover:text-warning-300",
  "focus:text-warning-300",
  "text-warning-400",
  "hover:text-warning-400",
  "focus:text-warning-400",
  "text-warning-500",
  "hover:text-warning-500",
  "focus:text-warning-500",
  "text-warning-600",
  "hover:text-warning-600",
  "focus:text-warning-600",
  "text-warning-700",
  "hover:text-warning-700",
  "focus:text-warning-700",
  "text-warning-800",
  "hover:text-warning-800",
  "focus:text-warning-800",
  "text-warning-900",
  "hover:text-warning-900",
  "focus:text-warning-900",
  "text-accent-50",
  "text-accent",
  "hover:text-accent-50",
  "hover:text-accent",
  "focus:text-accent-50",
  "focus:text-accent",
  "text-accent-100",
  "hover:text-accent-100",
  "focus:text-accent-100",
  "text-accent-200",
  "hover:text-accent-200",
  "focus:text-accent-200",
  "text-accent-300",
  "hover:text-accent-300",
  "focus:text-accent-300",
  "text-accent-400",
  "hover:text-accent-400",
  "focus:text-accent-400",
  "text-accent-500",
  "hover:text-accent-500",
  "focus:text-accent-500",
  "text-accent-600",
  "hover:text-accent-600",
  "focus:text-accent-600",
  "text-accent-700",
  "hover:text-accent-700",
  "focus:text-accent-700",
  "text-accent-800",
  "hover:text-accent-800",
  "focus:text-accent-800",
  "text-accent-900",
  "hover:text-accent-900",
  "focus:text-accent-900",
  "text-muted-50",
  "text-muted",
  "hover:text-muted-50",
  "hover:text-muted",
  "focus:text-muted-50",
  "focus:text-muted",
  "text-muted-100",
  "hover:text-muted-100",
  "focus:text-muted-100",
  "text-muted-200",
  "hover:text-muted-200",
  "focus:text-muted-200",
  "text-muted-300",
  "hover:text-muted-300",
  "focus:text-muted-300",
  "text-muted-400",
  "hover:text-muted-400",
  "focus:text-muted-400",
  "text-muted-500",
  "hover:text-muted-500",
  "focus:text-muted-500",
  "text-muted-600",
  "hover:text-muted-600",
  "focus:text-muted-600",
  "text-muted-700",
  "hover:text-muted-700",
  "focus:text-muted-700",
  "text-muted-800",
  "hover:text-muted-800",
  "focus:text-muted-800",
  "text-muted-900",
  "hover:text-muted-900",
  "focus:text-muted-900",
  "checked:bg-primary-50",
  "checked:bg-primary",
  "checked:bg-primary-100",
  "checked:bg-primary-200",
  "checked:bg-primary-300",
  "checked:bg-primary-400",
  "checked:bg-primary-500",
  "checked:bg-primary-600",
  "checked:bg-primary-700",
  "checked:bg-primary-800",
  "checked:bg-primary-900",
  "checked:bg-secondary-50",
  "checked:bg-secondary",
  "checked:bg-secondary-100",
  "checked:bg-secondary-200",
  "checked:bg-secondary-300",
  "checked:bg-secondary-400",
  "checked:bg-secondary-500",
  "checked:bg-secondary-600",
  "checked:bg-secondary-700",
  "checked:bg-secondary-800",
  "checked:bg-secondary-900",
  "checked:bg-danger-50",
  "checked:bg-danger",
  "checked:bg-danger-100",
  "checked:bg-danger-200",
  "checked:bg-danger-300",
  "checked:bg-danger-400",
  "checked:bg-danger-500",
  "checked:bg-danger-600",
  "checked:bg-danger-700",
  "checked:bg-danger-800",
  "checked:bg-danger-900",
  "checked:bg-success-50",
  "checked:bg-success",
  "checked:bg-success-100",
  "checked:bg-success-200",
  "checked:bg-success-300",
  "checked:bg-success-400",
  "checked:bg-success-500",
  "checked:bg-success-600",
  "checked:bg-success-700",
  "checked:bg-success-800",
  "checked:bg-success-900",
  "checked:bg-warning-50",
  "checked:bg-warning",
  "checked:bg-warning-100",
  "checked:bg-warning-200",
  "checked:bg-warning-300",
  "checked:bg-warning-400",
  "checked:bg-warning-500",
  "checked:bg-warning-600",
  "checked:bg-warning-700",
  "checked:bg-warning-800",
  "checked:bg-warning-900",
  "checked:bg-accent-50",
  "checked:bg-accent",
  "checked:bg-accent-100",
  "checked:bg-accent-200",
  "checked:bg-accent-300",
  "checked:bg-accent-400",
  "checked:bg-accent-500",
  "checked:bg-accent-600",
  "checked:bg-accent-700",
  "checked:bg-accent-800",
  "checked:bg-accent-900",
  "checked:bg-muted-50",
  "checked:bg-muted",
  "checked:bg-muted-100",
  "checked:bg-muted-200",
  "checked:bg-muted-300",
  "checked:bg-muted-400",
  "checked:bg-muted-500",
  "checked:bg-muted-600",
  "checked:bg-muted-700",
  "checked:bg-muted-800",
  "checked:bg-muted-900",
];

export const getElementWithAllClasses = () => {
  const classAsString = `${dynamicUsedClasses.map((c) => `${c} `)}`;

  return <div className={classAsString}></div>;
};
