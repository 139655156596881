import { Layout } from "src/components/common/layout-component/layout-component";
import InternalProgramsContainer from "src/containers/user/internal-programs-container";

function InternalProgramsPage() {
  return (
    <Layout>
      <InternalProgramsContainer />
    </Layout>
  );
}

export default InternalProgramsPage;
