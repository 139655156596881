import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useModeStore from "src/core/helpers/useModeStore";

import Footer from "../Footer-component/footer-component";
import NavBar from "../Navbar-componet/nav-bar-componet";
import SidebarComponent from "../Sidebar-component/sidebar-component";
interface LayoutProps {
  children: ReactElement;
}
export function Layout({ children }: LayoutProps) {
  const { i18n } = useTranslation();
  const { mode } = useModeStore();
  const isDarkMode = mode === "dark";

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("language") || "ar");
  }, [i18n]);

  return (
    <div className="flex">
      <div
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
        className={` flex flex-col justify-center items-center bg-cover h-[100dvh] w-full bg-no-repeat ${
          isDarkMode
            ? "bg-mainBackground bg-black"
            : "bg-mainBackgroundLight bg-gray-500"
        }`}
      >
        <div
          className="flex justify-center mobile:overflow-y-scroll tablet:overflow-y-scroll mobile:h-[100dvh] tablet:h-[100dvh] rounded-[34px] mx-auto bg-naturalGray500/10 backdrop-blur-lg w-[82%] 
      laptop:w-[90%] mobile:rounded-none tablet:rounded-none mobile:w-full tablet:w-full mobile:border-none 
      tablet:border-none border desktop:border-gray-600/50 largeLaptop:border-gray-600/50 laptop:border-gray-600/50 px-4 mobile:px-0 tablet:px-0 py-5 mobile:py-0 tablet:py-0 largeLaptop:my-6 "
        >
          <div className="flex flex-col justify-between mx-auto mobile:hidden tablet:hidden">
            <div className="w-4 h-4 rounded-full bg-neonGreen"></div>
            <div className="w-4 h-4 rounded-full bg-neonGreen"></div>
          </div>
          <div className="flex flex-col w-full">
            <div className="tablet:px-3 mobile:px-3">
              <NavBar />
            </div>

            <div className="flex  gap-x-7">
              <div className="mobile:hidden tablet:hidden">
                <SidebarComponent />
              </div>
              {children}
            </div>
            <div className="mobile:hidden tablet:hidden">
              <Footer />
            </div>
          </div>
          <div className="flex flex-col justify-between mx-auto mobile:hidden tablet:hidden">
            <div className="w-4 h-4 rounded-full bg-neonGreen"></div>
            <div className="w-4 h-4 rounded-full bg-neonGreen"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
