const adminRule = "admin";
export const hasAdminAccess = (rules: string[] = []): boolean => {
  return rules.includes(adminRule);
};

export const getRoundomNumber = (): number => {
  return parseInt((Math.random() * 10).toString());
};

// pagination limit,offset
export const getLimitOffset = (pageNo = 1, resultsInPage = 20) => {
  return {
    limit: resultsInPage,
    offset: (pageNo - 1) * resultsInPage,
  };
};

/**
 * @param object {title,description,image,url}
 * @description
 */

export const opengraphMetaTags = (data: {
  title: string;
  description: string;
  logo: string;
}) => {
  return [
    // open graph
    { name: "og:title", content: `${data?.title}` },
    { name: "og:description", content: `${data?.description}` },
    { name: "og:image", content: `${data?.logo}` },
    { name: "og:url", content: `${window.location.href}` },
    //twitter card
    { name: "twitter:title", content: `${data?.title}` },
    { name: "twitter:description", content: `${data?.description}` },
    { name: "twitter:image", content: `${data?.logo}` },
    { name: "twitter:site", content: `${window.location.href}` },
  ];
};
