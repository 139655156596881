import { makeRequest, baseURL } from "src/core/data-access/http.service";
import { HttpMethods } from "src/core/enums/httpMethods.enum";


export const beOurPartnerApplication = async (
  data: object,
  reCaptchaToken: string,
) => {
  return await makeRequest({
    url: `${baseURL}/be-our-partner`,
    method: HttpMethods.POST,
    data,
    headers: { "recaptcha-token": reCaptchaToken },
  });
};

export const beOurPartnerStatus = async () => {
  return await makeRequest({
    url: `${baseURL}/be-our-partner/status`,
    method: HttpMethods.GET,
  });
};
