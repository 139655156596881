import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import TitleComponent from "./title-component";

interface LayoutProps {
  title: string;
  description: string;
  children: ReactElement;
}

export function RegistrationCardComponent({
  children,
  title,

  description,
}: LayoutProps) {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex flex-col mobile:w-full gap-y-4">
      <div>

        <TitleComponent
          title={title}
          color="text-white"
          size={`${i18n.language === "en" ? "text-base" : "text-2xl"}`}
        />
        <p
          className={`text-naturalGray400  ${i18n.language === "en"
            ? "text-sm font-helveticaMedium pr-44 mobile:pr-0"
            : "text-xl font-arMyriad leading-none"
            } `}
        >
          {t(`${description}`)}
        </p>
      </div>
      <div className="w-full tablet:h-full mobile:h-full">{children}</div>
    </div>
  );
}
