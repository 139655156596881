/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface PopupCardProps {
  children: ReactElement;
  link?: string;
  icon: JSX.Element;
}
function PopupCard({ children, icon, link }: PopupCardProps) {
  const { i18n } = useTranslation();

  return (
    <div className="w-full">
      <div className="h-full w-full largeLaptop:h-auto rounded-2xl p-2 border-1 mx-auto border border-gray-600/50 mobile:p-0 mobile:bg-transparent mobile:rounded-none mobile:border-none tablet:mt-6 tablet:backdrop-blur-[0%] tablet:bg-transparent tablet:rounded-none tablet:border-none">
        <div className="flex items-end justify-end pt-3 pb-2">
          <Link to={link!}>{icon}</Link>
        </div>
        <div
          className={`h-[564px] overflow-y-auto tablet:h-auto mobile:h-auto px-2 ${
            i18n.language === "en"
              ? "largeLaptop:h-[458px]"
              : "largeLaptop:h-[462px]"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default PopupCard;
