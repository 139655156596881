import { makeRequest, baseURL } from "src/core/data-access/http.service";
import { HttpMethods } from "src/core/enums/httpMethods.enum";

export const disruptCompanyApplication = async (
  data: object,
  reCaptchaToken: string,
) => {
  return await makeRequest({
    url: `${baseURL}/disrupt/company/application`,
    method: HttpMethods.POST,
    data,
    headers: { "recaptcha-token": reCaptchaToken },
  });
};

export const disruptStatus = async () => {
  return await makeRequest({
    url: `${baseURL}/disrupt/status`,
    method: HttpMethods.GET,
  });
};
