import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { IoMdClose } from "react-icons/io";
import { appRoutesObj } from "src/app.paths";
import { Layout } from "src/components/common/layout-component/layout-component";
import PopupCard from "src/components/common/PopupCard";
import HiddenDisruptTimeComponent from "src/components/user/hidden-disrupt-time-components";
import HiddenDisruptFormContainer from "src/containers/user/disrupt-container/hidden-disrupt-form-container";

function DisruptHiddenFormPage() {

  return (
    <Layout>
        <PopupCard
           link={`${appRoutesObj.getBasePath()}`}
          icon= {<IoMdClose size={32} className="text-white" />}
        >
         <> <HiddenDisruptTimeComponent/>
         <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA as string}
          >
            <HiddenDisruptFormContainer />
          </GoogleReCaptchaProvider>
    </>
        </PopupCard>
    </Layout>
  );
}

export default DisruptHiddenFormPage;
