import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import TitleComponent from "src/components/common/title-component";
import useModeStore from "src/core/helpers/useModeStore";
import { ImagesPartners } from "src/core/statics/partner.static";

function AboutTheGarageContainer() {
  const { t, i18n } = useTranslation();
  const { mode } = useModeStore();

  return (
    <div className="flex flex-col gap-y-12 mobile:gap-y-10 laptop:w-fit px-4 mobile:px-0 mobile:h-full mobile:pb-8">
      <div className="flex flex-col">
        <TitleComponent
          title="app.shared.theGarage"
          color="text-whiteColor"
          size={`${i18n.language === "ar" ? "text-2xl" : "text-sm"}`}
        />
        <p
          className={`flex flex-col text-naturalGray400 ${
            i18n.language === "ar"
              ? "text-base font-arMyriad"
              : "text-sm font-helveticaMedium"
          }`}
        >
          <span>{t(`container.aboutTheGarageContainer.theGarage.title`)}</span>
          <span>
            {t(`container.aboutTheGarageContainer.theGarage.subtitle`)}
          </span>
        </p>
      </div>
      <div className="flex flex-col">
        <TitleComponent
          title="app.shared.theGaragePartners.businessModel"
          color="text-whiteColor"
          size={`${i18n.language === "ar" ? "text-2xl" : "text-sm"}`}
        />
        <p
          className={`flex flex-col text-naturalGray400 ${
            i18n.language === "ar"
              ? "text-base font-arMyriad"
              : "text-sm font-helveticaMedium"
          }`}
        >
          <span>
            {t("container.aboutTheGarageContainer.businessModel.title")}
          </span>
        </p>
      </div>
      <div className="flex flex-col">
        <TitleComponent
          title="app.shared.theGaragePartners.partners"
          size={`${i18n.language === "ar" ? "text-2xl" : "text-sm"}`}
        />
        <div className="flex pt-2 gap-x-4 mobile:grid mobile:grid-cols-1 mobile:gap-4">
          {ImagesPartners?.map((item, index) => (
            <Link key={index} to={item.link}>
              <div className="mx-auto rounded-2xl p-4 border w-[151px] h-[123px]  border-gray-600/50 ">
                <img
                  className="object-contain object-center w-full h-24 p-2 rounded-xl"
                  src={mode === "dark" ? item.imgSrc : item.imagSrcLight}
                  alt={`partner ${item.name}`}
                />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AboutTheGarageContainer;
