import { baseURL, makeRequest } from "src/core/data-access/http.service";
import { HttpMethods } from "src/core/enums/httpMethods.enum";

export const plusFormApplication = async (
  data: object,
  reCaptchaToken: string,
) => {
  return await makeRequest({
    url: `${baseURL}/plus/application`,
    method: HttpMethods.POST,
    data,
    headers: { "recaptcha-token": reCaptchaToken },
  });
};

export const plusStatus = async () => {
  return await makeRequest({
    url: `${baseURL}/plus/status`,
    method: HttpMethods.GET,
  });
};

export const getPlusCompanies = async (id:number) => {
  return await makeRequest({
    url: `${baseURL}/plus/copany/${id}`,
    method:HttpMethods.GET
  })
}
