import { useTranslation } from "react-i18next";

export interface titleComponent {
  title?: string;
  icon?: JSX.Element | undefined;
  h?: string;
  w?: string;
}

function CardIncbatorComponent({ title, icon }: titleComponent) {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex items-center justify-center h-[50px] w-full px-4  bg-black/20 rounded-2xl">
      <div className="flex items-center justify-center w-full gap-2">
        <div className="text-secondaryGreen">{icon}</div>
        <p
          className={`flex w-full items-start justify-start text-white ${
            i18n.language === "en"
              ? "font-helveticaMedium text-[11px]"
              : "font-arMyriad text-base largeLaptop:text-sm laptop:text-sm"
          }`}
        >
          {t(`${title}`)}
        </p>
      </div>
    </div>
  );
}

export default CardIncbatorComponent;
