import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { appRoutesObj } from "src/app.paths";
import { Layout } from "src/components/common/layout-component/layout-component";
import PopupCard from "src/components/common/PopupCard";
import IssueContainer from "src/containers/user/Issues-Container/issue-container";
import { opengraphMetaTags } from "src/core/helpers/example.helper";
export interface Type {
  type: string;
}
function IssuePage() {
  const { i18n ,t} = useTranslation();
  
  return (
    <Layout>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA as string}
      >
        <Helmet
            title={`${t("app.shared.theGarage")} | ${t(
              "containers.IssuesContainers.problem"
            )}`}
            meta={[
              ...opengraphMetaTags({
                title: `${t("app.shared.theGarage")} | ${t(
                  "containers.IssuesContainers.problem"
                )}`,
                description: `${t(
                  "component.user.SidebarComponent.theGarageDescreption"
                )}`,
                logo: "/assets/images/logo.png",
              }),
              {
                name: "description",
                content: `${t(
                  "component.user.SidebarComponent.theGarageDescreption"
                )}`,
              },
            ]} />
        <PopupCard
          link={appRoutesObj.getBasePath()}
          icon={
            i18n.language === "en" ? (
              <AiOutlineArrowRight size={32} className="text-white" />
            ) : (
              <AiOutlineArrowLeft size={32} className="text-white" />
            )
          }
        >
          <IssueContainer type={"PLUS"} />
        </PopupCard>
      </GoogleReCaptchaProvider>
    </Layout>
  );
}

export default IssuePage;
