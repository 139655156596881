import { InitialValuesType } from "src/core/types/incubator.type";

export const initialValues: InitialValuesType = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  gender: "",
  nationality: "",
  idType: "",
  idNumber: "",
  startupName: "",
  yearOfEstablishment: "",
  country: "",
  startupSize: "",
  startupMajor: "",
  website: "",
  aboutStartup: "",
  startupProfile: "",
  hearAboutGarage: "",
  reasonToJoin: "",
  status: "new",
  businessModel: "",
  numberOfCustomers: undefined,
  howManyFullTimeEmployees: undefined,
  stageYourProduct: "",
  startupCurrentValuation: undefined,
  lastFundraisingRound: "",
  startup_location: "",
};
