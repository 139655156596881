import React from "react";

interface scrollProps {
  children: React.ReactNode;
}
export default function ScrollView({ children }: scrollProps) {
  return (
    <div className="h-full scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-neutral-700 scrollbar-track-naturalGray500/10 mobile:scrollbar-none">
      <div className="scrollbar-thin  h-auto overflow-y-scroll">
        <div className="w-full h-[328px] tablet:h-full mobile:h-full">
          {children}
        </div>
      </div>
    </div>

  );
}
