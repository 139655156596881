import { useState, useCallback, useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { appRoutesObj } from "src/app.paths";
import { Layout } from "src/components/common/layout-component/layout-component";
import Loading from "src/components/common/Loading/loading";
import PopupCard from "src/components/common/PopupCard";
import RegistrationCloseComponents from "src/components/user/registration-close-component";
import DistruptForm from "src/containers/user/disrupt-container/distrupt-form";
import { handleRequestError } from "src/core/helpers/error.helper";
import { opengraphMetaTags } from "src/core/helpers/example.helper";
import { disruptStatus } from "src/services/disrupt-service";

function DistruptFormPage() {
  const { i18n ,t} = useTranslation();
  const [isOpen, setIsOpen] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const DisruptStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await disruptStatus();
      setIsOpen(result.is_open);
      setIsLoading(false);
    } catch (error) {
      handleRequestError(error);
    }
  }, []);

  useEffect(() => {
    DisruptStatus();
  }, [DisruptStatus]);

  return (
    <Layout>
      {isLoading ? (
        <Loading />
      ) : isOpen ? (
        <><Helmet
            title={`${t("app.shared.theGarage")} | ${t(
              "container.disruptContainer.ditruptOverView"
            )}`}
            meta={[
              ...opengraphMetaTags({
                title: `${t("app.shared.theGarage")} | ${t(
                  "container.disruptContainer.ditruptOverView"
                )}`,
                description: `${t(
                  "component.user.SidebarComponent.theGarageDescreption"
                )}`,
                logo: "/assets/images/logo.png",
              }),
              {
                name: "description",
                content: `${t(
                  "component.user.SidebarComponent.theGarageDescreption"
                )}`,
              },
            ]} /><PopupCard
              link={appRoutesObj.getDisruptPagePath()}
              icon={i18n.language === "en" ? (
                <AiOutlineArrowRight size={32} className="text-white" />
              ) : (
                <AiOutlineArrowLeft size={32} className="text-white" />
              )}
            >
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA as string}
              >
                <DistruptForm />
              </GoogleReCaptchaProvider>
            </PopupCard></>
      ) : (
        <RegistrationCloseComponents link={appRoutesObj.getDisruptPagePath()} />
      )}
    </Layout>
  );
}

export default DistruptFormPage;
