import * as Yup from "yup";

export const validationSchema = (imageUploadedCopy: HTMLInputElement) =>
  Yup.object({
    email: Yup.string()
      .trim()
      .required("form.validation.email.required")
      .max(50, "form.validation.email.max.50")
      .email("form.validation.email.valid.format"),

    browser: Yup.string().required("form.validate.browser.required"),
    // otherBrowser: Yup.string(),
    operating_system: Yup.string().required(
      "form.validate.operating.system.required"
    ),
    // otherOperatingSystem: Yup.string(),
    details: Yup.string()
      .max(350, "form.validate.problem.description.max.350")
      .required("form.validate.problem.description.required"),
    image_url: Yup.mixed()
      .required("form.validation.image.file.required")
      .test(
        "fileSize",
        "form.validation.image.file.size",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (value) => value && imageUploadedCopy.files![0].size < 2000000
      ),
  });
