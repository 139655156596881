import { BookVenueType } from "src/core/types/user.type";

export const initialValues: BookVenueType = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  company: "",
  position: "",
  event_name: "",
  event_type: "",
  guest_count: undefined,
  other: "",
  start_at: "",
  end_at: "",
};
