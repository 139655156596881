import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ErrorDetailType, ErrorType } from "src/core/types/error.type";

/**
 *
 * @param error
 * @description handles all requests catch errors
 */
export const handleRequestError = (error: unknown) => {
  toast.error(getErrorMessage((error as ErrorType)?.response?.data?.detail));
};

/**
 * @param error
 * @description
 * @returns error string
 */
export const getErrorMessage = (error: string | ErrorDetailType) => {
  const { t } = useTranslation();
  if (!error) {
    return t("form.messages.error");
  }

  if (typeof error === "string") {
    return error;
  }

  if ((error as ErrorDetailType)?.error_code) {
    return (error as ErrorDetailType)?.error_message;
  }

  return t("form.messages.error");
};

export const getErrorMessage2 = (
  error: string | ErrorDetailType,
  t: (key: string) => string,
) => {
  if (!error) {
    return t("form.messages.error");
  }

  if (typeof error === "string") {
    return error;
  }

  if ((error as ErrorDetailType)?.error_code) {
    const code: string = (error as ErrorDetailType)?.error_code;

    return t(`${code}`);
  }

  return t("form.messages.error");
};
