import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { appRoutesObj } from "src/app.paths";
import SharedSectionComponent from "src/components/shard-Section";
import useModeStore from "src/core/helpers/useModeStore";
import { InputList } from "src/core/statics/inputList";

import NewsSectionComponent from "./news-section-component";

function SidebarComponent() {
  const { t, i18n } = useTranslation();
  const { mode } = useModeStore();
  const isDarkMode = mode === "dark";

  return (
    <div
      className={`mobile:py-0 flex tablet:items-center tablet:justify-center w-[350px] h-auto largeLaptop:w-[300px] laptop:w-[290px] mobile:w-full tablet:w-full`}
    >
      <div
        className={`px-4 mobile:px-0 tablet:px-0 py-5  flex flex-col gap-y-7 largeLaptop:gap-y-2.5 mobile:border-none border mobile:rounded-none tablet:border-none tablet:rounded-none rounded-2xl pb-3 ${
          isDarkMode ? "border-gray-600/50" : "border-naturalGray500"
        }  `}
      >
        <SharedSectionComponent
          component={
            <div className={``}>
              <p
                className={`text-sm text-naturalGray400 line-clamp-2 ${
                  i18n.language === "ar"
                    ? "font-arMyriad "
                    : "font-helveticaMedium "
                }`}
              >
                {t("component.user.SidebarComponent.theGarageDescreption")}
              </p>

              <Link to={appRoutesObj.getAboutThegaragePagePath()}>
                <p
                  className={`text-xs text-yellow underline laptop:text-[8px] largeLaptop:my-3 my-7 mx-2 ${
                    isDarkMode
                      ? " hover:text-yellowDarkPrimmery300"
                      : " hover:text-yellowLightPrimmery300"
                  } ${
                    i18n.language === "ar"
                      ? " text-left font-arMyriad "
                      : "text-right font-helvetica font-light text-[11px]"
                  }`}
                >
                  {t("component.user.SidebarComponent.theGarageExplore")}
                </p>
              </Link>
              <div className="grid grid-cols-2 mobile:gap-6 tablet:gap-4 desktop:gap-3 largeLaptop:gap-2 laptop:gap-2 font-HelveticaCEMedium">
                {InputList.map((val, index) => (
                  <div
                    key={index}
                    className={`rounded-2xl h-16 largeLaptop:h-14 border bg-naturalGray500 bg-opacity-10 mobile:py-3 mobile:h-[110%] ${
                      isDarkMode
                        ? "border-gray-600/50"
                        : "border-naturalGray500"
                    } `}
                  >
                    <div className="flex flex-col">
                      <div className="flex justify-center">
                        <p className="mt-1 text-lg text-center laptop:text-sm text-secondaryGreen ">
                          <CountUp
                            className="text-lg text-center text-secondaryGreen"
                            end={
                              typeof val.Number === "string"
                                ? parseFloat(val.Number) 
                                : val.Number
                            }
                            duration={2}
                            useGrouping={false}
                          />
                          {i18n.language ==="en" ? " "+ val.subtext+ " +":""+ val.subtext+ " +"} 
                        </p>
                      </div>
                      <p
                        className={`text-center text-whiteColor text-[11px] laptop:text-[9px] laptop:text-center mt-1 ${
                          i18n.language === "ar"
                            ? "font-arMyriad"
                            : "font-helveticaMedium"
                        }`}
                      >
                        {t(`${val.label}`)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          }
          title="app.shared.theGarage"
        />
        <div className="mobile:hidden tablet:hidden h-full">
          <NewsSectionComponent />
        </div>
      </div>
    </div>
  );
}
export default SidebarComponent;
