import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import CardComponent from "./card-component";

interface ICommunityCardComponentProps  {
  title: string;
  path: string;
}

export default function CommunityCardComponent({
  title,
  path,
}: ICommunityCardComponentProps) {
  const { t, i18n } = useTranslation();

  return (
    <Link to={path}>
      <CardComponent isHover={true}>
        <p
          className={`py-3 largeLaptop:py-0.5 laptop:py-3.5 largeLaptop:text-[13px] laptop:text-xs ${
            i18n.language === "ar" ? "font-arMyriad" : "font-helveticaMedium"
          }`}
        >
          {t(title)}
        </p>
      </CardComponent>
    </Link>
  );
}
