import {
  onlyLetters_Ar_En,
  onlyNumbers_En,
} from "src/components/common/ui/regex";
import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object({
    firstName: Yup.string()
      .trim()
      .matches(onlyLetters_Ar_En, "form.validation.first.name.no.numbers")
      .required("form.validation.first.name.required")
      .min(2, "form.validation.first.name.min.2")
      .max(20, "form.validation.first.name.max.20"),
    lastName: Yup.string()
      .trim()
      .matches(onlyLetters_Ar_En, "form.validation.last.name.no.numbers")
      .required("form.validation.last.name.required"),
    position: Yup.string().required("form.validation.position.required").max(255, "form.validation.position.max.255"),
    email: Yup.string()
      .trim()
      .required("form.validation.email.required")
      .max(50, "form.validation.email.max.50")
      .email("form.validation.email.valid.format"),
    phoneNo: Yup.string()
      .trim()
      .required("form.validation.mobile.required")
      .matches(onlyNumbers_En, "form.validation.mobile.no.numbers")
      .min(9, "form.validation.mobile.min.9")
      .max(15, "form.validation.mobile.max.15"),
    gender: Yup.string().trim().required("form.validation.gender.required"),
    nationality: Yup.string()
      .trim()
      .required("form.validation.nationlity.required")
      .max(255, "form.validation.nationlity.max.255"),

    companyName: Yup.string()
      .trim()
      .required("form.validation.company.name.required")
      .max(50, "form.validation.company.name.max.50"),
    occupation: Yup.string().required("form.validation.occupation.required"),
    reasonToJoin: Yup.string()
      .max(350, "form.validate.AttendTheGarage.max.350")
      .required("form.validation.reason.to.attend.required"),
  });
