import { useState, useCallback, useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { appRoutesObj } from "src/app.paths";
import { Layout } from "src/components/common/layout-component/layout-component";
import Loading from "src/components/common/Loading/loading";
import PopupCard from "src/components/common/PopupCard";
import RegistrationCloseComponents from "src/components/user/registration-close-component";
import PlusForm from "src/containers/user/plus-container/plus-form-container";
import { handleRequestError } from "src/core/helpers/error.helper";
import { plusStatus } from "src/services/plus.service";

function PlusFormPage() {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const PlusStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await plusStatus();
      setIsOpen(result.is_open);
      setIsLoading(false);
    } catch (error) { handleRequestError(error) }
  }, []);

  useEffect(() => {
    PlusStatus();
  }, [PlusStatus]);

  return (
    <Layout>

      {isLoading ? <Loading /> : isOpen ? <PopupCard
        link={appRoutesObj.getInternalProgramsPagePath()}
        icon={
          i18n.language === "en" ? (
            <AiOutlineArrowRight size={32} className="text-white" />
          ) : (
            <AiOutlineArrowLeft size={32} className="text-white" />
          )

        }
      >
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA as string}
        >
          <PlusForm />
        </GoogleReCaptchaProvider>
      </PopupCard> : <RegistrationCloseComponents link={appRoutesObj.getInternalProgramsPagePath()} />
      }

    </Layout>
  );
}

export default PlusFormPage;
