export const getListDisruptOneMock = [
  {
    id: "6bac5411-a9bd-4426-a677-b2b9b15f76be",
    image:
      "https://assets.thegarage.sa/disrupts/6bac5411-a9bd-4426-a677-b2b9b15f76be.svg",
    disrupt_version: 1,
  },
  {
    id: "01c15108-a65d-4edf-b25f-458c555cd337",
    image:
      "https://assets.thegarage.sa/disrupts/01c15108-a65d-4edf-b25f-458c555cd337.png",
    disrupt_version: 1,
  },
  {
    id: "7c333c28-e484-4b2e-aed8-29e9aa95bd97",
    image:
      "https://assets.thegarage.sa/disrupts/7c333c28-e484-4b2e-aed8-29e9aa95bd97.png",
    disrupt_version: 1,
  },
  {
    id: "f87f6836-788d-411c-8eb3-7e76f105608d",
    image:
      "https://assets.thegarage.sa/disrupts/f87f6836-788d-411c-8eb3-7e76f105608d.png",
    disrupt_version: 1,
  },
  {
    id: "56091dda-65f4-4119-888a-32c4d8b0f4db",
    image:
      "https://assets.thegarage.sa/disrupts/56091dda-65f4-4119-888a-32c4d8b0f4db.jpg",
    disrupt_version: 1,
  },
  {
    id: "f6ef270b-a5ee-40d2-a88e-7c40e2f2a73b",
    image:
      "https://assets.thegarage.sa/disrupts/f6ef270b-a5ee-40d2-a88e-7c40e2f2a73b.png",
    disrupt_version: 1,
  },
  {
    id: "5f7fd22d-1959-4c5f-bc31-78aa282cef52",
    image:
      "https://assets.thegarage.sa/disrupts/5f7fd22d-1959-4c5f-bc31-78aa282cef52.png",
    disrupt_version: 1,
  },
  {
    id: "41e6112f-d1a4-4fd0-bcbc-5deb5ae8bead",
    image:
      "https://assets.thegarage.sa/disrupts/41e6112f-d1a4-4fd0-bcbc-5deb5ae8bead.svg",
    disrupt_version: 1,
  },
  {
    id: "d18ee48a-935d-4397-92a7-989c423d86e6",
    image:
      "https://assets.thegarage.sa/disrupts/d18ee48a-935d-4397-92a7-989c423d86e6.png",
    disrupt_version: 1,
  },
  {
    id: "e9eb2122-ed72-4ba0-ad69-920f36748a1f",
    image:
      "https://assets.thegarage.sa/disrupts/e9eb2122-ed72-4ba0-ad69-920f36748a1f.png",
    disrupt_version: 1,
  },
  {
    id: "6f3c378e-cb31-47e2-b70d-97a03279a79a",
    image:
      "https://assets.thegarage.sa/disrupts/6f3c378e-cb31-47e2-b70d-97a03279a79a.png",
    disrupt_version: 1,
  },
  {
    id: "90a6abb3-9ff7-4263-a883-c25144c18cea",
    image:
      "https://assets.thegarage.sa/disrupts/90a6abb3-9ff7-4263-a883-c25144c18cea.svg",
    disrupt_version: 1,
  },
];

export const getListDisruptTwoMock = [
  {
    id: "7055b579-67de-4050-b691-398437ccf7a9",
    image:
      "https://assets.thegarage.sa/disrupts/7055b579-67de-4050-b691-398437ccf7a9.png",
    disrupt_version: 2,
  },
  {
    id: "2d7a5cb5-26a4-4ba7-9bcc-fdc6d5a8353f",
    image:
      "https://assets.thegarage.sa/disrupts/2d7a5cb5-26a4-4ba7-9bcc-fdc6d5a8353f.png",
    disrupt_version: 2,
  },
  {
    id: "e482b4a2-1b71-4c26-b364-3ae3b4218f36",
    image:
      "https://assets.thegarage.sa/disrupts/e482b4a2-1b71-4c26-b364-3ae3b4218f36.png",
    disrupt_version: 2,
  },
  {
    id: "36e475a4-225d-4a92-bf07-4a592427cf55",
    image:
      "https://assets.thegarage.sa/disrupts/36e475a4-225d-4a92-bf07-4a592427cf55.png",
    disrupt_version: 2,
  },
  {
    id: "22123998-2519-4dab-acf7-f7dc0cb7cdd8",
    image:
      "https://assets.thegarage.sa/disrupts/22123998-2519-4dab-acf7-f7dc0cb7cdd8.png",
    disrupt_version: 2,
  },
  {
    id: "33961c1b-303e-4bf0-a782-c3323646bcd7",
    image:
      "https://assets.thegarage.sa/disrupts/33961c1b-303e-4bf0-a782-c3323646bcd7.png",
    disrupt_version: 2,
  },
  {
    id: "b662a8d2-198f-49f2-9702-a5a1bd0fdefd",
    image:
      "https://assets.thegarage.sa/disrupts/b662a8d2-198f-49f2-9702-a5a1bd0fdefd.png",
    disrupt_version: 2,
  },
  {
    id: "766de850-43ba-4739-a513-024f9ef384b6",
    image:
      "https://assets.thegarage.sa/disrupts/766de850-43ba-4739-a513-024f9ef384b6.png",
    disrupt_version: 2,
  },
  {
    id: "b64d7560-3474-40a2-90cd-9a0ad5de8012",
    image:
      "https://assets.thegarage.sa/disrupts/b64d7560-3474-40a2-90cd-9a0ad5de8012.png",
    disrupt_version: 2,
  },
  {
    id: "26a12eaf-e5b4-4d45-bf49-6120a9603d75",
    image:
      "https://assets.thegarage.sa/disrupts/26a12eaf-e5b4-4d45-bf49-6120a9603d75.png",
    disrupt_version: 2,
  },
  {
    id: "7f5c63ff-d711-4175-be02-0d7bd2e6fd39",
    image:
      "https://assets.thegarage.sa/disrupts/7f5c63ff-d711-4175-be02-0d7bd2e6fd39.png",
    disrupt_version: 2,
  },
];

export const getListDisruptThreeMock = [
  {
    id: "0c776e86-f19b-43c2-97c6-ce398b002720",
    image:
      "https://assets.thegarage.sa/disrupts/0c776e86-f19b-43c2-97c6-ce398b002720.svg",
    disrupt_version: 3,
  },
  {
    id: "ccf496a1-f62b-495b-8386-6a089bdc3685",
    image:
      "https://assets.thegarage.sa/disrupts/ccf496a1-f62b-495b-8386-6a089bdc3685.png",
    disrupt_version: 3,
  },
  {
    id: "7e58d062-d27c-439f-a986-0fb0b2daac8e",
    image:
      "https://assets.thegarage.sa/disrupts/7e58d062-d27c-439f-a986-0fb0b2daac8e.jpg",
    disrupt_version: 3,
  },
  {
    id: "c13e9104-1cf0-4e80-8a02-f2bf442fbd8a",
    image:
      "https://assets.thegarage.sa/disrupts/c13e9104-1cf0-4e80-8a02-f2bf442fbd8a.png",
    disrupt_version: 3,
  },
  {
    id: "80eb6051-5075-4771-b101-48ffe9a1af69",
    image:
      "https://assets.thegarage.sa/disrupts/80eb6051-5075-4771-b101-48ffe9a1af69.png",
    disrupt_version: 3,
  },
  {
    id: "eb72da2b-c4f2-4657-91c6-d727019f7e81",
    image:
      "https://assets.thegarage.sa/disrupts/eb72da2b-c4f2-4657-91c6-d727019f7e81.png",
    disrupt_version: 3,
  },
  {
    id: "486a8638-c02d-421c-920c-f1a4aeb32942",
    image:
      "https://assets.thegarage.sa/disrupts/486a8638-c02d-421c-920c-f1a4aeb32942.png",
    disrupt_version: 3,
  },
  {
    id: "2c01f52a-086a-4495-83cf-92afef058c05",
    image:
      "https://assets.thegarage.sa/disrupts/2c01f52a-086a-4495-83cf-92afef058c05.png",
    disrupt_version: 3,
  },
  {
    id: "a86238ab-bb26-4ceb-b1ba-34d8c3c22413",
    image:
      "https://assets.thegarage.sa/disrupts/a86238ab-bb26-4ceb-b1ba-34d8c3c22413.png",
    disrupt_version: 3,
  },
  {
    id: "671710d5-5c32-4710-9991-ea98232e7dc3",
    image:
      "https://assets.thegarage.sa/disrupts/671710d5-5c32-4710-9991-ea98232e7dc3.png",
    disrupt_version: 3,
  },
  {
    id: "78a0bb42-763e-4c62-b7f8-bb1e5980daf6",
    image:
      "https://assets.thegarage.sa/disrupts/78a0bb42-763e-4c62-b7f8-bb1e5980daf6.png",
    disrupt_version: 3,
  },
  {
    id: "a5976023-eff7-4184-9981-4be24d0d1d08",
    image:
      "https://assets.thegarage.sa/disrupts/a5976023-eff7-4184-9981-4be24d0d1d08.png",
    disrupt_version: 3,
  },
];

export const getListDisruptFourMock = [
  {
    id: "c5a0d175-3395-4f7d-ac9f-8c4cbd016d43",
    image:
      "https://assets.thegarage.sa/disrupts/c5a0d175-3395-4f7d-ac9f-8c4cbd016d43.png",
    disrupt_version: 4,
  },
  {
    id: "b3a2c62c-6e4b-4202-9eb6-4c1859ed5685",
    image:
      "https://assets.thegarage.sa/disrupts/b3a2c62c-6e4b-4202-9eb6-4c1859ed5685.png",
    disrupt_version: 4,
  },
  {
    id: "ede685d1-ee7f-41eb-af43-bb0bb9150452",
    image:
      "https://assets.thegarage.sa/disrupts/ede685d1-ee7f-41eb-af43-bb0bb9150452.png",
    disrupt_version: 4,
  },
  {
    id: "584c56ed-c08e-4f17-aae9-92e8092bcf3f",
    image:
      "https://assets.thegarage.sa/disrupts/584c56ed-c08e-4f17-aae9-92e8092bcf3f.jpg",
    disrupt_version: 4,
  },
  {
    id: "4735d6a4-0d0d-41dc-9f7e-cb6dd52976c8",
    image:
      "https://assets.thegarage.sa/disrupts/4735d6a4-0d0d-41dc-9f7e-cb6dd52976c8.jpg",
    disrupt_version: 4,
  },
  {
    id: "70549f93-1c2b-4cbc-809c-6bd654303cab",
    image:
      "https://assets.thegarage.sa/disrupts/70549f93-1c2b-4cbc-809c-6bd654303cab.png",
    disrupt_version: 4,
  },
  {
    id: "2bd9dd0b-f2a7-4bd4-bb53-6755420aa505",
    image:
      "https://assets.thegarage.sa/disrupts/2bd9dd0b-f2a7-4bd4-bb53-6755420aa505.png",
    disrupt_version: 4,
  },
  {
    id: "953baf9a-f6aa-4420-a3f8-9f56895eb3f1",
    image:
      "https://assets.thegarage.sa/disrupts/953baf9a-f6aa-4420-a3f8-9f56895eb3f1.png",
    disrupt_version: 4,
  },
  {
    id: "11517004-6d30-4da6-a8f0-f1bb24a5ade2",
    image:
      "https://assets.thegarage.sa/disrupts/11517004-6d30-4da6-a8f0-f1bb24a5ade2.jpg",
    disrupt_version: 4,
  },
  {
    id: "4c81e5b2-3ab3-44c7-8026-2b7d481b5e04",
    image:
      "https://assets.thegarage.sa/disrupts/4c81e5b2-3ab3-44c7-8026-2b7d481b5e04.png",
    disrupt_version: 4,
  },
  {
    id: "564cda3a-38f5-479b-857e-40494cfcf2a7",
    image:
      "https://assets.thegarage.sa/disrupts/564cda3a-38f5-479b-857e-40494cfcf2a7.png",
    disrupt_version: 4,
  },
];

export const getListDisruptFiveMock = [
  {
    id: "30cd6856-08c1-4068-84dd-582c1c81a162",
    image:
      "https://assets.thegarage.sa/disrupts/30cd6856-08c1-4068-84dd-582c1c81a162.png",
    disrupt_version: 5,
  },
  {
    id: "8900fbce-9ca9-44f1-b8a4-afad7a185a13",
    image:
      "https://assets.thegarage.sa/disrupts/8900fbce-9ca9-44f1-b8a4-afad7a185a13.jpg",
    disrupt_version: 5,
  },
  {
    id: "92783325-1963-4871-b664-d22ab8026783",
    image:
      "https://assets.thegarage.sa/disrupts/92783325-1963-4871-b664-d22ab8026783.png",
    disrupt_version: 5,
  },
  {
    id: "4a047866-1808-4673-a37d-a254ebf8f7ab",
    image:
      "https://assets.thegarage.sa/disrupts/4a047866-1808-4673-a37d-a254ebf8f7ab.png",
    disrupt_version: 5,
  },
  {
    id: "5ec3c34e-313b-493d-a43d-90d7653c9220",
    image:
      "https://assets.thegarage.sa/disrupts/5ec3c34e-313b-493d-a43d-90d7653c9220.png",
    disrupt_version: 5,
  },
  {
    id: "62e82ee8-afb1-4be9-9afb-bfe816a9bcd4",
    image:
      "https://assets.thegarage.sa/disrupts/62e82ee8-afb1-4be9-9afb-bfe816a9bcd4.png",
    disrupt_version: 5,
  },
  {
    id: "f1d45cd8-1a55-44fd-a648-82ee26f49789",
    image:
      "https://assets.thegarage.sa/disrupts/f1d45cd8-1a55-44fd-a648-82ee26f49789.png",
    disrupt_version: 5,
  },
  {
    id: "fc0ff293-7e8b-41fd-9a53-b640abc399fe",
    image:
      "https://assets.thegarage.sa/disrupts/fc0ff293-7e8b-41fd-9a53-b640abc399fe.png",
    disrupt_version: 5,
  },
  {
    id: "a6943e1b-5bf6-43d7-97a8-5ed1a941f5dd",
    image:
      "https://assets.thegarage.sa/disrupts/a6943e1b-5bf6-43d7-97a8-5ed1a941f5dd.png",
    disrupt_version: 5,
  },
  {
    id: "68f687f5-2cbe-4a90-8723-f5782b09fb40",
    image:
      "https://assets.thegarage.sa/disrupts/68f687f5-2cbe-4a90-8723-f5782b09fb40.png",
    disrupt_version: 5,
  },
  {
    id: "5eee4c18-955a-4f57-b524-645bda056dbc",
    image:
      "https://assets.thegarage.sa/disrupts/5eee4c18-955a-4f57-b524-645bda056dbc.png",
    disrupt_version: 5,
  },
];

export const getListDisruptSixMock = [
  {
    id: "955de4f1-a0ba-47ad-9d29-3b5a763de34b",
    image:
      "https://assets.thegarage.sa/disrupts/955de4f1-a0ba-47ad-9d29-3b5a763de34b.svg",
    disrupt_version: 6,
  },
  {
    id: "5ad99aa6-c573-480b-8377-c9515018918c",
    image:
      "https://assets.thegarage.sa/disrupts/5ad99aa6-c573-480b-8377-c9515018918c.png",
    disrupt_version: 6,
  },
  {
    id: "ee12162f-04cc-4f92-9e7d-44550214b548",
    image:
      "https://assets.thegarage.sa/disrupts/ee12162f-04cc-4f92-9e7d-44550214b548.png",
    disrupt_version: 6,
  },
  {
    id: "149fa3aa-cd5f-4fc1-b552-e563f2398218",
    image:
      "https://assets.thegarage.sa/disrupts/149fa3aa-cd5f-4fc1-b552-e563f2398218.png",
    disrupt_version: 6,
  },
  {
    id: "696d7efa-d7be-42bc-a86d-c699378b5468",
    image:
      "https://assets.thegarage.sa/disrupts/696d7efa-d7be-42bc-a86d-c699378b5468.svg",
    disrupt_version: 6,
  },
  {
    id: "e7ff17ff-296c-4c96-aa75-38e4cc8e504b",
    image:
      "https://assets.thegarage.sa/disrupts/e7ff17ff-296c-4c96-aa75-38e4cc8e504b.png",
    disrupt_version: 6,
  },
  {
    id: "66a799d4-a3e8-465b-b372-a52b8b577802",
    image:
      "https://assets.thegarage.sa/disrupts/66a799d4-a3e8-465b-b372-a52b8b577802.png",
    disrupt_version: 6,
  },
  {
    id: "945bac8c-ae6b-4383-beff-cb71915f2542",
    image:
      "https://assets.thegarage.sa/disrupts/945bac8c-ae6b-4383-beff-cb71915f2542.jpg",
    disrupt_version: 6,
  },
  {
    id: "a5e2fbcc-7eff-4dce-ac70-0cd7f980419a",
    image:
      "https://assets.thegarage.sa/disrupts/a5e2fbcc-7eff-4dce-ac70-0cd7f980419a.jpeg",
    disrupt_version: 6,
  },
  {
    id: "0103ecaa-f080-4b17-9e88-3072d8d31117",
    image:
      "https://assets.thegarage.sa/disrupts/0103ecaa-f080-4b17-9e88-3072d8d31117.png",
    disrupt_version: 6,
  },
  {
    id: "22a4f9f7-19bc-4fe9-bb34-75ee15c8cd3f",
    image:
      "https://assets.thegarage.sa/disrupts/22a4f9f7-19bc-4fe9-bb34-75ee15c8cd3f.png",
    disrupt_version: 6,
  },
];

export const getListDisruptSevenMock = [
  {
    id: "3e6f842f-9185-4d3f-aa25-8a21eb73c6fa",
    image:
      "https://assets.thegarage.sa/disrupts/3e6f842f-9185-4d3f-aa25-8a21eb73c6fa.png",
    disrupt_version: 7,
  },
  {
    id: "8fd9e278-9d00-4c11-9a27-3fff324f7900",
    image:
      "https://assets.thegarage.sa/disrupts/8fd9e278-9d00-4c11-9a27-3fff324f7900.jpg",
    disrupt_version: 7,
  },
  {
    id: "931e4118-1b9f-45f3-a97d-13b1a8beccdd",
    image:
      "https://assets.thegarage.sa/disrupts/931e4118-1b9f-45f3-a97d-13b1a8beccdd.jpg",
    disrupt_version: 7,
  },
  {
    id: "84a8e7d6-c08d-4429-9282-52f81c05c5d7",
    image:
      "https://assets.thegarage.sa/disrupts/84a8e7d6-c08d-4429-9282-52f81c05c5d7.png",
    disrupt_version: 7,
  },
  {
    id: "cb422f12-a4e6-40ce-8fb1-bff918e76774",
    image:
      "https://assets.thegarage.sa/disrupts/cb422f12-a4e6-40ce-8fb1-bff918e76774.gif",
    disrupt_version: 7,
  },
  {
    id: "19f070e4-74ed-4ba2-a792-8b60a732c757",
    image:
      "https://assets.thegarage.sa/disrupts/19f070e4-74ed-4ba2-a792-8b60a732c757.png",
    disrupt_version: 7,
  },
  {
    id: "405fbaa8-53da-4b47-bb48-c88f968695e1",
    image:
      "https://assets.thegarage.sa/disrupts/405fbaa8-53da-4b47-bb48-c88f968695e1.png",
    disrupt_version: 7,
  },
  {
    id: "817bae64-1888-49a2-acb8-d37e80a03852",
    image:
      "https://assets.thegarage.sa/disrupts/817bae64-1888-49a2-acb8-d37e80a03852.png",
    disrupt_version: 7,
  },
  {
    id: "2ef226e9-c7fb-4004-93d4-899fc724af6a",
    image:
      "https://assets.thegarage.sa/disrupts/2ef226e9-c7fb-4004-93d4-899fc724af6a.jpg",
    disrupt_version: 7,
  },
  {
    id: "edd01e84-f08c-415f-98c2-4e27ec4b9d69",
    image:
      "https://assets.thegarage.sa/disrupts/edd01e84-f08c-415f-98c2-4e27ec4b9d69.png",
    disrupt_version: 7,
  },
];

export const getListDisruptEightMock = [
  {
    id: "c43fae83-f90b-4450-9eae-9e43070d9e94",
    image:
      "https://assets.thegarage.sa/disrupts/c43fae83-f90b-4450-9eae-9e43070d9e94.jpg",
    disrupt_version: 8,
  },
  {
    id: "7f105fe5-9880-469d-82d9-54d72b419edf",
    image:
      "https://assets.thegarage.sa/disrupts/7f105fe5-9880-469d-82d9-54d72b419edf.png",
    disrupt_version: 8,
  },
  {
    id: "0da4f421-9cb9-41bc-90f3-9780cedb41da",
    image:
      "https://assets.thegarage.sa/disrupts/0da4f421-9cb9-41bc-90f3-9780cedb41da.png",
    disrupt_version: 8,
  },
  {
    id: "3b9e9143-97b5-4f9b-9f03-cb5892bab97d",
    image:
      "https://assets.thegarage.sa/disrupts/3b9e9143-97b5-4f9b-9f03-cb5892bab97d.png",
    disrupt_version: 8,
  },
  {
    id: "61efba5d-3d05-49c1-8c7a-a431103a3633",
    image:
      "https://assets.thegarage.sa/disrupts/61efba5d-3d05-49c1-8c7a-a431103a3633.png",
    disrupt_version: 8,
  },
  {
    id: "61c53e38-74a6-48c1-8ff9-30c23da2351a",
    image:
      "https://assets.thegarage.sa/disrupts/61c53e38-74a6-48c1-8ff9-30c23da2351a.jpg",
    disrupt_version: 8,
  },
];
