import { PlusFormDataType } from "src/core/types/plus.type";

export const plusInitialValues: PlusFormDataType = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  gender: "",
  nationality: "",
  otherNationality: "",
  idType: "",
  idNumber: "",
  startupName: "",
  yearOfEstablishment: "",
  country: "",
  otherCountry: "",
  startupMajor: "",
  website: "",
  twitter: null,
  instagram: null,
  aboutStartup: "",
  startupProfile: "",
  hearAboutGarage: "",
  reasonToJoin: "",
  otherHearAboutGarage: "",
  idValidation: null,
};
