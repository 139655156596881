import React from "react";
import { BiMinus, BiPlus } from "react-icons/bi";
import i18n from "src/core/configs/i18n";

interface ICommonQuestionsComponentsProps {
  question: string;
  answer: string | JSX.Element;
  click: boolean;
  handleClick: React.MouseEventHandler<HTMLDivElement>;
}
function CommonQuestionsComponents({
  question,
  answer,
  click,
  handleClick,
}: ICommonQuestionsComponentsProps) {
  return (
    <div className="flex justify-center items-center pb-4 py-1">
      <div className="flex flex-col bg-neutral-500/10 rounded-lg w-full h-auto py-5 px-8 items-start justify-center">
        <div className="flex items-center justify-between w-full">
          <h1
            className={`flex text-white gap-2 ${
              i18n.language === "en"
                ? "font-helveticaMedium text-[13px]"
                : "font-arMyriad text-[19px]"
            }`}
          >
            {question}
          </h1>

          <div onClick={handleClick}>
            {click ? (
              <BiMinus className="text-primary text-xl font-bold" />
            ) : (
              <BiPlus className="text-primary text-xl font-bold" />
            )}
          </div>
        </div>
        {click && (
          <div
            className={`text-neutral-400 pt-4 ${
              i18n.language === "en"
                ? "font-helveticaMedium text-[13px]"
                : "font-arMyriad text-base"
            } `}
          >
            {answer}
          </div>
        )}
      </div>
    </div>
  );
}

export default CommonQuestionsComponents;
