import { InitialValuesType } from "src/core/types/distrupt-company-apply";

export const initialValues: InitialValuesType = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  gender: "",
  nationality: "",
  startupName: "",
  yearOfEstablishment: "",
  startupMajor: "",
  website: "",
  aboutStartup: "",
  startupProfile: "",
  hearAboutGarage: "",
  reasonToJoin: "",
  otherNationality: "",
  position: "",
  startup_last_fundraising_round: "",
  twitter: "",
  instagram: "",
};
