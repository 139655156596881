import { baseURL, makeRequest } from "src/core/data-access/http.service";
import { HttpMethods } from "src/core/enums/httpMethods.enum";


export const disruptGuestApplication = async (
  data: object,
  reCaptchaToken: string,
) => {
  return await makeRequest({
    url: `${baseURL}/disrupt/guest/application`,
    method: HttpMethods.POST,
    data,
    headers: { "recaptcha-token": reCaptchaToken },
  });
};


export const disruptStatus = async () => {
  return await makeRequest({
    url: `${baseURL}/disrupt/status`,
    method: HttpMethods.GET,
  });
};
