interface CardPhotoComponent {
  isHover?: boolean;
  children?: JSX.Element | JSX.Element[];
  icon?: JSX.Element | JSX.Element[];
  chiled?: boolean;
}

function CardPhotoComponent({
  isHover = false,
  chiled = false,
  children,
  icon,
}: CardPhotoComponent) {
  const getHover = () => {
    switch (isHover) {
      case true:
        return "gradient-border8 rounded-2xl gradient-border8 ";
      default:
        return "rounded-2xl gradient-border8 ";
    }
  };
  const getchiled = () => {
    switch (chiled) {
      case true:
        return "gradient-border4 gradient-border8 bg-white bg-opacity-5 rounded-2xl";
      default:
        return "rounded-2xl gradient-border8";
    }
  };

  return (
    <div
      className={` rounded-2xl ${getHover()} ${getchiled()}`}
    >
      {children}
      <div className="flex items-center justify-center w-1 h-1 mx-auto">
        <div className="text-2xl">{icon}</div>
      </div>
    </div>
  );
}

export default CardPhotoComponent;
