import React from "react";
import { Layout } from "src/components/common/layout-component/layout-component";
import HostedContainer from "src/containers/user/hosted-container";

function HostedPage() {
  return (
    <Layout>
      <HostedContainer />
    </Layout>
  );
}

export default HostedPage;
