import { appRoutesObj } from "src/app.paths";

export const programsData = [
  {
    path: appRoutesObj.getInternalProgramsPagePath(),
    title: "app.shared.theGarage",
    description: "app.shared.internalPrograms.description",
    img:"assets/images/internal.png",
    imgAr:"assets/images/internal-AR.png"
  },
  {
    path: appRoutesObj.getHostedPagePath(),
    title: "app.shared.theGarage",
    description: "app.shared.hostedPrograms.description",
    img:"assets/images/hosted.png",
    imgAr:"assets/images/hosted-AR.png"
  },
];
