import React from "react";
import { useTranslation } from "react-i18next";
import { BiCalendar, BiTimeFive } from "react-icons/bi";

import CardBorderGreenComponent from "./card-border-green-component";

function DisruptTimeAndDateComponent() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center items-start gap-3">
      <CardBorderGreenComponent
        title={t("component.HiddenDisruptTimeComponent.date")}
        icon={BiCalendar}
      />
      <CardBorderGreenComponent
        title={t("component.HiddenDisruptTimeComponent.time")}
        icon={BiTimeFive}
      />
    </div>
  );
}

export default DisruptTimeAndDateComponent;
