import { makeRequest, baseURL } from "src/core/data-access/http.service";
import { HttpMethods } from "src/core/enums/httpMethods.enum";

export const postPermitService = async (
  data: object,
  reCaptchaToken: string
) => {
  return await makeRequest({
    url: `${baseURL}/visit/permit/application`,
    method: HttpMethods.POST,
    data,
    headers: { "recaptcha-token": reCaptchaToken },
  });
};

export const getScanPermitService = async (permit_id: string) => {
  return await makeRequest({
    url: `${baseURL}/visit/permit/${permit_id}/scan`,
    method: HttpMethods.GET,
  });
};
