export const getListPlusOneMock = [
  {
    id: "Logexa",
    description: "container.user.community.communityLogexa.description",
    imgSrc: "/assets/images/plus_cohort/Logexa.png",
    startup_name: "Logexa",
    email: "husam@logexa.com",
    websiteLink: " https://logexa.com/",
    videoId: "#LogexaVideo",
    hasVideo: true,
    videoLink:
      "https://user-images.githubusercontent.com/91417065/217559317-5d87b303-4de7-4ec9-90e7-b81c916e862b.mp4",
    phone: null,
  },
  {
    id: "Nitx",
    description: "container.user.community.communityNitx.description",
    imgSrc: "/assets/images/plus_cohort/Nitx.png",
    startup_name: "Nitx",
    email: "mohammed@nitx.io",
    websiteLink: " http://www.nitx.io/",
    videoId: "#NitxVideo",
    hasVideo: true,
    videoLink:
      "https://user-images.githubusercontent.com/91417065/217550831-7389204f-1a09-4b67-b0c2-32184aec3a7f.mp4",
    phone: null,
  },
  {
    id: "Moltazim",
    description: "container.user.community.communityMoltazim.description",
    imgSrc: "/assets/images/plus_cohort/Moltazim.png",
    startup_name: "Moltazim",
    email: "info@moltazim.com",
    websiteLink: " http://www.moltazim.com/",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
  {
    id: "Mkhzon",
    description: "container.user.community.communityMkhzon.description",
    imgSrc: "/assets/images/plus_cohort/MKhzon.png",
    startup_name: "Mkhzon",
    email: "thamer@mkhzon.sa",
    websiteLink: " https://mkhzon.sa/",
    videoId: "#MkhzonVideo",
    hasVideo: true,
    videoLink:
      "https://user-images.githubusercontent.com/91417065/217535663-e5ca87f3-f610-4ad1-bd21-28ac33ba590e.mp4",
    phone: null,
  },
  {
    id: "Mezwalah",
    description: "container.user.community.communityMezwalah.description",
    imgSrc: "/assets/images/plus_cohort/Mezwalah.png",
    startup_name: "Mezwalah",
    email: "info@mezwalah.com",
    websiteLink: " http://www.mezwalah.com/",
    videoId: "#MezwalahVideo",
    hasVideo: true,
    videoLink:
      "https://user-images.githubusercontent.com/91417065/217534132-d125fdee-beac-49ed-bf90-147d61301509.mp4",
    phone: null,
  },
  {
    id: "qvalon",
    description: "container.user.community.communityqvalon.description",
    imgSrc: "/assets/images/plus_cohort/Qvalon.png",
    startup_name: "qvalon",
    email: "andrew@qvalon.com",
    websiteLink: " http://www.qvalon.com/",
    videoId: "#QvalonVideo",
    hasVideo: true,
    videoLink:
      "https://user-images.githubusercontent.com/91417065/217517072-ead70498-1900-4aec-9814-8fd15d9430b2.mp4",
    phone: null,
  },
  {
    id: "Shttle",
    description: "container.user.community.communityShttle.description",
    imgSrc: "/assets/images/plus_cohort/Shttle.png",
    startup_name: "Shttle",
    email: "Malhassan@shuttle-sa.com",
    websiteLink: " http://www.shttle.com/",
    videoId: "#ShttleVideo",
    hasVideo: true,
    videoLink:
      "https://user-images.githubusercontent.com/91417065/217549160-3a3841c7-9e63-4978-95cb-9787b5aa7f8d.mp4",
    phone: null,
  },
  {
    id: "MoneyLoop",
    description: "container.user.community.communityMoneyLoop.description",
    imgSrc: "/assets/images/plus_cohort/MoneyLoop.png",
    startup_name: "Money Loop",
    email: "Saleh@moneyloop.sa",
    websiteLink: " http://www.moneyloop.sa/",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
  {
    id: "OptimalPV",
    description: "container.user.community.communityOptimalPV.description",
    imgSrc: "/assets/images/plus_cohort/OptimalPV.png",
    startup_name: "OptimalPV",
    email: "Habib@optimalpv.com",
    websiteLink: " http://www.optimalpv.com/",
    videoId: "#OptimalPVVideo",
    hasVideo: true,
    videoLink:
      "https://user-images.githubusercontent.com/91417065/217548261-859a623c-afb9-4e34-a589-d5aa62652fb3.mp4",
    phone: null,
  },
  {
    id: "Nitros",
    description: "container.user.community.communityNitro.description",
    imgSrc: "/assets/images/plus_cohort/nitros@3x.png",
    startup_name: "Nitros",
    email: "A.asmi@gonitros.com",
    websiteLink: " http://www.gonitros.com/",
    videoId: "#NitrosVideo",
    hasVideo: true,
    videoLink:
      "https://user-images.githubusercontent.com/91417065/217548062-53a5e1b8-dc85-4f8d-9354-c45406e6a98a.mp4",
    phone: null,
  },
  {
    id: "Wakkl",
    description: "container.user.community.communityWakkl.description",
    imgSrc: "/assets/images/plus_cohort/Wakkl.png",
    startup_name: "Wakkl",
    email: "naser@wakkl.com",
    websiteLink: " http://www.wakkl.com/",
    videoId: "#WakklVideo",
    hasVideo: true,
    videoLink:
      "https://user-images.githubusercontent.com/91417065/217549321-7eba68d5-0481-4974-8142-0d42342a4524.mp4",
    phone: null,
  },
  {
    id: "Mismar",
    description: "container.user.community.communityMismar.description",
    imgSrc: "/assets/images/plus_cohort/Mismar.png",
    startup_name: "Mismar",
    email: "info@mismarapp.com",
    websiteLink: " http://www.mismarapp.com/",
    videoId: "#MismarVideo",
    hasVideo: true,
    videoLink:
      "https://user-images.githubusercontent.com/91417065/217534791-a39efd08-8429-41a9-b7d3-b7d843957afd.mp4",
    phone: null,
  },
  {
    id: "Faceki",
    description: "container.user.community.communityFaceki.description",
    imgSrc: "/assets/images/plus_cohort/Faceki.png",
    startup_name: "Faceki",
    email: "hamza@faceki.com",
    websiteLink: " https://faceki.com/",
    videoId: "#FacekiVideo",
    hasVideo: true,
    videoLink:
      "https://user-images.githubusercontent.com/91417065/217532685-b5123d45-ebb9-4223-8caa-7f5e380666f0.mp4",
    phone: null,
  },
  {
    id: "Shiftat",
    description: "container.user.community.communityShiftat.description",
    imgSrc: "/assets/images/plus_cohort/Shiftat.png",
    startup_name: "Shiftat",
    email: "abdullah@shiftatsa.com",
    websiteLink: " http://www.shiftat.sa/",
    videoId: "#ShiftatVideo",
    hasVideo: true,
    videoLink:
      "https://user-images.githubusercontent.com/91417065/217549889-9b99ff80-2965-4abc-b1b1-b92b9a56202e.mp4",
    phone: null,
  },
];

export const getListPlusTwoMock = [
  {
    id: "Ivvest",
    description: "container.user.Plus.SecondCohort.Ivvest.description",
    imgSrc: "/assets/images/second_cohort/IVVEST.png",
    startup_name: "Ivvest",
    email: "azubaidi@ivvest.com",
    websiteLink: " http://www.ivvest.com/",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
  {
    id: "Hydrojeen",
    description: "container.user.Plus.SecondCohort.Hydrojeen.description",
    imgSrc: "/assets/images/second_cohort/Hydrojeen.png",
    startup_name: "Hydrojeen",
    email: "info@hydrojeen.com",
    websiteLink: " http://www.hydrojeen.com/",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
  {
    id: "askPepper",
    description: "container.user.Plus.SecondCohort.askPepper.description",
    imgSrc: "/assets/images/second_cohort/askPepper.png",
    startup_name: "askPepper",
    email: "samira.owaynat@ask-pepper.com",
    websiteLink: " http://www.ask-pepper.com/",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
  {
    id: "Printhub",
    description: "container.user.Plus.SecondCohort.Printhub.description",
    imgSrc: "/assets/images/second_cohort/printHub.png",
    startup_name: "Printhub",
    email: "ghali@printhub.sa",
    websiteLink: " http://printhub.sa/",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
  {
    id: "UP",
    description: "container.user.Plus.SecondCohort.4UPTitle.description",
    imgSrc: "/assets/images/second_cohort/4UP.png",
    startup_name: "4UP",
    email: "mai@4upapp.com",
    websiteLink: " https://www.4upapp.com/",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
  {
    id: "Eventful",
    description: "container.user.Plus.SecondCohort.Eventful.description",
    imgSrc: "/assets/images/second_cohort/eventful.png",
    startup_name: "Eventful | ايفينتفل",
    email: "aa@eventful.sa",
    websiteLink:
      " https://apps.apple.com/sa/app/eventful-your-event-planner/id1357975527",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
  {
    id: "DIGGIPACKS",
    description: "container.user.Plus.SecondCohort.DIGGIPACKS.description",
    imgSrc: "/assets/images/second_cohort/Diggipacks .png",
    startup_name: "DIGGIPACKS",
    email: "hasan@diggipacks.com",
    websiteLink: " http://www.diggipacks.com/",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
  {
    id: "Unipal",
    description: "container.user.Plus.SecondCohort.Unipal.description",
    imgSrc: "/assets/images/second_cohort/Unipal .png",
    startup_name: "Unipal",
    email: "a.alalawi@unipal.me",
    websiteLink: " https://unipal.me/",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
  {
    id: "Mbshr",
    description: "container.user.Plus.SecondCohort.Mbshr.description",
    imgSrc: "/assets/images/second_cohort/mbshr.png",
    startup_name: "Mbshr",
    email: "m.alnajjar@mbshr.com",
    websiteLink: " http://mbshr.com/",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
  {
    id: "Capifly",
    description: "container.user.Plus.SecondCohort.Capifly.description",
    imgSrc: "/assets/images/second_cohort/capifly.png",
    startup_name: "Capifly",
    email: "dunya@growthcapifly.com,hasan@growthcapifly.com",
    websiteLink: " https://www.growthcapifly.com/",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
  {
    id: "Exitval",
    description: "container.user.Plus.SecondCohort.Exitval.description",
    imgSrc: "/assets/images/second_cohort/exitval.jpeg",
    startup_name: "Exitval",
    email: "monim@exitval.com",
    websiteLink: " https://exitval.com/",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
  {
    id: "TeamUp",
    description: "container.user.Plus.SecondCohort.TeamUp.description",
    imgSrc:
      "/assets/images/second_cohort/TeamUp Official Logo - Ghazal Alruwaili.png",
    startup_name: "TeamUp",
    email: "the.alamer@gmail.com",
    websiteLink: " https://teamup.technology/",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
  {
    id: "threeD",
    description: "container.user.Plus.SecondCohort.threeD.description",
    imgSrc: "/assets/images/second_cohort/3D +.png",
    startup_name: "3D+",
    email: "Info@3Dplus.sa",
    websiteLink: " http://3dplus.sa/",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
  {
    id: "YouCan",
    description: "container.user.Plus.SecondCohort.YouCan.description",
    imgSrc: "/assets/images/second_cohort/logo youcan.png",
    startup_name: "YouCan",
    email: "akram@youcan.shop",
    websiteLink: " https://youcan.shop/en",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
  {
    id: "YallaRX",
    description: "container.user.Plus.SecondCohort.YallaRX.description",
    imgSrc: "/assets/images/second_cohort/yalla.png",
    startup_name: "YallaRX",
    email: "elena.vatutina@yallarx.com",
    websiteLink: " https://yallarx.com/",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
  {
    id: "AS3A",
    description: "container.user.Plus.SecondCohort.AS3A.description",
    imgSrc: "/assets/images/second_cohort/blue alternative.png",
    startup_name: "AS3A",
    email: "hisham@as3a.com",
    websiteLink: " https://as3a.com/",
    videoId: undefined,
    hasVideo: false,
    videoLink: undefined,
    phone: null,
  },
];

export const getListPlusThreeMock = [
  {
    id: "SuperCommerce",
    description:
      "SuperCommerce is a low-code enterprise ecommerce platform for software houses and developers to accelerate the development process. With it, they can build for their clients advanced, truly unique and personalized shopping experiences that increase online sales and reduce operational costs.",
    imgSrc: "/assets/images/third_cohort/SuperCommerce.png",
    startup_name: "SuperCommerce",
    email: "m@supercommerce.io",
    websiteLink: "http://supercommerce.io/",
  },
  {
    id: "Glamera",
    description:
      "Glamera is an All-in-One Platform (SaaS) to Power and Supply lifestyle Services Providers",
    imgSrc: "/assets/images/third_cohort/Glamera.jpg",
    startup_name: "Glamera",
    email: "m.hassan@glamera.com",
    websiteLink: "https://business.glamera.com/",
  },
  {
    id: "AortoMedical",
    description:
      "In heart failure with preserved ejection fraction, several technological solutions have emerged that successfully achieve long-term cardiac-circulatory support. However, their cross-sectional impact has been hindered due to inherent limitations including restricted availability of donor hearts, limited surgical implantation expertise, high procedural mortality, non-streamlined manufacturing, risk of driveline sepsis/infection, high device and procedural costs as well as need for high subsequent device maintenance and thus patient compliance & normal cognitive function. Through passive blood redistribution, Aorto Medical's implant solution prioritizes blood flow to the most critical organs that play a central role in the pathophysiology of congestive heart failure. By delivering a Flow Modulation Stent (FMS), a tapered aortic stent graft device, blood passage to the lower extremities (legs) is partially limited at the level of the infrarenal aorta in order to divert blood volume more proximally towards the renal artery branches and ultimately to the kidneys. Because many CHF symptoms arise from low blood flow to the kidneys, an important underlying mechanism of heart failure, the cardiorenal syndrome can be addressed via deployment of the FMS.",
    imgSrc: "/assets/images/third_cohort/AortoMedical.JPG",
    startup_name: "Aorto Medical",
    email: "youssef@aortomedical.com",
    websiteLink: "https://www.aortomedical.com/",
  },
  {
    id: "DarDoc",
    description: "Delivering healthcare services to the doorstep of patients",
    imgSrc: "/assets/images/third_cohort/DarDoc.png",
    startup_name: "DarDoc",
    email: "keswin@dardoc.com",
    websiteLink: "https://www.dardoc.com/",
  },
  {
    id: "Infoseed",
    description:
      "Help Enterprise and SMEs regain control over customer experiences using automated chatbot technologies through WhatsApp.",
    imgSrc: "/assets/images/third_cohort/Infoseed.jpg",
    startup_name: "Infoseed",
    email: "Yousef.awadallah@info-seed.com",
    websiteLink: "https://www.info-seed.com/",
  },
  {
    id: "osarah",
    description:
      "OSARH is an online platform providing a professional LMS that can help you build an internal or external academy for your client and employees",
    imgSrc: "/assets/images/third_cohort/osarah.png",
    startup_name: "osarah",
    email: "Adel@osarh.com",
    websiteLink: "https://www.osarh.pro/",
  },
  {
    id: "Markabte",
    description:
      "An automotive inspection management and automation platform. We develop proprietary inspection products to enable cars to be inspected on-site without the need for a technician's knowledge",
    imgSrc: "/assets/images/third_cohort/Markabte.png",
    startup_name: "Markabte",
    email: "hamzeh@markabte.com",
    websiteLink: "https://markabte.com/",
  },
  {
    id: "Golato",
    description:
      "Golato, a leading sports tech company, is revolutionizing the way athletes and sports enthusiasts engage with sports facilities and academies. Founded in 2017, our innovative platform seamlessly connects sport players with a wide array of sports facilities and academies, streamlining the booking process and enhancing the overall sports experience. Our comprehensive B2B platform empowers sports facilities and academies to efficiently manage day-to-day operations, including booking management, loyalty programs, billing, payment, and scheduling. Additionally, our B2C app marketplace enables direct bookings, subscriptions, and online payments, while also offering exciting features like game creation. With a core focus on the Middle East's thriving sports sector, Golato is deeply committed to supporting Saudi Vision 2030 and the Quality of Life Program. Our mission is to make sports an integral part of people's lifestyles, encouraging regular participation and enhancing overall well-being.",
    imgSrc: "/assets/images/third_cohort/Golato.png",
    startup_name: "Golato",
    email: "hammad@golatoapp.com",
    websiteLink: "https://golatoapp.com/",
  },
  {
    id: "Nabeeh",
    description:
      "In Nabeeh, we help people get excellence home services with a simple mobile app in a convenient and reliable way. Nabeeh is a mobile marketplace that connects clients with 'Providers' who can perform various home services on-demand. Nabeeh offers a platform for clients to find and hire local, reliable, and skilled services providers for diverse services, such as home repairs, cleaning, and moving, while providing these services providers the opportunity to become providers and earn money flexibly.",
    imgSrc: "/assets/images/third_cohort/Nabeeh.jpg",
    startup_name: "Nabeeh",
    email: "Fawaz.samara@nabeeh.net",
    websiteLink: "https://www.nabeehapp.com/",
  },
  {
    id: "ISNAADLogisticsSolutions",
    description:
      "ISNAAD provides advanced technical solutions for e-commerce starting from inventory management, order receiving, delivery and any related consultancy to ensure the quality of operations and to save your time and effort for your growth.",
    imgSrc: "/assets/images/third_cohort/ISNAAD.png",
    startup_name: "ISNAAD Logistics Solutions",
    email: "aalomar@isnaad.sa",
    websiteLink: "https://isnaad.sa/",
  },
  {
    id: "munjiz",
    description:
      "munjiz is a Saudi Talent Match-Making platform that connects Job seekers and businesses through an integrated end-to-end solutions to enhance hiring success rate in an efficient way.",
    imgSrc: "/assets/images/third_cohort/Munjiz.png",
    startup_name: "munjiz | منجز",
    email: "a.alqahtani@munjiz.sa",
    websiteLink: "https://www.munjiz.sa/",
  },
  {
    id: "TRUC-KING",
    description:
      "Move your heavy loads at the touch of a button! ‘ The billion-dollar TrucKing industry in KSA is still far behind with the working process of the industry. Our Aim is to bring about revolution with the help of technology and align road transportation services in KSA with the Saudi Vision 2030 and bridge the country with the rest of the GCC.",
    imgSrc: "/assets/images/third_cohort/Truc-King.jpeg",
    startup_name: "TRUC-KING",
    email: "sahil@lahdatech.com",
    websiteLink: "https://www.truc-king.info/",
  },
  {
    id: "DIGITALPETROLEUM",
    description:
      "Founded in 2021 by an experienced Team, DIGITALPETROLEUM IS SAUDI STARTUP FOCUSING ON improving efficiency and sustainability in many industries by reducing the unplanned downtime using artificial intelligent SAAS PLATFORM.",
    imgSrc: "/assets/images/third_cohort/DIGITALPETROLEUM.png",
    startup_name: "DIGITALPETROLEUM",
    email: "h.althagafi@digitalpetroleum.com",
    websiteLink: "https://digitalpetroleum.com",
  },
  {
    id: "AlgebraIntelligence",
    description:
      "Algebra Intelligence, established in 2020, is a reputable software development firm specializing in digital technologies for facility environments. Their innovative solutions focus on energy management, maintenance streamlining, and team productivity. Their data-driven energy management approach connects fragmented facilities and energy infrastructure, empowering organizations to make informed decisions and achieve sustainable energy utilization for cost savings and environmental responsibility.",
    imgSrc: "/assets/images/third_cohort/AlgebraIntelligence.png",
    startup_name: "Algebra Intelligence",
    email: "ahmad@algebraintelligence.com",
    websiteLink: "https://algebraintelligence.com/",
  },
  {
    id: "HudhudAI",
    description:
      "Hudhud is a no-code platform that builds AI-enabled chatbots in no time. We help businesses unlock the power of AI to automate customers’ conversations and boost the productivity of their workforce by orders of magnitude. We are proud to be the first and most successful Saudi company that offers this service to our local markets addressing our client's special needs.",
    imgSrc: "/assets/images/third_cohort/HudhudAI.png",
    startup_name: "Hudhud AI",
    email: "tariq@hudhud.ai",
    websiteLink: "http://www.hudhud.ai/",
  },
  {
    id: "Slide",
    description:
      "Slide offers a social commerce solution with an integrated omnichannel system for e-commerce to enable businesses to integrate their online stores (Zid, Salla, Shopify) with their social media accounts and start social selling. Slide helps businesses transform their social media channels as an e-commerce front face and leverage the marketing opportunities in social platforms to create a seamless experience for customers to order online. Leveraging AI and machine learning technologies, Slide establishes conversation at scale and creates a unique retargeting opportunity for each business with its recommendation engine for better customer acquisition. Slide also offers a marketing tool to help companies analyze their customer in-web behavior and historical data to create actionable insights for marketing teams.",
    imgSrc: "/assets/images/third_cohort/Slide.png",
    startup_name: "Slide",
    email: "faisal@tryslide.co",
    websiteLink: "https://tryslide.co/",
  },
  {
    id: "Ratbli",
    description:
      "Ratbli is a smart digital platform and application that helps people and organizations in coordinating and arranging all events of different types and sizes through a flexible application that provides the best offers and discounts smoothly with a guarantee of quality and spreading joy and happiness in the attendees.",
    imgSrc: "/assets/images/third_cohort/Ratbli.png",
    startup_name: "Ratbli Application",
    email: "majed@ratbli.sa",
    websiteLink: "http://www.ratbli.sa/",
  },
  {
    id: "Edversity",
    description:
      "Edversity is an edtech platform creating next-generation technopreneurs through affordable and modern high-tech skills education while disrupting traditional education by empowering the youth with a contemporary e-learning platform powered with technologies like ChatGPT, preparing our students for the future of work. We've forged strategic partnerships with top-tier tech companies and the top 1% of experts to ensure our curriculum remains at the cutting edge. Our courses cover a wide spectrum of tech skills, from AI to Blockchain, & Data, etc., equipping learners to compete and excel in the digital age.",
    imgSrc: "/assets/images/third_cohort/Edversity.png",
    startup_name: "Edversity",
    email: "saadghufran@outlook.com",
    websiteLink: "https://edversity.com.pk/",
  },
];

export const getListPlusFourMock = [
  {
    id: "Aamar",
    description:
      "Aamar is a tech-driven property management platform that will streamline and automate leasing processes, ensuring efficient interactions between stakeholders.",
    imgSrc: "/assets/images/fourth_cohort/Aamar.jpg",
    startup_name: "Aamar",
    email: "mailto: faisal@aamar.sa",
    websiteLink: "https://aamar.sa",
  },
  {
    id: "Alawa",
    description:
      "B2B Statistical Analysis Software (SAS) model for financial asset management firms that want to digitize their workflows, save cost and increase efficiency.",
    imgSrc: "/assets/images/fourth_cohort/Alawa.jpg",
    startup_name: "Alawa",
    email: "mailto: a.alotaibi@alawa.com.sa",
    websiteLink: "http://alawa.com.sa/",
  },
  {
    id: "Baeynh",
    description:
      "Marketplace connecting lawyers and clients together, streamlining the process of searching, negotiating, scoping, and paying for legal services for both B2C and B2B.",
    imgSrc: "/assets/images/fourth_cohort/Baeynh.jpg",
    startup_name: "Baeynh",
    email: "mailto: muath@baeynh.com",
    websiteLink: "https://baeynh.com/",
  },
  {
    id: "BuildHop",
    description:
      "BuildHop is a B2B procurement platform for the construction industry. It focuses on a technology first approach to streamlining and solving difficulties in the procurement process of materials.",
    imgSrc: "/assets/images/fourth_cohort/BuildHop.jpg",
    startup_name: "BuildHop",
    email: "mailto: a.masoud@buildhop.com",
    websiteLink: "https://www.buildhop.com",
  },
  {
    id: "doobi",
    description:
      "Laundry Subscription platform, built for consumers, businesses and everything in the middle. Tailored to the busy life style, and budget-friendly.",
    imgSrc: "/assets/images/fourth_cohort/doobi.jpg",
    startup_name: "doobi",
    email: "mailto: emad@getdoobi.app",
    websiteLink: "https://www.getdoobi.app/",
  },
  {
    id: "GetOutfit",
    description:
      "Get Outfit is a GenAI-driven fashion app that streamlines discovery, creation, and monetization for fashion lovers featuring commission-based collaborations with over 20,000 high-end and authentic local brands, all in one place.",
    imgSrc: "/assets/images/fourth_cohort/GetOutfit.PNG",
    startup_name: "Get Outfit",
    email: "mailto: kim@getoutf.it",
    websiteLink: "https://getoutfit.app",
  },
  {
    id: "Mkhdoom",
    description:
      "Mkhdoom is a logistics platform where you can access different service providers in a one-stop solution, whether you’re an e-commerce business, importer or exporter, global trader, or a regular business, Mkhdoom platform is your logistics partner.",
    imgSrc: "/assets/images/fourth_cohort/mkhdoom.png",
    startup_name: "Mkhdoom",
    email: "mailto: ahmed@mkhdoom.sa",
    websiteLink: "https://app.mkhdoom.sa/",
  },
  {
    id: "Parqour",
    description:
      "End-to-end smart parking software as a service - proprietary license plate recognition algorithm, online payments, dynamic pricing, mobile app, real time data & analytics, dashboards. Can be integrated to any equipment (hardware-agnostic)",
    imgSrc: "/assets/images/fourth_cohort/Parqour.jpg",
    startup_name: "Parqour",
    email: "mailto: ceo@parqour.com",
    websiteLink: "https://www.parqour.com",
  },
  {
    id: "PlusOneHealth",
    description:
      "An online platform that provides access to medical second opinions for critical medical cases from a network of world-renowned experts in a timely and reliable manner.",
    imgSrc: "/assets/images/fourth_cohort/Plusone.jpg",
    startup_name: "PlusOne Health",
    email: "mailto: qusay@plusone.expert",
    websiteLink: "https://plusone.expert/",
  },
  {
    id: "SARsatX",
    description:
      "A data-capturing hardware and analytics software that provides reliable information from space for better decisions on earth.",
    imgSrc: "/assets/images/fourth_cohort/Sarsat.jpg",
    startup_name: "SARsatX",
    email: "mailto: ahmed.alzubairi@sarsatarabia.com",
    websiteLink: "https://sarsatarabia.com",
  },
  {
    id: "Seavo",
    description:
      "Seavo is revolutionizing marine micro-mobility with IoT-connected, environmentally-friendly electric watercraft, marine drones, and a versatile management system that empowers businesses to customize operations based on local policies and regulations.",
    imgSrc: "/assets/images/fourth_cohort/Seavo.jpg",
    startup_name: "Seavo",
    email: "mailto: aly@seavo.co",
    websiteLink: "https://seavo.co/",
  },
  {
    id: "TakhleesApp",
    description:
      "Tackling the importing and custom clearance process. Tahklees provides the client with logistic services needed to import their items and inventory and minimizes the issues related to it from the minute it arrives at the port until it reaches a local warehouse.",
    imgSrc: "/assets/images/fourth_cohort/Takhlees.png",
    startup_name: "Takhlees",
    email: "mailto: aalmiman@takhleeslogistic.com",
    websiteLink: "https://www.takhlees.app/",
  },
  {
    id: "WTDcare",
    description:
      "WTD is an innovative technology company that aims to revolutionize healthcare through AI-driven solutions, with a focus on improving patient outcomes and simplifying medical services.",
    imgSrc: "/assets/images/fourth_cohort/Wtdcare.jpg",
    startup_name: "WTDcare",
    email: "mailto: rakanjaber@wtd.care",
    websiteLink: "https://linktr.ee/wtdcare",
  },
];
