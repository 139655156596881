import React from "react";
import { BiMapPin } from "react-icons/bi";
import { GoLightBulb } from "react-icons/go";
import { GrSteps,GrChatOption } from "react-icons/gr";
import { RiEarthLine } from "react-icons/ri";

interface SelectionCriteria {
  id: number;
  title: string;
  description: string;
  icon: React.FC;
}

export const SelectionCriteriaDisruptData: SelectionCriteria[] = [
  {
    id: 1,
    title: "container.disruptContainer.SelectionCriteria1.Title1",
    icon: GrSteps,
    description: "container.disruptContainer.SelectionCriteria1.subTitle1",
  },
  {
    id: 2,
    title: "container.disruptContainer.SelectionCriteria2.Title2",
    icon: GoLightBulb,
    description: "container.disruptContainer.SelectionCriteria2.subTitle2",
  },
  {
    id: 3,
    title: "container.disruptContainer.SelectionCriteria3.Title3",
    icon: GrChatOption,
    description: "container.disruptContainer.SelectionCriteria3.subTitle3",
  },
  {
    id: 4,
    title: "container.disruptContainer.SelectionCriteria4.Title4",
    icon: RiEarthLine,
    description: "container.disruptContainer.SelectionCriteria4.subTitle4",
  },
  {
    id: 5,
    title: "container.disruptContainer.SelectionCriteria5.Title5",
    icon: BiMapPin,
    description: "container.disruptContainer.SelectionCriteria5.subTitle5",
  },
];

export const getDisruptInNumbersData = [
  {
    label: "container.disruptContainer.DisruptInNumbers1",
    Number: "20",
    char: null,
  },
  {
    label: "container.disruptContainer.DisruptInNumbers2",
    Number: "100",
    char: null,
  },
  {
    label: "container.disruptContainer.DisruptInNumbers3",
    Number: "2",
    char: "M",
  },
  {
    label: "container.disruptContainer.DisruptInNumbers4",
    Number: "1",
    char: "K",
  },
];
