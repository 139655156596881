import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { opengraphMetaTags } from "./core/helpers/example.helper";
import useModeStore from "./core/helpers/useModeStore";
import LandingPage from "./pages/user/LandingPage/landing-page";
// import MobilePage from "./pages/user/Mobile-page";

function App() {
  const { mode } = useModeStore();
  const isDarkMode = mode === "dark";
  const { t } = useTranslation();

  return (
    <>
      <Helmet
        title={t("app.shared.theGarage") as string}
        meta={[
          ...opengraphMetaTags({
            title: `${t("app.shared.theGarage")}`,
            description: `${t(
              "component.user.SidebarComponent.theGarageDescreption"
            )}`,
            logo: "/assets/images/logo.png",
          }),
          {
            name: "description",
            content: `${t(
              "component.user.SidebarComponent.theGarageDescreption"
            )}`,
          },
        ]}
      />
      <div
        className={`max-w-[100%] bg-cover ${
          isDarkMode ? "bg-mainBackground" : "bg-mainBackgroundLight"
        } `}
      >
        <LandingPage />
      </div>
    </>
  );
}

export default App;
