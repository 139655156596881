/* eslint-disable import/named */
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";

interface CardBorderGreenComponentProps {
  title: string;
  icon:IconType
}

export default function CardBorderGreenComponent(prop: CardBorderGreenComponentProps) {
  const { i18n } = useTranslation();

  return (
    <div className="flex justify-center items-center gap-x-2">
      <div className="flex items-center justify-center gradient-card-border custom-bg-blur rounded-lg w-8 h-8">
        < prop.icon className="h-4 text-white" />
      </div>
      <span
        className={`text-white ${
          i18n.language === "en"
            ? "font-helveticaMedium text-xs"
            : "font-arMyriad text-lg"
        }`}
      >
        {prop.title}
      </span>
    </div>
  );
}
