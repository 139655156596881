/* eslint-disable import/named */
import {
  Formik,
  useFormik,
  FormikTouched,
  FormikValues,
  FormikErrors,
} from "formik";
import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { BsX } from "react-icons/bs";
import { VscFilePdf } from "react-icons/vsc";
import Popup from "src/components/common/pop-up";
import ProgramSteps from "src/components/common/program-steps";
import { ReportProblemButtonComponent } from "src/components/common/report-problem-button-component";
import BackButton from "src/components/common/ui/BackButton";
import GridView from "src/components/common/ui/grid-view";
import InputField from "src/components/common/ui/InputField";
import ScrollView from "src/components/common/ui/scroll-view";
import SelectComponent from "src/components/common/ui/select-component";
import TextAreaField from "src/components/common/ui/textArea";
import { checkSizeAndExtensions } from "src/core/helpers/checkFileSize";
import { handleRequestError } from "src/core/helpers/error.helper";
import * as list from "src/core/statics/form-data/form-data";
import { PlusFormDataType } from "src/core/types/plus.type";
import { plusFormApplication } from "src/services/plus.service";
import { uploadImage, FileTypeEnum } from "src/services/upload.service";

import { plusInitialValues } from "./plus-form.data";
import { validationPlusSchema } from "./plus-form.validation";

function PlusForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t, i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [succeSsfully, setSucceSsfully] = useState<boolean>(false);
  const startupProfileCopy = document.getElementById(
    "startupProfile"
  ) as HTMLInputElement;
  const formik = useFormik({
    initialValues: plusInitialValues,
    onSubmit: async () => {
      await handleSubmit();
    },
    validationSchema: validationPlusSchema(startupProfileCopy),
  });
  const handleSubmit = async () => {
    const fileUploaded = startupProfileCopy?.files?.[0];
    try {
      if (executeRecaptcha) {
        const token = await executeRecaptcha();
        if (fileUploaded) {
          const logo_extension = fileUploaded.type.replace("application/", "");
          const validImage: boolean = checkSizeAndExtensions(
            logo_extension,
            fileUploaded.size
          );
          if (validImage) {
            const file = await uploadImage(
              FileTypeEnum.startup_profile,
              logo_extension,
              fileUploaded
            );
            await plusFormApplication(
              {
                first_name: formik.values.firstName,
                last_name: formik.values.lastName,
                email: formik.values.email,
                mobile: formik.values.phoneNo,
                gender: formik.values.gender,
                nationality:
                  formik.values.otherNationality !== ""
                    ? formik.values.otherNationality
                    : formik.values.nationality,
                identity_type: formik.values.idType,
                identity_number: formik.values.idNumber,
                startup_name: formik.values.startupName,
                year_of_establishment: formik.values.yearOfEstablishment,
                country:
                  formik.values.otherCountry !== ""
                    ? formik.values.otherCountry
                    : formik.values.country,
                startup_major: formik.values.startupMajor,
                website_link: formik.values.website,
                twitter:
                  formik.values.twitter?.length === 0
                    ? formik.initialValues.twitter
                    : formik.values.twitter,
                instagram:
                  formik.values.instagram?.length === 0
                    ? formik.initialValues.instagram
                    : formik.values.instagram,
                about_startup: formik.values.aboutStartup,
                startup_profile_url: file,
                hear_about_garage:
                  formik.values.otherHearAboutGarage !== ""
                    ? formik.values.otherHearAboutGarage
                    : formik.values.hearAboutGarage,
                reason_to_join: formik.values.reasonToJoin,
              },
              token
            );
            setSucceSsfully(!succeSsfully);
          }
        }
      }
    } catch (error) {
      handleRequestError(error);
    }
  };

  const handleBackClick = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + content.length) % content.length
    );
  };
  const years = Array.from({ length: 10 }, (_, index) =>
    (new Date().getFullYear() - index).toString()
  );

  const fieldsToCheck: string[] = [
    "firstName",
    "lastName",
    "phoneNo",
    "email",
    "nationality",
    "gender",
    "idType",
    "idNumber",
  ];
  const fieldsToCheck2: string[] = [
    "startupName",
    "yearOfEstablishment",
    "country",
    "startupMajor",
    "aboutStartup",
    "website",
  ];
  const content = [
    { label: "app.shared.personalInformation", color: "#737373" },
    { label: "app.shared.startupInformation", color: "#737373" },
    { label: "app.shared.additionalInformation", color: "#737373" },
  ];
  const isLastSection = activeIndex === content.length - 1;
  const [isvalid, setIsvalid] = useState<boolean>(false);

  const handleNext = (
    fieldsToCheck: string[],
    formik: {
      setTouched: (touched: FormikTouched<PlusFormDataType>) => void;
      values: FormikValues;
      errors: FormikErrors<PlusFormDataType>;
    },
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content: any[]
  ) => {
    const newTouched: FormikTouched<PlusFormDataType> = {};

    fieldsToCheck.forEach((field) => {
      if (!formik.values[field]) {
        newTouched[field] = true;
      }
    });
    const currentStep = activeIndex;
    let fieldsToValidate: string[];

    if (currentStep === 0) {
      fieldsToValidate = fieldsToCheck;
    } else if (currentStep === 1) {
      fieldsToValidate = fieldsToCheck2;
    } else {
      return handleSubmit();
    }

    fieldsToValidate.forEach((field) => {
      newTouched[field] = true;
    });

    formik.setTouched(newTouched);

    const hasErrors = fieldsToValidate.some(
      (field) => !formik.values[field] || !!formik.errors[field]
    );

    if (!hasErrors) {
      setIsvalid(!isvalid);
      setActiveIndex((prevIndex) =>
        Math.min(prevIndex + 1, content.length - 1)
      );
    }
  };

  const handlClick = () => {
    if (activeIndex !== 0 && activeIndex !== 1) {
      handleBackClick();
    } else if (activeIndex === 0) {
      handleNext(fieldsToCheck, formik, setActiveIndex, content);
    } else if (activeIndex === 1) {
      handleNext(fieldsToCheck2, formik, setActiveIndex, content);
    } else {
      handleSubmit();
    }
  };


  return (
    <div>
      <div className="flex justify-center ">
        <img
          className="w-[170px] tablet:w-[120px] cursor-pointer justify-center flex items-center"
          src="/assets/images/plus.svg"
          alt="logo"
        />
      </div>
      <div className="flex gap-12 pt-5 mobile:justify-between mobile:gap-2 tablet:gap-2 text-base text-neutral-500">
        {content.map((item, index) => (
          <ProgramSteps
            activeIndex={activeIndex}
            index={index}
            color={item.color}
            label={item.label}
            key={index}
            completed={activeIndex > index}
            onClick={handlClick}
          />
        ))}
      </div>

      <div>
        <Formik
          initialValues={plusInitialValues}
          onSubmit={() => formik.submitForm()}
          validationSchema={formik.initialValues}
          validateOnChange
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            {activeIndex === 0 && (
              <GridView column={"2"}>
                <InputField
                  label="app.shared.firstName"
                  name="firstName"
                  isValid={formik.touched.firstName && !formik.errors.firstName}
                  value={
                    formik.values.firstName != null
                      ? formik.values.firstName
                      : ""
                  }
                  errorMsg={
                    formik.touched.firstName && formik.errors.firstName
                      ? formik.errors.firstName
                      : ""
                  }
                  onChange={(e) => {
                    formik.setFieldValue("firstName", e.target.value);
                    formik.handleChange(e);
                  }}
                />
                <InputField
                  label="app.shared.lastName"
                  name="lastName"
                  isValid={formik.touched.lastName && !formik.errors.lastName}
                  value={
                    formik.values.lastName != null ? formik.values.lastName : ""
                  }
                  errorMsg={
                    formik.touched.lastName && formik.errors.lastName
                      ? formik.errors.lastName
                      : ""
                  }
                  onChange={(e) => {
                    formik.setFieldValue("lastName", e.target.value);
                    formik.handleChange(e);
                  }}
                />
                <InputField
                  label="app.shared.mobile.number"
                  name="phoneNo"
                  helperText={""}
                  errorMsg={
                    formik.touched.phoneNo && formik.errors.phoneNo
                      ? formik.errors.phoneNo
                      : ""
                  }
                  minLength={9}
                  isValid={formik.touched.phoneNo && !formik.errors.phoneNo}
                  value={
                    formik.values.phoneNo != null ? formik.values.phoneNo : NaN
                  }
                  onChange={(e) => {
                    formik.setFieldValue("phoneNo", e.target.value);
                    formik.handleChange(e);
                  }}
                />

                <InputField
                  label="app.shared.email"
                  name="email"
                  isValid={formik.touched.email && !formik.errors.email}
                  value={formik.values.email != null ? formik.values.email : ""}
                  errorMsg={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : ""
                  }
                  onChange={(e) => {
                    formik.setFieldValue("email", e.target.value);
                    formik.handleChange(e);
                  }}
                />
                <InputField
                  label="app.shared.nationalty"
                  name="nationality"
                  isValid={
                    formik.touched.nationality && !formik.errors.nationality
                  }
                  value={
                    formik.values.nationality != null
                      ? formik.values.nationality
                      : ""
                  }
                  errorMsg={
                    formik.touched.nationality && formik.errors.nationality
                      ? formik.errors.nationality
                      : ""
                  }
                  onChange={(e) => {
                    formik.setFieldValue("nationality", e.target.value);
                    formik.handleChange(e);
                  }}
                />
                <SelectComponent
                  selectLabel="app.shared.gender"
                  options={
                    i18n.language === "en" ? list.gender_en : list.gender_ar
                  }
                  value={
                    formik.values.gender != null ? formik.values.gender : ""
                  }
                  errorMsg={
                    formik.touched.gender && formik.errors.gender
                      ? formik.errors.gender
                      : ""
                  }
                  onChange={(e) => {
                    formik.setFieldValue("gender", e.target.value);
                    formik.handleChange(e);
                  }}
                  name="gender"
                />

                <SelectComponent
                  selectLabel="app.shared.idType"
                  options={list.idType}
                  onChange={(e) => {
                    formik.setFieldValue("idType", e.target.value);
                    formik.handleChange(e);
                  }}
                  value={
                    formik.values.idType != null ? formik.values.idType : ""
                  }
                  errorMsg={
                    formik.touched.idType && formik.errors.idType
                      ? formik.errors.idType
                      : ""
                  }
                  name="idType"
                />

                <InputField
                  label={
                    formik.values.idType === 'app.shared.iqamaNo'
                      ? `${t('app.shared.iqamaNo')}*`
                      : formik.values.idType === 'app.shared.Passport'
                        ? `${t('app.shared.passportNo')}*`
                        : `${t('app.shared.idNo')}*`
                  }
                  name="idNumber"
                  isValid={formik.touched.idNumber && !formik.errors.idNumber}
                  value={
                    formik.values.idNumber != null ? formik.values.idNumber : ""
                  }
                  errorMsg={
                    formik.touched.idNumber && formik.errors.idNumber
                      ? formik.errors.idNumber
                      : ""
                  }
                  onChange={(e) => {
                    formik.setFieldValue("idNumber", e.target.value);
                    formik.handleChange(e);
                  }}
                />
              </GridView>
            )}
            {activeIndex === 1 && (
              <ScrollView>
                <GridView column={"2"}>
                  <InputField
                    label="app.shared.startupName"
                    name="startupName"
                    isValid={
                      formik.touched.startupName && !formik.errors.startupName
                    }
                    value={
                      formik.values.startupName != null
                        ? formik.values.startupName
                        : ""
                    }
                    errorMsg={
                      formik.touched.startupName && formik.errors.startupName
                        ? formik.errors.startupName
                        : ""
                    }
                    onChange={(e) => {
                      formik.setFieldValue("startupName", e.target.value);
                      formik.handleChange(e);
                    }}
                  />
                  <SelectComponent
                    selectLabel="app.shared.yearOfEstablishment"
                    name="yearOfEstablishment"
                    isValid={
                      formik.touched.yearOfEstablishment &&
                      !formik.errors.yearOfEstablishment
                    }
                    value={
                      formik.values.yearOfEstablishment != null
                        ? formik.values.yearOfEstablishment
                        : ""
                    }
                    errorMsg={
                      formik.touched.yearOfEstablishment &&
                        formik.errors.yearOfEstablishment
                        ? formik.errors.yearOfEstablishment
                        : ""
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        "yearOfEstablishment",
                        e.target.value
                      );
                      formik.handleChange(e);
                    }}
                    options={years}
                  />
                  <SelectComponent
                    selectLabel="app.shared.country"
                    name="country"
                    isValid={formik.touched.country && !formik.errors.country}
                    value={
                      formik.values.country != null ? formik.values.country : ""
                    }
                    errorMsg={
                      formik.touched.country && formik.errors.country
                        ? formik.errors.country
                        : ""
                    }
                    onChange={(e) => {
                      formik.setFieldValue("country", e.target.value);
                      formik.handleChange(e);
                    }}
                    options={
                      i18n.language === "en"
                        ? list.countries_en
                        : list.countries_ar
                    }
                  />
                  <SelectComponent
                    selectLabel="app.shared.startupMajor"
                    name="startupMajor"
                    isValid={
                      formik.touched.startupMajor && !formik.errors.startupMajor
                    }
                    value={
                      formik.values.startupMajor != null
                        ? formik.values.startupMajor
                        : ""
                    }
                    errorMsg={
                      formik.touched.startupMajor && formik.errors.startupMajor
                        ? formik.errors.startupMajor
                        : ""
                    }
                    onChange={(e) => {
                      formik.setFieldValue("startupMajor", e.target.value);
                      formik.handleChange(e);
                    }}
                    options={
                      i18n.language === "en"
                        ? list.company_size_en
                        : list.company_size_ar
                    }
                  />
                  <InputField
                    label="app.shared.instagram"
                    name="instagram"
                    isValid={
                      formik.touched.instagram && !formik.errors.instagram
                    }
                    value={
                      formik.values.instagram != null
                        ? formik.values.instagram
                        : ""
                    }
                    errorMsg={
                      formik.touched.instagram && formik.errors.instagram
                        ? formik.errors.instagram
                        : ""
                    }
                    onChange={(e) => {
                      formik.setFieldValue("instagram", e.target.value);
                      formik.handleChange(e);
                    }}
                  />
                  <InputField
                    label="app.shared.x"
                    name="twitter"
                    isValid={formik.touched.twitter && !formik.errors.twitter}
                    value={
                      formik.values.twitter != null ? formik.values.twitter : ""
                    }
                    errorMsg={
                      formik.touched.twitter && formik.errors.twitter
                        ? formik.errors.twitter
                        : ""
                    }
                    onChange={(e) => {
                      formik.setFieldValue("twitter", e.target.value);
                      formik.handleChange(e);
                    }}
                  />
                </GridView>
                <GridView column={"1"}>
                  <div className="col-span-2 tablet:col-span-1 mobile:col-span-1 pt-4 mobile:pt-2 tablet:pt-2 ">
                    <InputField
                      label="app.shared.website"
                      name="website"
                      isValid={formik.touched.website && !formik.errors.website}
                      value={
                        formik.values.website != null
                          ? formik.values.website
                          : ""
                      }
                      errorMsg={
                        formik.touched.website && formik.errors.website
                          ? formik.errors.website
                          : ""
                      }
                      onChange={(e) => {
                        formik.setFieldValue("website", e.target.value);
                        formik.handleChange(e);
                      }}
                    />
                  </div>

                  <div className="col-span-2 tablet:col-span-1 mobile:col-span-1">
                    <TextAreaField
                      label="app.shared.aboutStartup"
                      name="aboutStartup"
                      limit={250}
                      isValid={
                        formik.touched.aboutStartup &&
                        !formik.errors.aboutStartup
                      }
                      value={
                        formik.values.aboutStartup != null
                          ? formik.values.aboutStartup
                          : ""
                      }
                      errorMsg={
                        formik.touched.aboutStartup &&
                          formik.errors.aboutStartup
                          ? formik.errors.aboutStartup
                          : ""
                      }
                      onChange={(e) => {
                        formik.setFieldValue("aboutStartup", e.target.value);
                        formik.handleChange(e);
                      }}
                    />
                  </div>
                </GridView>
              </ScrollView>
            )}
            {activeIndex === 2 && (
              <GridView column={"1"}>
                <div
                  className={`backdrop-blur-lg p-4 rounded-lg w-full h-full bg-neutral-500/10
                         ${formik.touched.startupProfile &&
                      formik.errors.startupProfile
                      ? "border border-danger"
                      : ""
                    }`}
                >
                  <div className={`grid px-4 text-gray-400 ${i18n.language === "en" ? "font-helveticaMedium text-base" : "font-arMyriad text-xl"} `}>
                    <label htmlFor="startupProfile">
                      {t("app.shared.startupProfile")}
                      <div  >
                        <div className="flex flex-col items-center justify-center w-full h-full mx-auto">
                          <label className="flex flex-col items-center">
                            <VscFilePdf className="text-teal-500" size={18} />
                            <p className="text-xs text-teal-500 font-helveticaMedium">
                              {t("app.shared.clickUpload")}
                            </p>
                            <p className="text-xs font-helveticaMedium">
                              {t("app.shared.sizeFile")}
                            </p>
                            <input
                              type="file"
                              className="hidden"
                              name="startupProfile"
                              id="startupProfile"
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "startupProfile",
                                  e.target.value
                                );
                                formik.handleChange(e);
                              }}
                              accept="application/pdf,application/vnd.ms-excel"
                            />
                          </label>
                          {startupProfileCopy?.value && (
                            <div className="w-full p-3 mt-3 text-gray-400 rounded-lg font-sm bg-neutral-500/10">
                              <ul>
                                <li>
                                  <div className="flex justify-between">
                                    {startupProfileCopy &&
                                      startupProfileCopy.files &&
                                      startupProfileCopy.files[0] &&
                                      startupProfileCopy.files[0].name}
                                    <button
                                      onClick={() =>
                                        (startupProfileCopy.value = "")
                                      }
                                    >
                                      <BsX
                                        size={16}
                                        className="text-slate-400"
                                      />
                                    </button>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="-mt-3 mobile:mt-px tablet:mt-px">
                  {formik.touched.startupProfile &&
                    formik.errors.startupProfile && (
                      <p className={`text-xs text-danger ${i18n.language === "en" ? "font-helveticaMedium" : "font-arMyriad"}`}>
                        {`${t(formik.errors.startupProfile)}`}
                      </p>
                    )}
                </div>

                <InputField
                  label="app.shared.wayOfLinking"
                  name="hearAboutGarage"
                  isValid={
                    formik.touched.hearAboutGarage &&
                    !formik.errors.hearAboutGarage
                  }
                  value={
                    formik.values.hearAboutGarage != null
                      ? formik.values.hearAboutGarage
                      : ""
                  }
                  errorMsg={
                    formik.touched.hearAboutGarage &&
                      formik.errors.hearAboutGarage
                      ? formik.errors.hearAboutGarage
                      : ""
                  }
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
                <TextAreaField
                  label="app.shared.whyJoinPlusTheGarage"
                  name="reasonToJoin"
                  isValid={
                    formik.touched.reasonToJoin && !formik.errors.reasonToJoin
                  }
                  value={
                    formik.values.reasonToJoin != null
                      ? formik.values.reasonToJoin
                      : ""
                  }
                  errorMsg={
                    formik.touched.reasonToJoin && formik.errors.reasonToJoin
                      ? formik.errors.reasonToJoin
                      : ""
                  }
                  limit={250}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              </GridView>
            )}
          </form>
        </Formik>
      </div>

      <div className="flex flex-row justify-center gap-4 pt-10 pb-5">


        <ReportProblemButtonComponent
          title={`${isLastSection ? t("app.shared.send") : t("app.shared.next")}`}
          onClick={() => {
            if (activeIndex === 0) {
              handleNext(fieldsToCheck, formik, setActiveIndex, content);
            } else if (activeIndex === 1) {
              handleNext(fieldsToCheck2, formik, setActiveIndex, content);
            } if (isLastSection) {
              formik.handleSubmit();
            }
          }}

        />
        {activeIndex !== 0 && (
          <BackButton onClick={handleBackClick} />
        )}
      </div>


      {succeSsfully && (
        <div className="max-w-[100%] bg-cover w-screen z-50 absolute top-0 bottom-0 bg-black/50 backdrop-blur-2xl rounded-xl py-[186px] px-[238px]">
          <Popup
            body="Your request has been sent successfully"
            onClose={() => setSucceSsfully(false)}
          />
        </div>
      )}
    </div>
  );
}

export default PlusForm;
