import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { appRoutesObj } from "src/app.paths";
import { Layout } from "src/components/common/layout-component/layout-component";
import PopupCard from "src/components/common/PopupCard";
import TeamComponent from "src/components/user/team-component";
import { opengraphMetaTags } from "src/core/helpers/example.helper";

export default function TeamPage() {
  const { i18n, t } = useTranslation();

  return (
    <Layout>
      <>
        <Helmet
          title={`${t("app.shared.theGarage")} | ${t(
            "container.user.community.communityOurTeam"
          )}`}
          meta={[
            ...opengraphMetaTags({
              title: `${t("app.shared.theGarage")} | ${t(
                "container.user.community.communityOurTeam"
              )}`,
              description: `${t(
                "component.user.SidebarComponent.theGarageDescreption"
              )}`,
              logo: "/assets/images/logo.png",
            }),
            {
              name: "description",
              content: `${t(
                "component.user.SidebarComponent.theGarageDescreption"
              )}`,
            },
          ]}
        />
        <PopupCard
          link={appRoutesObj.getBasePath()}
          icon={
            i18n.language === "en" ? (
              <IoMdClose size={32} className="text-white" />
            ) : (
              <IoMdClose size={32} className="text-white" />
            )
          }
        >
          <TeamComponent />
        </PopupCard>
      </>
    </Layout>
  );
}
