import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useTranslation } from "react-i18next";
import {
  PiPhone,
  PiEnvelopeSimple,
  PiGlobe,
  PiVideoCamera,
} from "react-icons/pi";
import { useLocation } from "react-router-dom";
import CardPhotoComponent from "src/components/common/card-photo-component";
import SelectComponent from "src/components/common/ui/select-component";
import CardComponent from "src/components/user/card-component";
import PopupVideo from "src/components/user/popup-video-component";
import i18n from "src/core/configs/i18n";
import * as list from "src/core/statics/form-data/form-data";

import { getListPlusService } from "./list-plus";

const StartupPlusContainer = () => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [page, setPage] = useState<string>("container.community.1.cohort");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [images, setImages] = useState<any[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [, setIsTextOverflowing] = useState<boolean>(false);

  const location = useLocation();
  const photoId = new URLSearchParams(location.search).get("photoId");
  const descriptionRef = useRef<HTMLParagraphElement>(null);

  const handleItemClick = useCallback((index: number) => {
    setSelectedItem(index);
    setIsExpanded(false);
    if (descriptionRef.current) {
      const { scrollHeight, clientHeight } = descriptionRef.current;
      setIsTextOverflowing(scrollHeight > clientHeight);
    }
  }, []);

  const handleChange = useCallback(async (selectedPage: string) => {
    await getDisrupt(selectedPage);
  }, []);

  const handleCardClick = useCallback(() => {
    setIsPopupOpen(!isPopupOpen);
  }, [isPopupOpen]);

  const handleClosePopup = useCallback(() => {
    setIsPopupOpen(false);
  }, []);

  const getDisrupt = useCallback(async (page: string) => {
    try {
      const disrupt = await getListPlusService(page);
      unstable_batchedUpdates(() => {
        setImages(disrupt);
        setPage(page);
        setSelectedItem(0);
      });
    } catch (error) {
      error;
    }
  }, []);

  useEffect(() => {
    if (photoId !== null) {
      setSelectedItem(parseInt(photoId, 10));
    } else {
      const localPhotoId = localStorage.getItem("PhotoId");
      if (localPhotoId !== null) {
        setSelectedItem(parseInt(localPhotoId, 10));
      }
    }
  }, [photoId]);

  useEffect(() => {
    getDisrupt(page);
  }, [page, getDisrupt]);

  useEffect(() => {
    if (descriptionRef.current) {
      const { scrollHeight, clientHeight } = descriptionRef.current;
      setIsTextOverflowing(scrollHeight > clientHeight);
    }
  }, [images, selectedItem, isExpanded]);

  const toggleExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const translatedDescription = useMemo(() => {
    return images[selectedItem]?.description
      ? t(images[selectedItem].description)
      : "";
  }, [images, selectedItem, t]);

  const truncatedDescription = useMemo(() => {
    return translatedDescription.length > 200
      ? `${translatedDescription.slice(0, 200)}...`
      : translatedDescription;
  }, [translatedDescription]);

  const displayDescription = isExpanded
    ? translatedDescription
    : truncatedDescription;

  return (
    <div>
      {selectedItem !== null && images[selectedItem] && (
        <div className="flex items-center mx-auto w-full justify-start px-5 mobile:px-0 mobile:flex-col">
          <img
            src={images[selectedItem].imgSrc}
            alt={images[selectedItem]?.startup_name}
            className="h-[160px] w-[157px] bg-white2Color px-3 py-2 rounded-lg mobile:w-[350px] mobile:h-[180px] mobile:object-contain"
          />
          <div
            className={`text-white px-4 ${
              i18n.language === "ar" ? "text-right" : "text-left"
            }`}
          >
            <p
              className={`text-base font-helveticaMedium mobile:mt-4 ${
                i18n.language === "ar"
                  ? "text-right font-arMyriad"
                  : "font-helveticaMedium"
              }`}
            >
              {t(`${images[selectedItem].startup_name}`)}
            </p>
            <span className="pt-2 h-[80px] mobile:h-auto flex flex-col justify-start">
              <p
                ref={descriptionRef}
                className={`items-start mobile:w-full text-naturalGray400 ${
                  isExpanded
                    ? "overflow-auto h-[79px] mobile:h-[95px]"
                    : "overflow-hidden text-ellipsis"
                } ${
                  i18n.language === "ar"
                    ? "text-right font-arMyriad text-base"
                    : "font-helveticaMedium text-sm"
                }`}
              >
                {displayDescription}
                {!isExpanded && translatedDescription.length > 200 && (
                  <button
                    onClick={toggleExpand}
                    className="text-yellow underline bg-transparent text-xs font-helveticaLight ml-1"
                  >
                    {t(
                      "containers.StartupPlusContainer.descriptionExpandedButton"
                    )}
                  </button>
                )}
              </p>
            </span>
            <div className="flex justify-start pt-2 mobile:pt-10">
              <div className="grid grid-cols-4 gap-x-4">
                <div className="w-8 h-10">
                  <CardComponent
                    isHover={true}
                    chiled={true}
                    icon={<PiPhone />}
                  />
                </div>
                <div className="w-8 h-10">
                  <a href={`mailto:${images[selectedItem].email}`}>
                    <CardComponent
                      isHover={true}
                      chiled={true}
                      icon={<PiEnvelopeSimple />}
                    />
                  </a>
                </div>
                <div className="w-8 h-10">
                  <div
                    onClick={() =>
                      window.open(images[selectedItem].websiteLink)
                    }
                  >
                    <CardComponent
                      isHover={true}
                      chiled={true}
                      icon={<PiGlobe />}
                    />
                  </div>
                </div>
                <div className="w-8 h-10">
                  {images[selectedItem].hasVideo && (
                    <div onClick={() => handleCardClick()}>
                      <CardComponent
                        isHover={true}
                        chiled={true}
                        icon={<PiVideoCamera size={24} />}
                      ></CardComponent>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isPopupOpen && (
        <div
          className={`z-50 absolute h-full inset-0 ${
            i18n.language === "en"
              ? "left-80 mobile:left-0"
              : "right-80 mobile:right-0"
          } mobile:top-0 mobile:left-0 tablet:top-80 `}
        >
          <div className="flex justify-center mx-auto items-center h-full">
            <PopupVideo
              video={`${images[selectedItem].videoLink}`}
              onClose={handleClosePopup}
            />
          </div>
        </div>
      )}
      <div className="flex justify-start w-full tablet:justify-center">
        <div className="w-1/3 largeLaptop:w-[45%] px-4 pt-4 mobile:w-full mobile:px-2 tablet:w-full">
          <SelectComponent
            selectLabel="app.shared.select"
            options={i18n.language === "en" ? list.plus_en : list.plus_Ar}
            name="Plus"
            defaultValue={`${t("The first cohort")}`}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              handleChange(e.target.value)
            }
          />
        </div>
      </div>
      <div className="w-full pt-7 desktop:h-[290px] largeLaptop:h-[200px] laptop:h-[270px] tablet:h-[275px] tablet:pt-6 overflow-auto">
        <div className="grid grid-cols-7 place-content-center place-items-center largeLaptop:-mt-6 laptop:grid-cols-6 mobile:pt-16 mobile:grid-cols-2 mobile:mt-8 mobile:gap-1 mobile:px-2">
          {images.map((val, index) => (
            <div key={index} onClick={() => handleItemClick(index)}>
              <CardPhotoComponent chiled={true} isHover={true}>
                <img
                  src={val.imgSrc}
                  alt={val.CompanyName}
                  width={100}
                  className="w-[44px] bg-white rounded-lg h-[44px] laptop:w-[28px] largeLaptop:w-[45px] mobile:h-[94px] mobile:w-[97px] object-contain"
                />
              </CardPhotoComponent>
              <h1
                className={`pt-2 pb-4 text-center mobile:mx-8 text-white text-[13px] mobile:text-[20px] mobile:pb-2 truncate w-20 desktop:truncate ${
                  i18n.language === "ar"
                    ? "font-arMyriad"
                    : "font-helveticaMedium"
                }`}
              >
                {t(`${val.startup_name}`)}
              </h1>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StartupPlusContainer;
