import { useState } from "react";
import { useTranslation } from "react-i18next";

interface SelectionCriteriaList {
  selectionCriteriaList: SelectionCriteria[];
  textLeft?: boolean;
  size?: "sm" | "md" | "lg";
}
interface SelectionCriteria {
  id: number;
  label: string;
}

function SelectionCriteriaComponent(props: SelectionCriteriaList) {
  const [activeIndex, setActiveIndex] = useState(0);
  const { t, i18n } = useTranslation();

  const handleItemClick = (index: number) => {
    setActiveIndex(index);
    const cardElement = document.getElementById(`card-${index + 1}`);
    if (cardElement) {
      cardElement.scrollIntoView({ behavior: "smooth" });
    }
  };



  const getSize = () => {
    switch (props.size) {
      case "sm":
        return "py-1 w-fit";

      case "md":
        return " py-2 w-36 px-4";

      case "lg":
        return " py-4 w-96";

      default:
        return "py-3 w-72";
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-3 pt-4">
      <div className="flex flex-col gap-4 w-full">
        <div className="w-full overflow-x-auto hide-scrollbar mobile:overflow-y-auto tablet:overflow-y-auto">
          <div className="w-96 mobile:w-full tablet:w-full h-[60px] mobile:h-auto tablet:h-auto flex flex-row mobile:flex-col tablet:flex-col">
            <div className="flex gap-4 mobile:flex-col mobile:items-center tablet:flex-col tablet:items-center">
              {props.selectionCriteriaList.map((val, index) => (
                <div
                  key={index}
                  id={`card-${val.id}`}
                  className={`${getSize()} mobile:w-full tablet:h-[103px] tablet:w-full flex  justify-center items-center bg-black/20 rounded-2xl bg-opacity-5`}
                  onClick={() => handleItemClick(index)}
                >
                  <p
                    className={`${
                      i18n.language === "en"
                        ? "font-helveticaMedium mobile:text-[11px]"
                        : "font-arMyriad mobile:text-[13px]"
                    } text-xs text-naturalGray400  px-1`}
                  >
                    {t(`${val.label}`)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-2 mobile:hidden tablet:hidden">
        {props.selectionCriteriaList.map((_, index) => (
          <div
            key={index}
            className={`rounded-lg cursor-pointer h-2 ${activeIndex === index ? "bg-primary w-5" : "bg-Zinc600 w-3"}`}
            onClick={() => handleItemClick(index)}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default SelectionCriteriaComponent;
