import * as Yup from "yup";
export const validationSchema = () =>
    Yup.object().shape({
        name: Yup.string().required('form.validation.required'),
        email: Yup.string().required('form.validation.required').email('form.validation.email'),
        job: Yup.string().required('form.validation.required'),
        vehicle: Yup.string().required('form.validation.required'),
        nationality: Yup.string().required('form.validation.required')
            .max(255, "form.validation.nationlity.max.255"),
        equipment: Yup.string().required('form.validation.required'),
        quantity: Yup.number().required('form.validation.required'),
        description: Yup.string().required('form.validation.required'),
        notes: Yup.string(),
        permit_date: Yup.string().required('form.validation.required'),
        reason: Yup.string().required('form.validation.required').max(250,"form.validation.reason.max.250"),
        transfer_from: Yup.string().required('form.validation.required'),
        transfer_to: Yup.string().required('form.validation.required'),
    });
