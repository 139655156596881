import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { appRoutesObj } from "src/app.paths";
import { Layout } from "src/components/common/layout-component/layout-component";
import PopupCard from "src/components/common/PopupCard";
import NewsDetailsContainer from "src/containers/user/news-container/news-details-container";

function NewsDetailsPage() {
  const { i18n } = useTranslation();

  return (
    <Layout>
      <PopupCard
        link={appRoutesObj.getNewsPagePath()}
        icon={
          i18n.language === "en" ? (
            <AiOutlineArrowRight size={32} className="text-white" />
          ) : (
            <AiOutlineArrowLeft size={32} className="text-white" />
          )
        }
      >
        <NewsDetailsContainer />
      </PopupCard>
    </Layout>
  );
}

export default NewsDetailsPage;
