import { baseURL, makeRequest } from "src/core/data-access/http.service";
import { HttpMethods } from "src/core/enums/httpMethods.enum";

export const getTeamService = async (
  lang: string,
  limit: number,
  offset: number,
) => {
  const res = await makeRequest({
    url: `${baseURL}/team`,
    method: HttpMethods.GET,
    params: {
      lang: lang,
      offset: offset,
      limit: limit,
    },
  });

  return res;
};
