/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */

import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "src/core/configs/i18n";
import { handleRequestError } from "src/core/helpers/error.helper";
import { GarageTeam, GarageTeamList } from "src/core/types/team.type";
import { getTeamService } from "src/services/team-services";

import Loading from "../common/Loading/loading";
import Pagination from "../common/Pagination/pagination";

function TeamComponent() {
  const { t } = useTranslation();
  const paginationLimit = 16;
  const [fulldata, setFullData] = useState<GarageTeam>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const getTeamList = useCallback(async () => {
    setIsLoading(true);
    try {
      const Team = await getTeamService(
        i18n.language.toLocaleUpperCase(),
        paginationLimit,
        (page - 1) * paginationLimit
      );
      setIsLoading(false);
      setFullData(Team);
    } catch (error) {
      handleRequestError(error);
    }
  }, [i18n.language, page]);

  useEffect(() => {
    getTeamList();
  }, [getTeamList]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="w-full grid grid-cols-4 gap-2 laptop:h-[550px] largeLaptop:h-[400px] overflow-y-auto text-white tablet:grid-cols-2 mobile:grid-cols-2 laptop:grid-cols-3">
        {fulldata?.result_list.map((items: GarageTeamList, index: number) => {
          return (
            <div
              key={`image-${index}`}
              className="flex flex-col items-center relative"
            >
              <img
                className="w-[200px] h-[132px] laptop:w-[140px] laptop:h-[90px] mobile:w-[171px] mobile:h-[168px] rounded-lg object-cover"
                src={`${items.image}`}
                alt={""}
              />
              <div className="absolute bg-neutral-500 opacity-50 top-20 mt-[13px] w-[200px] largeLaptop:w-[99%] mobile:w-[169px] mobile:mt-12 laptop:w-[140px] laptop:h-[26px] laptop:-mt-4 rounded-b-lg">
                <h1
                  className={`text-[13px] ${
                    i18n.language === "en"
                      ? "font-helveticaMedium"
                      : "font-arMyriad "
                  } pl-2`}
                >
                  {t(`${items.name}`)}
                </h1>
                <h1
                  className={`text-[13px] ${
                    i18n.language === "en"
                      ? "font-helveticaMedium"
                      : "font-arMyriad "
                  } laptop:-mt-2 text-slate-300 pl-2`}
                >
                  {t(`${items.position}`)}
                </h1>
              </div>
            </div>
          );
        })}
      </div>
      <div className="px-8 largeLaptop:px-0 fixed bottom-[12%] largeLaptop:bottom-[11.5%]">
        <Pagination
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          totalPage={Math.ceil(fulldata?.total_count! / paginationLimit)}
          page={page}
          limit={paginationLimit}
          onPageChange={setPage}
          totalCount={fulldata?.total_count || 0}
        />
      </div>
    </div>
  );
}

export default TeamComponent;
