import { appRoutesObj } from "src/app.paths";

export const ActivitiesElement = [
  {
    title: "container.user.ProgrameContainer.card.Title",
    img: "assets/images/disrubt.svg ",
    description: "container.disruptTimerContainer.CommingSoon",
  }
];

export const reservationItems = [
  {
    title: "component.user.reservationComponent.BooktourTitle",
    description: "component.user.reservationComponent.BooktourDescreption",
    link: appRoutesObj.getBookTourFormPagePath(),
  },
  {
    title: "containers.BeOurPartnerFormContainer.title",
    description: "containers.BeOurPartnerFormContainer.description",
    link: appRoutesObj.getBeOurPartnerFormPagePath(),
  },
  {
    title: "containers.BookVenueFormContainer.title",
    description: "containers.BookVenueFormContainer.description",
    link: appRoutesObj.getBookVenueFormPagePath(),
  },
];
