import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Layout } from "src/components/common/layout-component/layout-component";
import PopupCard from "src/components/common/PopupCard";
import AboutTheGarageContainer from "src/containers/user/about-thegarage-container";

function AboutTheGaragePage() {
  const navigate = useNavigate();
  
  return (
    <Layout>
      <PopupCard
     
        icon= {<IoMdClose size={32} className="text-white" onClick={()=>{ navigate(-1)}} />}
      >
        <AboutTheGarageContainer />
      </PopupCard>
    </Layout>
  );
}

export default AboutTheGaragePage;
