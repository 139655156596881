import { baseURL, makeRequest } from "src/core/data-access/http.service";
import { HttpMethods } from "src/core/enums/httpMethods.enum";

export const getDisruptTimeServices = () => {
  return makeRequest({
    url: `${baseURL}/disrupt/version`,
    method: HttpMethods.GET,
  });
};

export const disruptStatus = async () => {
  return await makeRequest({
    url: `${baseURL}/disrupt/status`,
    method: HttpMethods.GET,
  });
};
