export enum example {
  example = "example",
}

export enum LanguagesEnum {
  EN = "en",
  AR = "ar",
}

export enum GalleryEnum {
  PLUS = "Plus",
  DISRUPT = "Disrupt",
  INCUBATOR = "Incubator",
  the_garage = "the_garage",
  ALL = "",
}
