import { appRoutesObj } from "src/app.paths";

export const items = [
  {
    name: "container.user.community.communityHydrojeen",
    description: "container.user.community.communityHydrojeen.Descreption",
    logo: {
      url: "/assets/images/Hydrojeen.png",
      altText: "Plus",
    },
  },
  {
    name: "container.user.community.communityEventful",
    description: "container.user.community.communityEventfulDescreption",
    logo: {
      url: "/assets/images/eventful.png",
      altText: "Plus",
    },
  },
  {
    name: "container.user.community.communityShiftat",
    description: "container.user.community.communityShiftatDescreption",
    logo: {
      url: "/assets/images/Logo1.png",
      altText: "Plus",
    },
  },
];
export const linksData = [
  {
    path: appRoutesObj.getMediaPagePath(),
    title: "container.user.community.communitymedia",
  },
  {
    path: appRoutesObj.getNewsPagePath(),
    title: "container.user.community.communityNews",
  },
  {
    path: appRoutesObj.getCommonQuestionsPagePath(),
    title: "container.user.community.communityF&Q",
  },

];
