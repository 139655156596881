function NewsSectionSkeletonCard(): JSX.Element {
  return (
    <div className="relative flex flex-col items-start justify-center h-fit bg-neutral-800 rounded-2xl animate-pulse">
      <div className="h-60 w-full largeLaptop:h-48 mobile:h-[234px] rounded-xl" />
      <div className="bg-neutral-800 w-[100%] rounded-b-lg h-1/3 bottom-0 absolute px-1 justify-center flex flex-col">
        <div className="bg-gray-600 animate-pulse h-5 rounded-full mb-2" />
        <div className="bg-gray-600 animate-pulse h-2 rounded-full" />
      </div>
    </div>
  );
}

export default NewsSectionSkeletonCard;
