import React from 'react';
import { PiArrowLeft, PiArrowRight } from 'react-icons/pi';

// import { CarouselWidth } from './Carousel';
// import i18n from 'src/core/configs/i18n';

interface CarouselBtnProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: 'Left' | 'Right'; // Direction
  onClick: () => void; //onClick
}
export default function CarouselBtn({ variant, onClick }: CarouselBtnProps) {
  return (
    <button
      onClick={onClick}
      className={`z-50 cursor-pointer p-2 lg:p-0 text-3xl text-yellow lg:absolute lg:top-[45%] ${variant === 'Right' ? `lg:right-0` : 'lg:left-0'}`}
    >
      {variant === 'Left' ? <PiArrowLeft /> : <PiArrowRight />}
    </button>
  );
}