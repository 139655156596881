import PageSelect from "src/components/user/page-select";

import AttendansApplyForm from "./Attendees Apply/attendans-apply-form";
import CompanyApplayForm from "./company-apply/company-apply-form";

function DistruptForm() {
  return (
    <div className="h-[540px] overflow-y-auto">
      <div className="flex justify-center">
        <img
          className="w-[170px] tablet:w-[120px] mobile:w-[120px] cursor-pointer justify-center flex items-center"
          src="/assets/images/disrupt-join.png"
          alt="logo"
        />
      </div>
      <div >
        <PageSelect
          label1="app.shared.copmanyApply"
          label2="app.shared.attendeesApply"
          page1={<CompanyApplayForm />}
          page2={<AttendansApplyForm />}
        />
      </div>
    </div>
  );
}

export default DistruptForm;
