export const gender_en = ["Male", "Female"];
export const gender_ar = ["ذكر", "انثى"];
export const idType = [
  "app.shared.Passport",
  "app.shared.idNo",
  "app.shared.iqamaNo",
];

export const company_size_ar = [
  "0 - 9 موظفين",
  "10 - 20 موظف",
  "21 - 35 موظف",
  "35+ موظف",
];
export const company_size_en = [
  "0 - 9 Employees",
  "10 - 20 Employees",
  "21 - 35 Employees",
  "35+ Employees",
];
export const majors_ar = [
  "التقنيات العميقة",
  "البرمجيات كخدمة (SaaS)",
  "الذكاء الإصطناعي",
  "التقنيات الناشئة",
];

export const majors_en = [
  "Deeptech",
  "Software As A Service(SaaS)",
  "Artificial Intelligence",
  "Emerging Technologies",
];

export const wayOfLinking_ar = [
  "وسائل التواصل الاجتماعي",
  "الاخبار",
  "الأهل والأصدقاء",
  "العمل",
  "اخرى",
];
export const wayOfLinking_en = [
  "Social Media",
  "News",
  "Friends & Family",
  "Work",
  "Other",
];
export const startupStage = ["Pre-seed", "Seed", "Seed-A", "Seed-B"];

export const occupation_en = [
  "Student",
  "Recent Graduate",
  "Private Sector Employee",
  "Governmental Employee",
  "Media Personality",
  "Influencer",
  "Other",
];
export const occupation_ar = [
  "طالب",
  "متخرج حديثًا ",
  "موظف قطاع خاص",
  "موظف قطاع حكومي",
  "إعلامي",
  "مؤثر",
  "اخرى",
];
export const countries_en = [
  "Other",
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "American Virgin Puppy",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua and Barbuda",
  "Argentine",
  "Armenia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Baiwa New Guinea",
  "Bangladesh",
  "Barbados Island",
  "Belarus ",
  "Belgium",
  "Belize",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Botswana",
  "Brazilian",
  "British Indian Mait Territory",
  "British Virgin Puppy",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Caledonia",
  "Cambodia",
  "Cameron",
  "Canada",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Jar",
  "Cocos Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Democratic Republic of the Congo Assembly",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuadorean",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Europe",
  "Faroe Islands",
  "Federated States of Micronesia",
  "Fiji",
  "Financial",
  "Finland",
  "Fouta carrots",
  "France",
  "French Guiana",
  "French Polynesia",
  "French southern provinces",
  "Gabon",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea Bissau",
  "Guinea",
  "Guyana",
  "Haiti",
  "Holland",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Italia",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kingdom Saudi Arabia",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "La Reunion Island",
  "Laos",
  "Latvia",
  "Lebanon ",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonian Republic",
  "Madagascar",
  "Malawi",
  "Malaysia ",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco, West, sunset",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Nicaragua",
  "Niger",
  "Niue",
  "North Korea",
  "Norway",
  "On-site",
  "Pakistan",
  "Palau",
  "Panama",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcrine Islands",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of Moldova",
  "Roller Chair",
  "Romania",
  "Rwanda",
  "Saint Barthélemy",
  "Saint Helena",
  "Saint Martin, French Part.",
  "Saint One Kesves",
  "Saint Vincent and the Grenadines",
  "Saint-Pierre and Miquelon",
  "Samoa",
  "San Marino",
  "Sao Tomes and Principe",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore ",
  "Sint Maarten, the Dutch part.",
  "Slovakia",
  "Slovenia",
  "Solomon Island",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "State of Palestine",
  "Sudan",
  "Sultanate of Oman",
  "Suriname",
  "Svalbard Jan Mayen",
  "Swaziland",
  "Swede",
  "Switzerland ",
  "Taiwan",
  "Tajikistan",
  "Tarek mountain",
  "Thailand",
  "the black Mountain",
  "The Gambia",
  "the maldive Islands",
  "The Outlying Little Islands of the United States",
  "The Syrian Arab Republic",
  "The UK",
  "The United Arab Emirates ",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trenda and Tobago",
  "Tunisia",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "TUV",
  "Uganda",
  "Ukraine",
  "United Republic of Tirana",
  "United Russia",
  "United States of America",
  "Uraguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Victoria",
  "Vietnam",
  "Wallis and Futuna",
  "Western Desert",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];
export const countries_ar = [
  "اخرى",
  "أثيوبيا",
  "أذربيجان",
  "أرومينيا",
  "أسبانيا",
  "أستراليا",
  "أفغانستان",
  "ألبانيا",
  "ألمانيا",
  "أنتيغوا وبربودا",
  "أندونيسيا",
  "أنغولا",
  "أنغويلا",
  "أوربا",
  "أوزبكستان",
  "أوغندا",
  "أوكرانيا",
  "أيسلند",
  "إريتريا",
  "إقليم الميط الهندي البريطاني",
  "إيران",
  "إيطاليا",
  "استونيا",
  "الأرجنتيني",
  "الأردن",
  "الأقاليم الجنوبية الفرنسية",
  "الإكوادوري",
  "الإمارات العربية المتحدة",
  "البرتغال",
  "الجابون",
  "الجبل الأسود",
  "الجزائر",
  "الجمهرية العربية السورية",
  "السلفادور",
  "السنغال",
  "السودان",
  "السويدي",
  "الصحراء الغربية",
  "الصومال",
  "الصين",
  "العراق",
  "الفلبين",
  "الكاتميرون",
  "الكرسي الرولى",
  "الكونغو",
  "الكويت",
  "المغرب",
  "المكسيك",
  "المملكة العربية السعودية",
  "المملكة المتحدة البريطانية",
  "النرويج",
  "النمسا",
  "النيجر",
  "الهند",
  "الولايات المتحدة الأمريكية",
  "اليابان",
  "اليمن",
  "اندورا",
  "اوراغواي",
  "ايرلندا",
  "باراغواي",
  "باكستان",
  "بالاو",
  "بايوا غينيا الجديدة",
  "برازيلي",
  "برمودا",
  "بروناي دار السلام",
  "بلجيكا",
  "بلغاريا",
  "بليز",
  "بنغلاديش",
  "بنما",
  "بوتان",
  "بوتسوانا",
  "بورتوريكو",
  "بوركينافاسو",
  "بوروندي",
  "بولندا",
  "بوليفيا",
  "بولينزيا الفرنسية",
  "بيرو",
  "تايلاند",
  "تايوان",
  "تركمانستان",
  "تريندا وتوباجو",
  "تشاد",
  "تشيلي",
  "توجو",
  "توفالو",
  "توكيلاو",
  "تونجا",
  "تونس",
  "تيمور الشرقية",
  "جامايكا",
  "جبل طارق",
  "جرو العذراء الأمريكية",
  "جرو العذراء البرطانية",
  "جريرة الكريسماس",
  "جرينلاند",
  "جزر أولاند",
  "جزر البهاما",
  "جزر القمر",
  "جزر المالديف",
  "جزر الولايات المتحدة الصغيرة النائية",
  "جزر بيتكرين",
  "جزر توركس وكايكوس",
  "جزر فارو",
  "جزر كمايمان",
  "جزر كوك",
  "جزر كوكوس",
  "جزر مارشال",
  "جزرر فوكلاند",
  "جزية لا ريونيون",
  "جزيرة باربادوس",
  "جزيرة سليمان",
  "جزيرة مان",
  "جمعرية الكونغو الديمقراطية",
  "جمهرية أفريقيا الوسطى",
  "جمهرية تنرانا المتحدة",
  "جمهرية دومينيكان",
  "جمهرية مقدوني",
  "جمهرية مولدافيا",
  "جنوب أفريقيا",
  "جنوب السودان",
  "جورجيا",
  "جيبوتي",
  "جيرزي",
  "جيرنسي",
  "دنمارك",
  "دولة فلسطين",
  "دومينيكا",
  "راوندا",
  "روسيا الإتحادية",
  "روسيا البيضاء",
  "رومانيا",
  "زامبيا",
  "زيمبابوي",
  "ساحل العاج",
  "ساموا الأمريكية",
  "ساموا",
  "سان بيير وميكلون",
  "سان مارينو",
  "سان يارتيلمي",
  "سانت فنسينت والجرينادينز",
  "سانت كيتس ونيفيس",
  "سانت مارتن الجزء الفرنسي",
  "سانت هيلانه",
  "ساو تومس وبيرنسيب",
  "سفالبارد جان ماين",
  "سلطنة عمان",
  "سلوفاكيا",
  "سلوفانينيا",
  "سنغافورة",
  "سوازيلاند",
  "سورينام",
  "سويسرا",
  "سيراليون",
  "سيريلانكا",
  "سيشل",
  "سينت مارتن الجزء الهولندي",
  "صربيا",
  "طاجيكستان",
  "غامبيا",
  "غانا",
  "غرينادا",
  "غواتيمالا",
  "غوادلوب",
  "غوام",
  "غويانا الفرنسية",
  "غيانا",
  "غينيا الاستوائية",
  "غينيا بيساو",
  "غينيا",
  "فانواتو",
  "فرنسا",
  "فلندا",
  "فنزويلا",
  "فيتنام",
  "فيجي",
  "قبرص",
  "قرغيزستان",
  "قطر",
  "كازاخستان",
  "كاليدونيا",
  "كرواتيا",
  "كمبوديا",
  "كندا",
  "كوبا",
  "كوراساو",
  "كوريا الجنوبي",
  "كوريا الشمالي",
  "كوستاريكا",
  "كولومبيا",
  "كيريباتي",
  "كينيا",
  "لاتفيا",
  "لاوس",
  "لبنان",
  "لوكسمبورغ",
  "ليبيا",
  "ليبيريا",
  "ليتوانيا",
  "ليختنشتاين",
  "ليسوتو",
  "مارتينيك",
  "مالطا",
  "مالي",
  "ماليزيا",
  "مايوت",
  "مدغشقر",
  "مصر",
  "مكاو",
  "ملاوي",
  "مملكة البحرين",
  "منغوليا",
  "مورمبيق",
  "موريتانيا",
  "موريشوس",
  "موناكو",
  "مونتسرات",
  "ميانمار",
  "ناميبيا",
  "ناورو",
  "نيبال",
  "نيكاراغوا",
  "نييوي",
  "هايتي",
  "هندوراس",
  "هنقاريا",
  "هولندا",
  "هونغ كونغ",
  "واليس وفوتونا",
  "ولايات ميكرونيسيا المتحدة",
  "يونان",
];
export const kacstEntryPermCompanyNameEn = [
  "The Garage",
  "Garage Accelerator",
  "Garage Plus",
  "Garage Access",
  "Other",
];
export const kacstEntryPermCompanyNameAr = [
  "الكراج",
  "مسرعة الكراج",
  "كراج بلس",
  "كراج اكسيس",
  "اخرى",
];
export const kacstEntryPermVisitDurationnEn = [
  "1 Day",
  "A Week",
  "2 Weeks",
  "A Month",
  "6 Months",
];
export const kacstEntryPermVisitDurationnAr = [
  "يوم واحد",
  "اسبوع واحد",
  "اسبوعين",
  "شهر واحد",
  "ستة أشهر",
];

export const kacstEntryPermVisitInvitedByWhoEn = [
  "Startup",
  "Employee",
  "Other",
];
export const kacstEntryPermVisitInvitedByWhoAr = ["شركة", "موظف", "اخرى"];

export const incubatorFormBusinessModel = ["B2B", "B2C", "B2B2C", "B2G"];
export const incubatorFormStageYourProductEn = [
  "Research Stage",
  "In Development ",
  "MVP",
  "Testing the product",
  "Growth & expansion ",
];
export const incubatorFormStageYourProductAr = [
  "مرحلة البحث والتطوير",
  "مرحلة بناء المنتج الأولي",
  "مرحلة بناء المنتج النهائي MVP",
  "مرحلة اختبار المنتج",
  "مرحلة النمو والتوسع",
];

export const incubatorFormLastFundraisingRoundEn = [
  "No funding",
  "Pre-seed",
  "Seed",
  "Series A",
  "Series B",
];

export const incubatorFormLastFundraisingRoundAr = [
  "لا يوجد",
  "Pre-seed",
  "Seed",
  "Series A",
  "Series B",
];

export const partnershiptype_en = [
  "traning program",
  "Joint events",
  "Invest and finance",
  "Other",
];
export const partnershiptype = [
  "برامج تدريبية",
  "فعاليات مشتركة",
  "استثمار وتمويل",
  "اخرى",
];

export const eventType_en = [
  "Hackathon",
  "Accelerator",
  "Fgraduation",
  "Other",
];
export const eventType = ["هاكثون", "برنامج مسرعة", "حفل تخرج", "اخرى"];

export const browserType = ["Chrome", "Firefox", "Safari", "Other"];
export const browserTypeAr = ["Chrome", "Firefox", "Safari", "اخرى"];
export const operationSystemType = ["Windows", "Macos", "Linux", "Other"];
export const operationSystemTypeAr = ["Windows", "Macos", "Linux", "اخرى"];
export const title = ["Mr", "Ms", "Miss", "Mrs"];
export const What_do_you_hope_to_gain_from_this_course = [
  "Acquiring new knowledge",
  "Growing existing skills",
  "Gaining practical insights and tips",
  "Networking with professionals in the field",
  "Entrepreneurial guidance",
  "Personal development",
  "Other",
];
export const Choose_the_word_that_describes_you_the_most = [
  "Entrepreneur",
  "Student",
  "Aspiring Entrepreneur",
  "Entrepreneurship Enthusiast",
  "Academic",
  "Ecosystem Player",
  "Other",
];
export const Are_you_part_of_an_accelerator_or_incubator_program = [
  "Yes",
  "No",
  "Currently applying to one",
];
export const what_Stage_Are_You_At_In_Your_Startups_Evolution = [
  "Idea Stage",
  "Early-stage (Pre-seed/Seed)",
  "Growth-stage (Series A and beyond)",
  "Established (Operating for several years)",
];

export const disrupt_en = [
  "container.community.1.Disrupt",
  "container.community.2.Disrupt",
  "container.community.3.Disrupt",
  "container.community.4.Disrupt",
  "container.community.5.Disrupt",
  "container.community.6.Disrupt",
  "container.community.7.Disrupt",
  "container.community.8.Disrupt",
];
export const plus_en = [
  "container.community.1.cohort",
  "container.community.2.cohort",
  "container.community.3.cohort",
  "container.community.4.cohort",
];

export const plus_Ar = [
  "container.community.1.cohort",
  "container.community.2.cohort",
];
