/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import CardPhotoComponent from "src/components/common/card-photo-component";
import SelectComponent from "src/components/common/ui/select-component";
import { handleRequestError } from "src/core/helpers/error.helper";
import * as list from "src/core/statics/form-data/form-data";

import { getListDisruptService } from "./list-disrupt";

function StartupDisruptContainer() {
  const [page, setPage] = useState(list.disrupt_en[0]);
  const [images, setImages] = useState<any[]>([]);

  const getDisrupt = async (page: string) => {
    try {
      const disrupt = await getListDisruptService(page);
      unstable_batchedUpdates(() => {
        setImages(disrupt);
        setPage(page);
      });
    } catch (error) {
      handleRequestError(error);
    }
  };
  const handleChange = (selectedPage: string) => {
    getDisrupt(selectedPage);
  };
  useEffect(() => {
    getDisrupt(page);
  }, [page]);
  return (
    <div className="h-[545px] mobile:h-auto">
      <div className="flex justify-end w-full mobile:justify-center tablet:justify-center">
        <div className="w-1/3 px-4 pt-4 mobile:w-full tablet:w-full">
          <SelectComponent
            selectLabel="app.shared.select"
            options={list.disrupt_en}
            name="disrupt"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              handleChange(e.target.value)
            }
          />
        </div>
      </div>
      <div className="grid grid-cols-7 gap-4 pt-6 mobile:grid-cols-3 mobile:mt-8 tablet:grid-cols-6 laptop:grid laptop:grid-cols-4 place-content-center place-items-center">
        {images.map((val, index: number) => (
          <div key={index} className="">
            <CardPhotoComponent chiled={true} isHover={true}>
              <img
                src={val.image}
                alt={val.image}
                width={100}
                className="h-[44px] w-[44px] object-contain bg-white rounded-lg"
              />
            </CardPhotoComponent>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StartupDisruptContainer;
