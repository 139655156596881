import MediaSkeletonCard from '../../../components/common/skeleton/media-skeleton-card';

interface IMediaSkeletonContainerProps {
  count: number;
}
function MediaSkeletonContainer({ count }: IMediaSkeletonContainerProps) {
  return (
    <div className="grid grid-cols-4 grid-rows-3 gap-4 px-2 pt-2 text-white mobile:grid-cols-2 mobile:pb-10 tablet:ml-8 tablet:grid-cols-2 laptop:grid-cols-3 laptop:px-10 largeLaptop:gap-2">
      {Array(count)
        .fill(0)
        .map((_, i) => (
          <MediaSkeletonCard key={i} />
        ))}
    </div>
  );
}

export default MediaSkeletonContainer;
