import NewsSkeletonCard from "src/components/common/skeleton/news-skeleton-card";

interface INewsSkeletonContainerProps {
  count: number;
}
function NewsSkeletonContainer({ count }: INewsSkeletonContainerProps) {
  return (
    <div className="grid grid-cols-3 grid-rows-2 relative gap-2 mobile:gap-4 px-2 pt-4 tablet:grid-cols-1 mobile:grid-cols-1 tablet:px-40">
      {Array(count)
        .fill(0)
        .map((_, i) => (
          <NewsSkeletonCard key={i} />
        ))}
    </div>
  );
}

export default NewsSkeletonContainer;
